import {Button, Checkbox, FormControlLabel} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {CommonBorderBox, CommonTextInput, Loader} from '../../commonComponents';
import ApiClient from '../../networking/ApiManager';

function SyExamOptionForm(props) {
  const {
    isSubItem,
    setIsLoading,
    isLoading,
    refresh,
    leafNode,
    departmentId,
    subspecialityId,
    parentId,
    editId,
    descriptorSign,
    setShowLocalAlert,
    setLocalAlertMessage,
    askFordefaultedInput,
    askForInput,
  } = props;

  let text = isSubItem ? ' Sub Option' : 'Option';
  let btnText = editId ? 'Update ' : 'Add ';
  btnText += text;

  const [optionLabel, setOptionLabel] = useState(descriptorSign || '');
  const [optionLabelError, setOptionLabelError] = useState('');
  const [checked, setChecked] = useState(askForInput);
  const [defaultedCheck, setDefaultedCheck] = useState(askFordefaultedInput);

  const handleChange = event => {
    setChecked(event.target.checked);
  };

  const clearData = () => {
    refresh();
    setOptionLabel('');
  };

  useEffect(() => {
    setOptionLabelError(false);
  }, [optionLabel]);

  const showError = () => {
    if (optionLabel.trim().length === 0) {
      setOptionLabelError('Option label is required!');
    }
    return true;
  };

  const createHeadingOptions = () => {
    showError();
    if (optionLabel.trim().length != 0) {
      setIsLoading(true);
      const params = {
        department_id: departmentId,
        subspecialty_id: subspecialityId,
        option_label: optionLabel,
        ask_doctor_comment: checked,
        default_selected: defaultedCheck,
      };

      if (isSubItem) {
        params.parent_option_id = parentId;
      } else {
        params.symptom_questionnaire_id = parentId;
      }

      ApiClient.createHeadingOptions(params)
        .then(res => {
          clearData();
        })
        .catch(err => {
          console.log(err);
          setLocalAlertMessage(`Failed to create ${text}!`);
          setShowLocalAlert(true);
          setIsLoading(false);
        });
    }
  };

  const updateHeadingOptions = () => {
    showError();
    if (optionLabel.trim().length != 0) {
      setIsLoading(true);
      const params = {
        option_label: optionLabel,
        ask_doctor_comment: checked,
        default_selected: defaultedCheck,
      };
      ApiClient.updateExamHeadingOption(params, editId)
        .then(res => {
          clearData();
          setDefaultedCheck(defaultedCheck);
        })
        .catch(err => {
          console.log(err);
          setLocalAlertMessage(`Failed to update ${text}!`);
          setShowLocalAlert(true);
          setIsLoading(false);
        });
    }
  };

  const deleteOption = () => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete this ${text}?`,
    );
    if (confirmDelete) {
      setIsLoading(true);
      ApiClient.deleteExamHeadingOption(editId)
        .then(res => {
          clearData();
        })
        .catch(err => {
          console.log(err);
          setLocalAlertMessage(`Failed to delete ${text}!`);
          setShowLocalAlert(true);
          setIsLoading(false);
        });
    }
  };

  const renderCheckBox = () => {
    return (
      <>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={handleChange}
              color="primary"
            />
          }
          label="Ask doctor comment?"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={defaultedCheck}
              onChange={() => setDefaultedCheck(!askFordefaultedInput)}
              color="primary"
            />
          }
          label="Default Checked?"
        />
      </>
    );
  };

  return (
    <>
      <CommonTextInput
        label="Option Label"
        error={!!optionLabelError}
        customize
        helperText={optionLabelError}
        style={{width: '100%', margin: '15px 0px 0px 0px'}}
        placeholder="Option Label"
        value={optionLabel}
        onChange={setOptionLabel}
        helperTextStyle={{margin: '0px'}}
      />

      {!isSubItem && renderCheckBox()}

      <CommonBorderBox
        onClick={() => {
          editId ? updateHeadingOptions() : createHeadingOptions();
        }}
        style={{
          textAlign: 'center',
          padding: '10px',
          margin: '15px 0px 0px 0px',
        }}>
        {btnText}
      </CommonBorderBox>
      {editId && leafNode && (
        <CommonBorderBox
          onClick={deleteOption}
          style={{textAlign: 'center', margin: '10px 0', padding: '10px'}}>
          Delete {text}
        </CommonBorderBox>
      )}
      <Loader loading={isLoading} />
    </>
  );
}

export default SyExamOptionForm;
