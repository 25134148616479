import {createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import Utility from '../../../commonComponents/Utility';

const initialState = {
  data: [],
  query: '',
  isLoading: true,
  isRefreshing: false,
  status: 'idle',
  error: null,
  firstLoad: true,
};

const uniqueData = state => {
  const data = state.data.reduce((acc, current) => {
    const x = acc.find(item => item.id === current.id);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);
  state.data = data;
  return state;
};

const thunks = [
  'get',
  'getSingle',
  'create',
  'update',
  'delete',
  'toggle',
  'reorderTask',
  'reorder',
  'taskToggle',
];

class DefaultSlice {
  constructor(type) {
    this.type = type;
    this.initialState = initialState;
    this.asyncThunks = [];
    this.thunkNames = [];
  }

  removeLast(char) {
    return char.slice(0, -1);
  }

  getThunks(sliceName) {
    const finalThunk = [];
    const crudApis = new Crud(this.type);
    thunks.forEach((thunk, index) => {
      const thunkName = this.thunkNames[index];
      const create = createAsyncThunk(
        `${sliceName}/${thunkName}`,
        async (params = {}, thunkAPI) => {
          try {
            const response = await crudApis[thunk](params);
            return {...params, ...response.data};
          } catch (err) {
            return thunkAPI.rejectWithValue(err);
          }
        },
      );
      finalThunk.push({
        create,
        method: thunk,
        setData: (state, action) => {
          switch (thunk) {
            case 'get':
              state.data = action.payload.data;
              return uniqueData(state);
            case 'getSingle':
              return state;
            case 'create':
              state.data.push(action.payload.data);
              return uniqueData(state);
            case 'update':
              state.data = state.data.map(item => {
                if (item.id === +action.payload.id) {
                  return {
                    ...item,
                    ...action.payload.data,
                  };
                }
                return item;
              });
              return uniqueData(state);
            case 'delete':
              state.data = state.data.filter(
                item => item.id !== +action.payload.id,
              );
              return uniqueData(state);
            case 'toggle':
              const newData = [...state.data];
              state.data = newData.map(item => {
                const newItem = {...item};
                if (newItem.id === +action.payload.id) {
                  const deactivated_at = action.payload.isActive
                    ? new Date()
                    : null;
                  return {...newItem, deactivated_at};
                }
                return newItem;
              });
              return uniqueData(state);
            default:
              return uniqueData(state);
          }
        },
      });
    });
    this.asyncThunks = finalThunk;
    return finalThunk;
  }

  assignThunkNames(type) {
    const upper = type.slice(0, 1).toUpperCase() + type.slice(1);
    const names = thunks.map(thunk => {
      const last = this.removeLast(upper);
      const newUpper = thunk !== 'get' ? last : upper;
      return `${thunk}${newUpper}`;
    });
    this.thunkNames = names;
    return names;
  }

  extraReducers(asyncThunks, builder) {
    let builderState = builder;
    asyncThunks.forEach(thunk => {
      builderState = builderState
        .addCase(thunk.create.pending, (state, action) => {
          state.isLoading = true;
          state.isRefreshing = true;
          state.status = 'loading';
          state.error = null;
        })
        .addCase(thunk.create.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isRefreshing = false;
          state.status = 'success';
          state.error = null;
          let newState = {...state};
          const newData = thunk.setData(newState, action);
          state.data = newData.data;
        })
        .addCase(thunk.create.rejected, (state, action) => {
          const upper = Utility.firstUpperCase;
          const errors = action?.payload?.response?.data?.data;
          let message =
            'Failed to ' +
            upper(thunk.method) +
            ' ' +
            upper(this.removeLast(this.type));
          if (Array.isArray(errors)) {
            errors.forEach(error => {
              message += `
                ${error}
                `;
            });
          }
          if (Object.keys(errors).length > 0) {
            Object.keys(errors).forEach(key => {
              message += `
                ${key + ' ' + errors[key]}
                `;
            });
          }
          alert(message);
          state.isLoading = false;
          state.isRefreshing = false;
          state.status = 'failed';
          state.error = action.error.message;
        });
    });
  }
}

export {DefaultSlice};

const removeCamelCase = str => {
  return str.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();
};

class Crud {
  constructor(path) {
    this.path = removeCamelCase(path);
    this.api = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL + '/api/web/d1/',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
  }

  get(params) {
    return this.api.get(this.path, {params});
  }

  getSingle({id}) {
    return this.api.get(`${this.path}/${id}`);
  }

  create({params}) {
    return this.api.post(this.path, params);
  }

  update({id, params}) {
    return this.api.put(`${this.path}/${id}`, params);
  }

  delete({id}) {
    return this.api.delete(`${this.path}/${id}`);
  }

  toggle({id, isActive}) {
    const last = isActive ? 'activate' : 'deactivate';
    return this.api.put(`${this.path}/${id}/${last}`);
  }

  reorder({id, params}) {
    return this.api.put(`${this.path}/${id}/reorder`, params);
  }

  reorderTask({id, params, taskId}) {
    return this.api.put(`task_sets/${taskId}/tasks/${id}/reorder`, params);
  }
  taskToggle({id, isActive, taskSetId}) {
    const last = isActive ? 'deactivate' : 'activate';
    return this.api.put(`task_sets/${taskSetId}/tasks/${id}/${last}`);
  }
}
