import {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import AsyncThunks from '../../redux/AsyncThunks';

const useFollowUps = () => {
  const {FollowUpOptionsSlice, FollowUpQuestionsSlice} = useSelector(
    state => state,
  );
  const dispatch = useDispatch();
  const followUpOptions = useMemo(() => {
    return FollowUpOptionsSlice.data;
  }, [FollowUpOptionsSlice.data]);

  const followUpQuestions = useMemo(() => {
    return FollowUpQuestionsSlice.data;
  }, [FollowUpQuestionsSlice.data]);

  useEffect(() => {
    if (followUpOptions.length === 0) {
      // dispatch(AsyncThunks.getFollowUpOptions());
    }
  }, [dispatch, followUpOptions]);

  useEffect(() => {
    if (followUpQuestions.length === 0) {
      dispatch(AsyncThunks.getFollowUpQuestions());
    }
  }, [dispatch, followUpQuestions]);

  return {
    followUpOptions,
    followUpQuestions,
  };
};

export default useFollowUps;
