import {Box, Button, Modal} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import Colors from '../Resources/COLORS/Colors';
import BlackCrossIcon from './BlackCrossIcon';

const AddEditModal = props => {
  return (
    <Modal
      open={props.visible}
      onClose={() => props.close(false)}
      sx={styles.modal}>
      <Box sx={styles.containerBox}>
        <BlackCrossIcon
          onClick={() => props.close(false)}
          containerStyle={styles.containerStyle}
          style={styles.iconStyle}
        />
        {props.children}
        <Button
          variant="contained"
          sx={styles.createUpdateButton}
          component="span"
          onClick={props.onClick}>
          {props.buttonText}
        </Button>
      </Box>
    </Modal>
  );
};

export default AddEditModal;

AddEditModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

const styles = {
  containerBox: {
    width: '40%',
    background: Colors.WHITE,
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '20px',
    borderRadius: '10px',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  createUpdateButton: {
    width: '100%',
    paddingTop: '10px',
    paddingBottom: '10px',
    backgroundColor: '#405672',
    marginTop: 2,
  },
  containerStyle: {
    height: 25,
    width: '100%',
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center',
    marginBottom: 10,
  },
  iconStyle: {
    height: '16px',
    maxWidth: '17px',
  },
};
