import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ApiClient from '../../networking/ApiManager';
import {SearchBoxModal} from '../../commonComponents';

const BodyPartsSearchModal = (props, ref) => {
  const [bodyPartsList, setBodyPartsList] = useState([]);

  const getBodyParts = useCallback(() => {
    ApiClient.searchBodyParts()
      .then(res => {
        setBodyPartsList(res.data.data);
      })
      .catch(err => {
        console.log('err', err);
      });
  }, []);

  useEffect(() => {
    getBodyParts();
  }, [getBodyParts]);

  return (
    <SearchBoxModal
      data={bodyPartsList}
      title="Add Pody Part"
      onItemClick={props.onPartClick}
      ref={ref}
    />
  );
};

export default React.forwardRef(BodyPartsSearchModal);

BodyPartsSearchModal.propTypes = {
  onPartClick: PropTypes.func,
};
