import React, {useEffect, useState} from 'react';
import {Box, Button} from '@mui/material';
import {
  LocalAlert,
  CommonDropDown,
  CommonTextInput,
  Header,
  CommonButton,
} from '../../commonComponents';
import ApiClient from '../../networking/ApiManager';
import {useNavigate, useParams} from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import Loader from '../../commonComponents/Loader';
import {useTableData} from '../../hooks';
import LanguageDropdown from '../../commonComponents/LanguageDropdown';

function CreateCategory() {
  const [name, setName] = useState('');
  const [promptName, setPromptName] = useState('');
  const [loading, setLoading] = useState(false);
  const [departmentList, setDepartmentList] = useState(null);
  const [department, setDepartment] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertType, setAlertType] = useState('error');
  const [type, setType] = useState('');

  const {departments} = useTableData(true);

  const navigate = useNavigate();
  const {id} = useParams();

  useEffect(() => {
    setDepartmentList(departments);
  }, [departments]);

  useEffect(() => {
    if (id) {
      symptomCategoriesShow();
    }
  }, [id]);

  const symptomCategoriesShow = () => {
    setLoading(true);
    ApiClient.symptomCategoriesShow(id)
      .then(res => {
        let data = res.data.data;
        setName(data.name);
        setType([data.type]);
        setDepartment(data.department_id);
        setPromptName(data.lprompt);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
      });
  };

  const prepareFormData = () => {
    let formData = {
      department_id: department,
      type: type[0],
      name: name,
      lprompt: promptName,
    };
    return formData;
  };

  const createNewCategory = () => {
    setLoading(true);
    let formData = prepareFormData();

    ApiClient.createCategory(formData)
      .then(res => {
        setTimeout(function () {
          navigate('/categories');
        }, 1000);
        setShowAlert(true);
        setAlertMessage('Category Created successfully');
        setAlertType('success');
        setLoading(false);
      })
      .catch(err => {
        console.log(err.response.data);
        setLoading(false);
        setShowAlert(true);
        setAlertMessage('Failed to Create Category');
        setAlertType('error');
      });
  };

  const updateSymptomCategory = () => {
    setLoading(true);
    let formData = prepareFormData();

    ApiClient.updateSymptomCategory(id, formData)
      .then(res => {
        setShowAlert(true);
        setAlertMessage('Category updated successfully');
        setAlertType('success');
        setTimeout(function () {
          navigate('/categories');
        }, 1000);

        setLoading(false);
      })
      .catch(err => {
        console.log(err.response.data);
        setLoading(false);
        setShowAlert(true);
        setAlertMessage('Failed to update Category');
        setAlertType('error');
      });
  };

  const typeList = [
    {name: 'Input Category', key: 'InputCategory'},
    {name: 'Output Category', key: 'OutputCategory'},
  ];

  return (
    <Box>
      <Header />

      <Box sx={{margin: 2}}>
        <h2 style={{margin: '15px 0'}}>
          {' '}
          {id ? 'Update' : 'Create'} Category :-
        </h2>

        <CommonDropDown
          formControlStyle={{width: '100%', margin: '5px 0'}}
          label="Department Name"
          labelId="Select Department"
          onChange={setDepartment}
          value={department}
          selectStyle={{width: '100%'}}
          data={departmentList}
          setData={'id'}
          showData={'name'}
        />

        <CommonTextInput
          maxLength={250}
          showLength={125}
          style={{width: '100%', margin: '15px 0'}}
          value={name}
          onChange={setName}
          label="Category Name"
        />
        <LanguageDropdown
          inputValue={promptName}
          onChange={setPromptName}
          label="prompt"
        />
        <CommonDropDown
          formControlStyle={{width: '100%', margin: '15px 0'}}
          label="type"
          labelId="category"
          onChange={setType}
          value={type}
          selectStyle={{width: '100%'}}
          data={typeList}
          setData={'key'}
          showData={'name'}
        />

        <CommonButton
          style={{backgroundColor: '#405672', marginTop: 2, width: '100%'}}
          label={'Save'}
          onClick={() => {
            id ? updateSymptomCategory() : createNewCategory();
          }}
        />
      </Box>
      {showAlert && (
        <LocalAlert
          severity={alertType}
          alertText={alertMessage}
          onClose={() => setShowAlert(false)}
        />
      )}
      <Loader loading={loading} />
    </Box>
  );
}

export default CreateCategory;
