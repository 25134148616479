import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {LocalAlert} from '../commonComponents';
import Actions from '../redux/Actions';

const GlobalAlerts = () => {
  const messages = useSelector(state => state.UserDataSlice.alertMessages);
  const dispatch = useDispatch();

  const hide = useCallback(() => {
    dispatch(
      Actions.setAlertMessages({
        success: '',
        error: '',
      }),
    );
  }, [dispatch]);

  return (
    <>
      {messages.success && (
        <LocalAlert
          severity={'success'}
          alertText={messages.success}
          onClose={hide}
        />
      )}
      {messages.error && (
        <LocalAlert
          severity={'error'}
          alertText={messages.error}
          onClose={hide}
        />
      )}
    </>
  );
};

export default GlobalAlerts;
