import React from 'react';
import PropTypes from 'prop-types';

const TableHeaderTitle = ({title, style}) => {
  return (
    <div style={{display: 'flex', flexDirection: 'row', style}}>
      <h2 style={{margin: '15px 0'}}> {title}</h2>
    </div>
  );
};

export default TableHeaderTitle;

TableHeaderTitle.propTypes = {
  title: PropTypes.string.isRequired,
  style: PropTypes.object,
};
