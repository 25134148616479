import {Box} from '@mui/system';
import React, {useState} from 'react';
import {Grid} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import ApiClient from '../../networking/ApiManager';
import {
  CloneModal,
  CommonButton,
  CommonDropDown,
  CommonTable,
  Header,
  LocalAlert,
} from '../../commonComponents';
import {useTableData} from '../../hooks';

function Showlist() {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertType, setAlertType] = useState('error');
  const table = useTableData();
  const [cloneModalOpen, setCloneModalOpen] = useState(false);
  const [questionId, setQuestionId] = useState(null);
  const navigate = useNavigate();
  const [isButtonDisable, setIsButoonDisable] = useState(false);

  const handleClone = (department_id, subspecialty_id, symptom_id) => {
    setIsButoonDisable(true);
    const params = {
      id: questionId,
      department_id,
      subspecialty_id,
      symptom_id,
    };
    table.setIsLoading(true);
    ApiClient.cloneQuestions(params, questionId)
      .then(res => {
        setAlertMessage(res.data.data);
        setAlertType('success');
        setShowAlert(true);
        table.getSymptomQuestionnaires();
        setCloneModalOpen(false);
        setIsButoonDisable(false);
      })
      .catch(err => {
        console.log('error', err.response.data);
        setAlertMessage('Failed to clone Symptom');
        setAlertType('error');
        setShowAlert(true);
        setIsButoonDisable(false);
      })
      .finally(table.stopLoader);
  };

  const updateSymptomsQuestions = id => {
    navigate(`/questions/${id}/edit`);
  };

  const tableHeaderData = [
    'Id',
    'Department',
    'Subspecialty',
    'Symptom',
    'Descriptor System',
    'Descriptor User',
    'Image',
  ];
  const dataKeys = [
    'id',
    'department_name',
    'subspecialty_name',
    'symptom_name',
    'descriptor_code',
    'descriptor_sign',
  ];

  return (
    <Box>
      <Header />
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
        }}>
        <Grid item xs={2} sm={4} md={8}>
          <p style={{fontSize: 30, margin: '15px 11px'}}>Questions :- </p>
        </Grid>
        <div
          style={{
            display: 'flex',
            alignSelf: 'flex-end',
            width: '50%',
          }}>
          <CommonDropDown
            formControlStyle={{width: '31%', margin: '15px 0'}}
            selectStyle={{width: '100%'}}
            {...table.departmentDropDown}
          />
          <CommonDropDown
            formControlStyle={{
              width: '30%',
              margin: '15px 0',
              marginLeft: '3%',
            }}
            selectStyle={{width: '100%'}}
            {...table.subspecialtyDropDown}
          />
          <CommonDropDown
            formControlStyle={{
              width: '31%',
              margin: '15px 0',
              marginLeft: '3%',
            }}
            selectStyle={{width: '100%'}}
            {...table.symptomDropDown}
          />
        </div>
      </div>
      <CommonButton
        style={{width: '98.4%', backgroundColor: '#405672', marginTop: 2}}
        label="Create New Questions"
        onClick={() => navigate('/questions/new')}
      />
      <CommonTable
        data={table.questions}
        tableHeaderData={tableHeaderData}
        dataKeys={dataKeys}
        showImageKey={'image_url'}
        showUpdate
        showDelete
        showClone
        onPressCopy={id => {
          setQuestionId(id);
          setCloneModalOpen(true);
        }}
        onPressUpdate={updateSymptomsQuestions}
        tableStyle={{width: '100%'}}
        showDragAndDrop
        refresh={table.getSymptomQuestionnaires}
        apiType="symptomQuestionnaire"
        isFetching={table.isLoading}
        emptyTitle="No Questions Found"
      />
      {showAlert && (
        <LocalAlert
          severity={alertType}
          alertText={alertMessage}
          onClose={() => setShowAlert(false)}
        />
      )}
      <CloneModal
        open={cloneModalOpen}
        handleClone={handleClone}
        departmentsList={table.departments}
        onClose={() => setCloneModalOpen(false)}
        isButtonDisable={isButtonDisable}
      />
    </Box>
  );
}

export default Showlist;
