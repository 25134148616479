import {PatientsListActions} from '../Redcuers/SychartDoctor/PatientsListSlice';
import {BodyPartsActions} from '../Redcuers/SuperDoctor/BodyPartsSlice';
import {DepartmentsActions} from '../Redcuers/SuperDoctor/DepartmentSlice';
import {UserDataActions} from '../Redcuers/UserDataSlice';
import {RegistrationPatientListActions} from '../Redcuers/PatientListReducer/RegistrationPatientListSlice';
import {SymptomsActions} from '../Redcuers/SuperDoctor/SymptomsSlice';
import {LanguageActions} from '../Redcuers/LanguageSlice';

const Actions = {
  ...UserDataActions,
  ...DepartmentsActions,
  ...BodyPartsActions,
  ...PatientsListActions,
  ...RegistrationPatientListActions,
  ...SymptomsActions,
  ...LanguageActions,
};

export default Actions;
