import React from 'react';
import './welcome.scss';
import LogInScene from './LogInScene';
import Department from '../Departments/Department';

function Welcome() {
  let verifySessionUser = localStorage.getItem('user');

  return (
    <>
      <LogInScene />
      {verifySessionUser == 'doctor' && <Department />}
    </>
  );
}

export default Welcome;
