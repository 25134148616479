import React, {useEffect, useState} from 'react';
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Button,
  Tooltip,
  MenuItem,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import {useNavigate} from 'react-router-dom';
import {getAuth, signOut} from 'firebase/auth';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import {CommonAskForPending, useUserData} from './index';
import {useTranslation} from 'react-i18next';
import logo from '../Resources/Images/logo.png';
import Colors from '../Resources/COLORS/Colors';
import ApiClient from '../networking/ApiManager';

import {useSelector} from 'react-redux';
import {logout, profile} from '../Resources/Images';
import Utility from './Utility';
const Header = () => {
  const navigate = useNavigate();
  let sessionUser = localStorage.getItem('user');
  let departmentCode = localStorage.getItem('departmentCode');
  const isUhid = localStorage.getItem('loginMode') === 'uhid';
  const isNurse = localStorage.getItem('role') === 'nurse';
  const {getSetUserData} = useUserData();
  const userData = useSelector(state => state.UserDataSlice.userData);
  const isPatient = sessionUser == 'patient';

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const {t, i18n} = useTranslation();

  useEffect(() => {
    if (isPatient && userData.language_code) {
      i18n.changeLanguage(userData.language_code);
    }
  }, [userData]);

  useEffect(() => {
    if (!isUhid) {
      redirectUser();
    }
  }, [userData]);

  const redirectUser = () => {
    if (Object.keys(userData).length != 0) {
      if (
        (!userData.name || (isPatient && !userData.age)) &&
        window.location.pathname !== '/profile/edit'
      ) {
        navigate('/profile/edit', {state: {hideEmail: true}});
      } else if (
        isPatient &&
        window.location.pathname !== '/startquestionary' &&
        window.location.pathname !== '/profile/edit' &&
        window.location.pathname !== '/termsAndConditions' &&
        !!departmentCode
      ) {
        navigate('/termsAndConditions');
      }
    }
  };

  useEffect(() => {
    getSetUserData();
  }, []);

  const logOutSessionUser = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        localStorage.clear();
        window.location.pathname = Utility.rootPath;
      })
      .catch(error => {
        console.log(error);
      });
  };

  const logoutUser = () => {
    const logOutString = isPatient
      ? t('confirm_logout')
      : 'Are you sure you want to logout ?';
    const confirnLogOut = window.confirm(logOutString);

    if (confirnLogOut) {
      if (isPatient) {
        ApiClient.logOutPatient()
          .then(res => {
            console.log(res);
            logOutSessionUser();
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        ApiClient.logOutDoctor()
          .then(res => {
            console.log(res);
            logOutSessionUser();
          })
          .catch(err => {
            console.log(err);
          });
      }
    }
  };

  const doctorLinks = [
    {
      title: 'departments',
      link: '/departments',
    },
    {
      title: 'subspecialties',
      link: '/subspecialties',
    },
    {
      title: 'categories',
      link: '/categories',
    },
    {
      title: 'symptoms',
      link: '/symptoms',
    },
    {
      title: 'Facts',
      link: '/Facts',
    },
    {
      title: 'Body Parts',
      link: '/bodyparts',
    },
    {
      title: 'questions',
      link: '/questions',
    },
    {
      title: 'followups',
      link: '/followups',
    },
    {
      title: 'languages',
      link: '/languages',
    },
    {
      title: 'activities',
      link: '/activities',
    },
    {
      title: 'task set',
      link: '/task_sets',
    },
    {
      title: 'versions',
      link: '/versions',
    },
  ];

  const adminLinks = [];

  const patientLinks = [
    {
      title: t('home'),
      link: '/',
    },
    {
      title: t('start_new_sy') + ' ' + t('sychart'),
      link: '/termsAndConditions',
      openModal: true,
    },

    {
      title: t('upload_documents'),
      link: '/uploadDocuments',
    },
  ];

  const getPages = () => {
    if (sessionUser == 'doctor') {
      return doctorLinks;
    } else if (sessionUser == 'admin') {
      return adminLinks;
    } else if (sessionUser == 'patient') {
      return patientLinks;
    }
  };
  const pages = getPages();
  const settings = [
    {
      title: t('profile'),
      link: '/profile/edit',
      image: profile,
      styles: {height: '14px', width: '14px'},
    },
    {
      title: t('logout'),
      functionCall: logoutUser,
      image: logout,
      styles: {height: '16px', width: '16px', transform: 'rotate(180deg)'},
    },
  ];

  const handleOpenNavMenu = event => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = event => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const navigateToPage = (page, isOpenModal, isRefresh) => {
    if (isRefresh) {
      const BASE_URL = process.env.REACT_APP_BASE_URL;
      const query = localStorage.getItem('token');
      window.location = BASE_URL + page + '?query=' + query;
    } else {
      if (isOpenModal) {
        setOpenModal(true);
      } else {
        navigate(page);
      }
    }
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const returnModal = () => {
    return (
      <CommonAskForPending openModal={openModal} setOpenModal={setOpenModal} />
    );
  };

  const handleUserMenu = event => {
    if (event.functionCall) {
      event.functionCall();
    } else {
      navigate(event.link);
    }
    setAnchorElUser(null);
  };

  if (isUhid || isNurse) {
    return null;
  }

  return (
    <Box sx={{margin: 0, marginBottom: 10}}>
      <AppBar sx={{backgroundColor: Colors.APPCIRCLE, zIndex: 11}}>
        <Container maxWidth="xxl">
          <Toolbar disableGutters>
            <Typography
              variant="h4"
              noWrap
              component="div"
              sx={{mr: 2, display: {xs: 'none', lg: 'flex'}}}
              onClick={() => navigate('/')}>
              <img
                src={logo}
                style={{
                  width: '105px',
                }}
                onClick={() => navigate('/')}
              />
            </Typography>

            {sessionUser == 'admin' && (
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{
                  cursor: 'pointer',
                  mr: 2,
                  display: {xs: 'none', lg: 'flex'},
                }}
                onClick={() => setOpen(!open)}>
                <QrCode2Icon
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    margin: 0.5,
                    color: 'black',
                  }}
                />
                Qr Scan
              </Typography>
            )}

            {pages.length > 0 && (
              <Box sx={{flexGrow: 1, display: {xs: 'flex', lg: 'none'}}}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit">
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: {xs: 'block', lg: 'none'},
                  }}>
                  {pages.map((item, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        navigateToPage(item.link, item.openModal, item.refresh);
                      }}>
                      <Typography
                        textAlign="center"
                        sx={{textTransform: 'uppercase'}}>
                        {item.title}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            )}
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{flexGrow: 1, display: {xs: 'flex', lg: 'none'}}}
              onClick={() => navigate('/')}>
              <img
                src={logo}
                style={{
                  width: '110px',
                }}
                onClick={() => navigate('/')}
              />
            </Typography>

            {sessionUser == 'admin' && (
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{
                  cursor: 'pointer',
                  flexGrow: 1,
                  display: {xs: 'flex', lg: 'none'},
                }}
                onClick={() => setOpen(!open)}>
                <QrCode2Icon
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyItems: 'flex-end',
                    margin: 0.5,
                    color: 'black',
                  }}
                />
                Qr Scan
              </Typography>
            )}

            <Box sx={{flexGrow: 1, display: {xs: 'none', lg: 'flex'}}}>
              {pages.map((item, index) => (
                <Button
                  key={index}
                  onClick={() =>
                    navigateToPage(item.link, item.openModal, item.refresh)
                  }
                  sx={{
                    my: 2,
                    color: 'white',
                    display: 'block',
                    fontSize: '15px',
                  }}>
                  {item.title}
                </Button>
              ))}
            </Box>

            <Box sx={{flexGrow: 0}}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                  <img
                    style={{
                      maxWidth: 50,
                      height: 45,
                      margin: 2,
                      borderRadius: '2000px',
                      aspectRatio: 1,
                    }}
                    alt={userData.name}
                    src={
                      userData.image_url && userData.image_url
                        ? userData.image_url
                        : 'https://st2.depositphotos.com/4226061/9064/v/950/depositphotos_90647730-stock-illustration-female-doctor-avatar-icon.jpg'
                    }
                  />
                </IconButton>
              </Tooltip>

              <Menu
                sx={{mt: '45px'}}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}>
                {settings.map((setting, index) => (
                  <MenuItem key={index} onClick={() => handleUserMenu(setting)}>
                    <img src={setting.image} style={setting.styles} />
                    <Typography textAlign="center" sx={{marginLeft: '4px'}}>
                      {setting.title}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      {openModal && returnModal()}
    </Box>
  );
};
export default Header;
