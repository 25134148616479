import {Box} from '@mui/material';
import React from 'react';
import {useNavigate} from 'react-router';
import {CommonButton, CommonTable, Header} from '../../commonComponents';
import {useTableData} from '../../hooks';

function Department() {
  const {departments, departmentLoading: isLoading} = useTableData(true);
  const navigate = useNavigate();

  const createDepartment = () => {
    navigate(`/departments/new`);
  };

  const updateDepartment = id => {
    navigate(`/departments/${id}/edit`);
  };

  const tableHeaderData = [
    'Id',
    'Name',
    'Created At',
    'Followup Questions',
    'Symptom Grouping By',
    'Image',
  ];
  const dataKeys = [
    'id',
    'name',
    'created_at',
    'follow_up_question',
    'symptom_grouping_by',
  ];

  return (
    <Box>
      <Header />
      <Box sx={{margin: 2}}>
        <p style={{fontSize: 30, margin: '15px 12px'}}>Department :- </p>
        <CommonButton
          style={{width: '98.4%', backgroundColor: '#405672'}}
          label="Create Department"
          onClick={createDepartment}
        />

        <CommonTable
          data={departments}
          tableHeaderData={tableHeaderData}
          dataKeys={dataKeys}
          showImageKey={'image_url'}
          showUpdate
          showDelete
          onPressUpdate={updateDepartment}
          tableStyle={{width: '100%'}}
          apiType="department"
          isFetching={isLoading}
          emptyTitle="No Department Found"
        />
      </Box>
    </Box>
  );
}

export default Department;
