import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Box} from '@mui/material';
import {CommonDropDown, CommonTextInput} from '../../commonComponents';
import {useTranslation} from 'react-i18next';
import Colors from '../../Resources/COLORS/Colors';
import {SettingsPowerRounded} from '@mui/icons-material';
import './LoginForm.css';

function LoginForm(props) {
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (timer === 0) {
        clearInterval(interval);
      } else {
        setTimer(prev => prev - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  const {
    btnText,
    phone,
    phoneNumberError,
    countryCode,
    setCountryCode,
    handleSubmit,
    otpError,
    validateMovileNumber,
    showOtp,
    setOtp,
    otp,
    getOtp,
    setOpen,
    resent,
    SetOtpError,
  } = props;
  const disabled = showOtp && otp.length !== 6 && btnText;

  const {t, i18n} = useTranslation();

  useEffect(() => {
    if (showOtp) {
      setTimer(20);
    }
  }, [showOtp]);

  useEffect(() => {
    if (resent) {
      setTimer(20);
    }
  }, [resent]);

  const resendOtpClick = () => {
    if (timer <= 0) {
      setOpen(true);
      setOtp('');
      SetOtpError(true);
    }
  };

  const getTimer = () => {
    if (timer < 10) {
      return 0 + timer;
    } else {
      return timer;
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{mt: 1, maxWidth: '320px'}}>
      <div
        style={{
          display: 'flex',
          width: '100%',
          minWidth: '320px',
          marginTop: '20px',
        }}>
        <div></div>

        <div style={{marginLeft: '10px'}}>
          <CommonTextInput
            onChange={validateMovileNumber}
            style={{width: '100%'}}
            sx={{width: '100%'}}
            margin="normal"
            required
            value={phone}
            width="fullWidth"
            id="phone"
            label={t('phone')}
            // autoFocus={true}
            placeholder={t('phone')}
            customize
            error={!!phoneNumberError}
            helperText={phoneNumberError}
            helperTextStyle={{marginLeft: '-120px'}}
          />
        </div>
      </div>
      {showOtp && !phone == '' && (
        <CommonTextInput
          validateNumbers
          onChange={setOtp}
          style={{
            width: '100%',
            minWidth: '320px',
            marginTop: '20px',
            color: otpError ? 'red' : 'black',
          }}
          margin="normal"
          required
          value={otp}
          width="fullWidth"
          id="otp"
          label={t('enter_otp')}
          maxLength="6"
          placeholder={t('enter_otp')}
          autoFocus={true}
          customize
          error={!!otpError}
          helperText={otpError}
          helperTextStyle={{margin: 0}}
        />
      )}
      {showOtp && (
        <div
          style={{
            marginTop: '5px',
            display: 'flex',
            justifyContent: 'center',
            alignSelf: 'center',
          }}>
          <div style={{fontSize: '12px'}}>
            Re-enter phone number in case of mismatch OTP <br />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignSelf: 'center',
              }}>
              else you can{' '}
              <span
                onClick={resendOtpClick}
                style={{
                  color: Colors.APPCIRCLE,
                  cursor: 'pointer',
                  marginLeft: '6px',
                  fontWeight: 'bold',
                }}>
                Resend OTP {timer != 0 ? 'in 00:' + getTimer() : ''}
              </span>
            </div>
          </div>
        </div>
      )}
      <div
        type="submit"
        variant="contained"
        onClick={disabled ? null : handleSubmit}
        disabled={showOtp && otp.length !== 6 && btnText}
        className={`verify-btn ${disabled ? 'disabled' : ''}`}>
        {btnText}
      </div>
    </Box>
  );
}

export default LoginForm;
