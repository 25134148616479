import React, {useEffect, useMemo, useState} from 'react';

import {
  LocalAlert,
  CommonTextInput,
  CommonDropDown,
  Header,
  useCallApi,
  CommonButton,
} from '../../commonComponents';
import {Box, Button} from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import Loader from '../../commonComponents/Loader';
import {useTableData} from '../../hooks';

function CreateFacts(props) {
  const [departmentData, setDepartmentData] = useState(null);
  const [symptomsData, setSymptomsData] = useState(null);
  const [spacialistData, setSpacialistData] = useState(null);
  const [serverImage, setServerImage] = useState('');
  const [loading, setLoading] = useState(false);
  const [userFormData, setUserFromData] = useState({});
  const [departmentId, setDepartmentId] = useState('');
  const [subspecialtyId, setSubspecialtyId] = useState('');
  const [symptomId, setSymptomId] = useState('');
  const {departments} = useTableData(true);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertType, setAlertType] = useState('error');

  const {id} = useParams();
  const {apiCall, postApiCall, putApiCall} = useCallApi();
  const navigate = useNavigate();

  useEffect(() => {
    setDepartmentData(departments);
  }, [departments]);

  useEffect(() => {
    departmentId && getSpacialList();
  }, [departmentId]);

  useEffect(() => {
    departmentId && subspecialtyId && getSymptoms();
  }, [subspecialtyId, departmentId]);

  useEffect(() => {
    id && getSingleFact();
  }, []);

  const handleError = err => {
    setLoading(false);
    console.log(err);
    setAlertType('error');
    setAlertMessage(!id ? 'Failed to Create Fact' : 'Failed to Update Fact');
    setShowAlert(true);
  };

  const setallData = data => {
    setUserFromData({...data});
    setServerImage(data.image_url);
    setDepartmentId(data.department_id);
    setSubspecialtyId(data.subspecialty_id);
    setSymptomId(data.source_id);
  };

  const getSingleFact = () => {
    apiCall('getFacts', id, setallData, handleError);
  };

  const updateFact = data => {
    putApiCall('updateFact', data, callBackFunction, handleError, id);
  };

  const getSymptoms = () => {
    setLoading(true);
    const getSymptomsSetData = data => {
      setSymptomsData(data);
      setLoading(false);
    };
    apiCall('getSymptoms', subspecialtyId, getSymptomsSetData, handleError);
  };

  const getSpacialList = () => {
    setLoading(true);
    const getSpecialist = data => {
      setSpacialistData(data);
      setLoading(false);
    };
    apiCall('getSpacialList', departmentId, getSpecialist, handleError);
  };

  const saveData = () => {
    setLoading(true);
    let formData = new FormData();
    formData.append('source_type', 'Symptom');
    formData.append('source_id', symptomId);
    !!userFormData?.fact && formData.append('fact', userFormData.fact);
    !!userFormData?.content && formData.append('content', userFormData.content);
    userFormData.image && formData.append('image', userFormData.image);

    if (id) {
      updateFact(formData);
    } else {
      postApiCall('createFacts', formData, callBackFunction, handleError);
    }
  };

  const callBackFunction = data => {
    setLoading(false);
    setAlertType('success');
    setAlertMessage(
      !id ? 'Fact Created Successfully' : 'Fact Update Successfully',
    );
    setShowAlert(true);
    setTimeout(function () {
      navigate('/Facts');
    }, 1000);
  };

  const handleFieldChange = (data, state) => {
    userFormData[state] = data;
    setUserFromData({...userFormData});
  };

  const renderInput = (label, key, type, style, multiline, accept, id) => {
    return (
      <>
        <CommonTextInput
          maxLength={250}
          showLength={125}
          id={id}
          type={type ?? 'text'}
          style={{width: '100%', marginBottom: 15, ...style}}
          value={userFormData[key] || ''}
          label={label}
          onChange={data => {
            handleFieldChange(data, key);
          }}
          accept={accept}
          multiline={multiline ? true : false}
          minRows={multiline ? 3 : false}
          maxRows={multiline ? 10 : false}
        />
      </>
    );
  };

  const renderDropDown = (data, state, setState, label, style) => {
    return (
      <CommonDropDown
        formControlStyle={{width: '100%', marginBottom: 15, ...style}}
        label={label}
        value={state}
        onChange={setState}
        selectStyle={{width: '100%'}}
        data={data}
        setData={'id'}
        showData={'name'}
      />
    );
  };

  const renderHeader = useMemo(() => {
    return <Header />;
  }, []);

  return (
    <>
      {renderHeader}
      <h2 style={{margin: '15px 0', marginLeft: '20px'}}>
        {' '}
        {id ? 'Update' : 'Create'} Fact :-
      </h2>

      <Box sx={{margin: 2}}>
        {renderDropDown(
          departmentData,
          departmentId,
          setDepartmentId,
          'Department Name',
          {margin: '15px 0'},
        )}
        {renderDropDown(
          spacialistData,
          subspecialtyId,
          setSubspecialtyId,
          'Subspecialty',
        )}
        {renderDropDown(symptomsData, symptomId, setSymptomId, 'Symptom')}
        {renderInput('fact', 'fact')}
        {renderInput('content', 'content', false, false, true)}
        {(userFormData.image || serverImage) && (
          <div
            style={{
              display: 'flex',
            }}>
            {userFormData.image && (
              <img
                style={{maxWidth: '200px', maxHeight: '200px'}}
                src={URL.createObjectURL(userFormData.image)}
              />
            )}
            {!userFormData.image && serverImage && (
              <img
                style={{maxWidth: '200px', maxHeight: '200px'}}
                src={serverImage}
              />
            )}
          </div>
        )}
        <div style={{display: 'flex'}}>
          <label for="file-input-image">
            <Button
              variant="contained"
              sx={{width: '100%', backgroundColor: '#405672', marginTop: 2}}
              component="span">
              Attach Image
            </Button>
          </label>
        </div>
        {renderInput(
          'file',
          'image',
          'file',
          {visibility: 'hidden'},
          false,
          'image/*',
          'file-input-image',
        )}

        <CommonButton
          style={{
            width: '100%',
            backgroundColor: '#405672',
            margin: '20px 0px',
          }}
          label={'Save'}
          onClick={saveData}
        />
        {showAlert && (
          <LocalAlert
            severity={alertType}
            alertText={alertMessage}
            onClose={() => setShowAlert(false)}
          />
        )}
        <Loader loading={loading} />
      </Box>
    </>
  );
}

export default CreateFacts;
