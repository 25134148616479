import {Box, Paper, Button} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {CommonDropDown, Header} from '../commonComponents';
import EditIcon from '@mui/icons-material/Edit';
import {useNavigate, useParams} from 'react-router';
import ApiClient from '../networking/ApiManager';
import Loader from '../commonComponents/Loader';

function Profile() {
  const [data, setdata] = useState('');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const {id} = useParams();

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = () => {
    setLoading(true);
    ApiClient.getProfileData(id)
      .then(res => {
        let data = res.data.data;
        setdata(data);
        setLoading(false);
        data.image_url && localStorage.setItem('userImage', data.image_url);
        data.name && localStorage.setItem('userName', data.name);
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
      });
  };

  const updateDoctorProfile = id => {
    navigate(`/profile/edit`);
  };

  return (
    <Box>
      <Header profile={data.image_url} />
      <Box
        sx={{
          height: '80%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <img
          alt="Cant load image Server Error!"
          src={data.image_url}
          style={{maxWidth: '200px', maxHeight: '200px', marginTop: '20px '}}
        />
        <span style={{fontSize: ' 25px', marginTop: '10px'}}>{data.name}</span>
        <EditIcon
          style={{
            fontSize: '2rem',
            cursor: 'pointer',
            color: 'green',
            marginTop: '10px',
          }}
          onClick={() => updateDoctorProfile(data.id)}
        />
        <Paper elevation={5} sx={{width: '100%', marginTop: 2}}>
          <p style={{paddingTop: 20, marginLeft: 2}}>
            Department Name : &nbsp; {data.department_name}
          </p>
          <p style={{paddingTop: 20, marginLeft: 2}}>
            Name : &nbsp; {data.name}
          </p>
          <p style={{paddingTop: 20, marginLeft: 2}}>
            Email : &nbsp; {data.email}
          </p>
          <p style={{paddingTop: 20, marginLeft: 2}}>
            Phone : &nbsp; {data.phone}{' '}
          </p>
        </Paper>
      </Box>
      <Loader loading={loading} />
    </Box>
  );
}

export default Profile;
