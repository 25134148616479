import React, {useState, useEffect} from 'react';
import {
  CommonButton,
  CommonTable,
  Header,
  Loader,
  LocalAlert,
} from '../../commonComponents';
import {Button} from '@mui/material';
import ApiClient from '../../networking/ApiManager';
import {useNavigate, useParams} from 'react-router';

const SubspecialistRange = () => {
  const {id} = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [patientId, setPatientId] = useState(null);
  const [alertType, setAlertType] = useState('error');
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);

  const tableHeaderData = [
    'Id',
    'Min',
    'Max',
    'Title',
    'Severity',
    'Effective Index',
    'Cost Per Patient',
  ];
  const dataKeys = [
    'id',
    'min',
    'max',
    'title',
    'severity',
    'effective_index',
    'cost_per_patient',
  ];

  const updateSubspecialistRange = (rawId, value) => {
    navigate(`/subspecialty_score_ranges/${rawId}/edit`, {
      state: {edit: rawId, value, id},
    });
  };

  const deleteSubspecialistRange = async deleteId => {
    const confirmDelete = window.confirm(
      'Are you sure you want to delete this Score Range?',
    );
    if (!confirmDelete) {
      return;
    }
    setLoading(true);
    try {
      await ApiClient.deleteSubspecialistRange(id, deleteId);
      const response = await ApiClient.getSubspecialistRange(id);
      setShowAlert(true);
      setAlertMessage('Successfully Delete Subspecialist Range');
      setAlertType('success');
      setData(response.data.data);
    } catch (error) {
      console.log(error.response.data);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    ApiClient.getSubspecialistRange(id)
      .then(res => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err.response.data);
      });
  }, [patientId]);

  return (
    <div>
      <Header />

      <div style={{paddingLeft: 20}}>
        <p style={{fontSize: 25}}>Score Range :- </p>
      </div>

      <CommonButton
        style={{
          backgroundColor: '#405672',
          marginTop: 15,
          width: '98%',
        }}
        label="Create Score Range"
        onClick={() => navigate(`/subspecialty_score_ranges/${id}/new`)}
      />

      <CommonTable
        data={data}
        tableHeaderData={tableHeaderData}
        dataKeys={dataKeys}
        showUpdate
        showDelete
        hideToggle
        onPressUpdate={updateSubspecialistRange}
        tableStyle={{width: '100%'}}
        onPressDelete={deleteSubspecialistRange}
        emptyTitle="No Subspecialties Found"
      />
      {showAlert && (
        <LocalAlert
          severity={alertType}
          alertText={alertMessage}
          onClose={() => setShowAlert(false)}
        />
      )}
      <Loader loading={loading} />
    </div>
  );
};

export default SubspecialistRange;
