import {createSlice} from '@reduxjs/toolkit';
import {DefaultSlice} from './DefaultSlice';

const type = 'followUpOptions';
const slice = new DefaultSlice(type);
slice.assignThunkNames(type);
const sliceName = 'followUpOptionsSlice';
const thunks = slice.getThunks(sliceName);

const FollowUpOptionsThunks = thunks.reduce((acc, current, index) => {
  const thunkName = slice.thunkNames[index];
  return {...acc, [thunkName]: current.create};
}, {});

export const FollowUpOptionsSlice = createSlice({
  name: sliceName,
  initialState: slice.initialState,
  extraReducers(builder) {
    slice.extraReducers(slice.asyncThunks, builder);
  },
});

// Action creators are generated for each case reducer function
export const FollowUpOptionsActions = FollowUpOptionsSlice.actions;
export {FollowUpOptionsThunks};

export default FollowUpOptionsSlice.reducer;
