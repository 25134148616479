import React, {useEffect, useState} from 'react';
import {Button, Box} from '@mui/material';
import {CommonTextInput} from '../commonComponents';

import Colors from '../Resources/COLORS/Colors';

function UserIdAndPassWordLogin(props) {
  const {userNameErr, handleSubmit, passwordErr} = props;

  const [userName, setuserName] = useState('');
  const [password, serPassword] = useState('');

  let data = {
    username: userName,
    password,
  };

  return (
    <Box
      component="form"
      method="post"
      onSubmit={e => handleSubmit(e, data)}
      sx={{mt: 1, maxWidth: '320px'}}>
      <CommonTextInput
        onChange={setuserName}
        style={{width: '100%'}}
        sx={{width: '100%', minWidth: '320px'}}
        margin="normal"
        required
        name="username"
        value={userName}
        width="fullWidth"
        id="Username"
        label="Username"
        autoFocus={true}
        placeholder="Username"
        customize
        error={!!userNameErr}
        helperText={userNameErr}
        helperTextStyle={{margin: 0}}
        maxLength={26}
      />

      <CommonTextInput
        onChange={serPassword}
        style={{width: '100%', minWidth: '320px', marginTop: '20px'}}
        margin="normal"
        required
        name="password"
        value={password}
        width="fullWidth"
        id="Username"
        type="password"
        label="Password"
        placeholder="Password"
        customize
        error={!!passwordErr}
        helperText={passwordErr}
        helperTextStyle={{margin: 0}}
      />

      <Button
        type="Save"
        variant="contained"
        onClick={e => handleSubmit(e, data)}
        sx={{
          marginTop: '20px',
          width: '100%',
          minWidth: '320px',
          background: Colors.APPCIRCLE,
          fontSize: '18px',
          textAlign: 'center',
          color: Colors.WHITE,
          fontWeight: '700',
          padding: '10px 0',
          borderRadius: '15px',
          cursor: 'pointer',
          '&:hover': {
            background: Colors.APPCIRCLE,
          },
        }}>
        Log in
      </Button>
    </Box>
  );
}

export default UserIdAndPassWordLogin;
