import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes as BrowseRoutes,
} from 'react-router-dom';
import {CreateQuestionnaire} from '../otherComponents';
import {EditProfile, Profile} from '../profile';
import * as SuperDoctor from '../SuperDoctor';
import BodyModal from '../SuperDoctor/bodyParts/BodyModal';
import {Category, CreateCategory} from '../SuperDoctor/categorie';
import {CreateFollowUp, FollowUp} from '../SuperDoctor/followup';
import {LogInScene} from '../SuperDoctor/login';
import Showlist from '../SuperDoctor/question/Showlist';
import {
  CreateSubSpecialist,
  CreateSubspecialistRange,
  Subspecialist,
  SubspecialistRange,
} from '../SuperDoctor/subspecialtie';
import {SyExam} from '../SuperDoctor/syExams';
import CreateUpdateTask from '../scenes/tasklistScene/CreateUpdateTask';
import {TaskSet, UpdateTaskSet} from '../scenes/taskSetsScene';
import TaskList from '../scenes/tasklistScene/TaskList';
import Versions from '../scenes/Versions';
import Symptoms from '../SuperDoctor/symptom/Symptoms';
import CreateSymptoms from '../SuperDoctor/symptom/CreateSymptoms';
import Facts from '../SuperDoctor/fact/Facts';
import CreateFacts from '../SuperDoctor/fact/CreateFacts';
import ShowSymptomstree from '../SuperDoctor/symptom/ShowSymptomstree';
import LanguagesUpdate from '../SuperDoctor/language/LanguagesUpdate';
import Languages from '../SuperDoctor/language/Languages';
import {BodyPartList, BodyPartSymptoms} from '../SuperDoctor/bodyParts';
import {CreateActivity, UpdateActivity} from '../SuperDoctor/activity';

const SuperAdminRoutes = () => {
  return (
    <Router>
      <BrowseRoutes>
        <Route path="/" exact element={<SuperDoctor.Department />} />
        <Route path="/departments" element={<SuperDoctor.Department />} />
        <Route path="/departments/:id/body_parts" element={<BodyModal />} />
        <Route path="/subspecialties" element={<Subspecialist />} />
        <Route path="/symptoms" element={<Symptoms />} />
        <Route path="/questions" element={<Showlist />} />
        <Route path="/questions/new" element={<CreateQuestionnaire />} />
        <Route path="/bodyparts" element={<BodyPartList />} />
        <Route path="/bodyparts/:id/symptoms" element={<BodyPartSymptoms />} />
        <Route path="/questions/:id/edit" element={<CreateQuestionnaire />} />
        <Route path="/followups" exact element={<FollowUp />} />
        <Route path="/followups/new" element={<CreateFollowUp />} />
        <Route path="/followups/:id/edit" element={<CreateFollowUp />} />
        <Route path="/languages" element={<Languages />} />
        <Route path="/languages/:id/edit" element={<LanguagesUpdate />} />
        <Route path="/task_sets" element={<TaskSet />} />
        <Route path="/task_sets_update/:id/edit" element={<UpdateTaskSet />} />
        <Route path="/task/:id" element={<TaskList />} />
        <Route path="/task/:id/new" element={<CreateUpdateTask />} />
        <Route path="/task/:id/edit" element={<CreateUpdateTask />} />
        <Route path="/versions" element={<Versions />} />

        <Route
          path="/subspecialty_score_ranges/:id"
          element={<SubspecialistRange />}
        />
        <Route
          path="/subspecialty_score_ranges/:id/new"
          element={<CreateSubspecialistRange />}
        />
        <Route
          path="/subspecialty_score_ranges/:id/edit"
          element={<CreateSubspecialistRange />}
        />
        <Route
          path="/departments/new"
          element={<SuperDoctor.CreateDepartmentForm />}
        />
        <Route
          path="/departments/:id/edit"
          element={<SuperDoctor.CreateDepartmentForm />}
        />
        <Route path="/subspecialties/new" element={<CreateSubSpecialist />} />
        <Route
          path="/subspecialties/:id/edit"
          element={<CreateSubSpecialist />}
        />
        <Route path="/symptoms/new" element={<CreateSymptoms />} />
        <Route path="/symptoms/:id/edit" element={<CreateSymptoms />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/profile/edit" element={<EditProfile />} />
        <Route path="/login" element={<LogInScene />} />
        <Route path="/symptoms/:id" element={<ShowSymptomstree />} />
        <Route path="/subspecialties/:id/sy_exam" element={<SyExam />} />
        <Route path="/categories" element={<Category />} />
        <Route path="/category/new" element={<CreateCategory />} />
        <Route path="/category/:id/edit" element={<CreateCategory />} />
        <Route path="/Facts" element={<Facts />} />
        <Route path="/Facts/new" element={<CreateFacts />} />
        <Route path="/Facts/:id" element={<CreateFacts />} />
        <Route path="/activities" element={<CreateActivity />} />
        <Route path="/activities/:id/edit" element={<UpdateActivity />} />
      </BrowseRoutes>
    </Router>
  );
};

export default SuperAdminRoutes;
