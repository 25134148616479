import {Box, Checkbox, Button} from '@mui/material';
import React, {useCallback, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router';
import debounce from 'lodash/debounce';
import {CommonTextInput, Header, LocalAlert} from '../../commonComponents';
import ApiClient from '../../networking/ApiManager';

const LanguagesUpdate = () => {
  const {id} = useParams();
  const navigate = useNavigate();

  const location = useLocation();
  const {code, locale, checked, translation} = location.state || {};

  const [language, setLanguage] = useState(locale || '');
  const [codes, setCodes] = useState(code || '');
  const [check, setCheck] = useState(checked || false);
  const [upDateId, setUpdateId] = useState(id || '');
  const [translationsList, setTranslationsList] = useState(translation || {});
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertType, setAlertType] = useState('error');

  const handleCheckChange = useCallback(() => {
    setCheck(prevCheck => !prevCheck);
  }, []);

  const updateLanguage = useCallback(() => {
    const params = {
      locale: language,
      default: check,
      code: codes,
      json_attributes: translationsList,
    };
    setLanguage('');
    setCodes('');
    setUpdateId('');
    ApiClient.updateLanguage(params, upDateId)
      .then(response => {
        setShowAlert(true);
        setAlertMessage('Language Updated Successfully');
        setAlertType('success');
        setTimeout(() => {
          navigate(-1);
        }, 500);
      })
      .catch(error => {
        console.log(error);
        setShowAlert(true);
        setAlertMessage('Please Enter Language');
        setAlertType('error');
      });
  }, [language, check, codes, translationsList, upDateId, navigate]);

  const debouncedUpdateTranslation = debounce((key, newValue) => {
    const updatedtranslationsList = {...translationsList};
    updatedtranslationsList[key] = newValue;
    setTranslationsList(updatedtranslationsList);
  }, 0);

  const handleChange = debounce((newValue, setterFunction) => {
    setterFunction(newValue);
  }, 0);

  return (
    <div>
      <Box>
        <Header />
        <Box sx={{margin: 2, overflowY: 'auto'}}>
          <h2 style={{margin: '15px 0'}}>{'Update Languages:-'}</h2>

          <CommonTextInput
            maxLength={100}
            label="Language"
            validateStringOnly
            value={language}
            onChange={e => handleChange(e, setLanguage)}
            style={{m: 1, width: '100%'}}
          />

          <CommonTextInput
            maxLength={100}
            label="Code"
            validateStringOnly
            value={codes}
            onChange={e => handleChange(e, setCodes)}
            style={{m: 1, width: '100%', margin: '15px 0'}}
          />

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            <Checkbox
              checked={check}
              onChange={handleCheckChange}
              inputProps={{'aria-label': 'controlled'}}
            />
            <div
              style={{
                marginLeft: 3,
                fontWeight: '400',
                fontSize: '12px',
                alignSelf: 'center',
              }}>
              Default Checked
            </div>
          </div>
          <h2 style={{margin: '15px 0'}}>{'Translation :-'}</h2>

          <div
            style={{
              overflowY: 'auto',
            }}>
            {translationsList && typeof translationsList === 'object' ? (
              Object.keys(translationsList).map((key, index) => {
                const label = key
                  .split('_')
                  .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(' ');

                return (
                  <CommonTextInput
                    key={index}
                    maxLength={250}
                    label={label}
                    value={translationsList[key]}
                    onChange={newValue =>
                      debouncedUpdateTranslation(key, newValue)
                    }
                    style={{
                      margin: '15px 0px',
                      width: '100%',
                    }}
                  />
                );
              })
            ) : (
              <p>No translationsList available</p>
            )}
          </div>
          <div
            style={{
              height: 40,
              padding: 20,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Button
              variant="contained"
              sx={{
                width: '98%',
                backgroundColor: '#405672',
                marginTop: 2,
                position: 'absolute',
              }}
              onClick={updateLanguage}>
              Update Language
            </Button>
          </div>
        </Box>
      </Box>
      {showAlert && (
        <LocalAlert
          alertText={alertMessage}
          onClose={() => setShowAlert(false)}
          severity={alertType}
        />
      )}
    </div>
  );
};

export default LanguagesUpdate;
