import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import ApiClient from '../../networking/ApiManager';

const initialState = {
  data: [],
  isLoading: true,
};

export const getLanguage = createAsyncThunk(
  'language/getLanguage',
  async thunkAPI => {
    try {
      const response = await ApiClient.getLanguages();
      return {data: response.data.data};
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getLanguage.pending, state => {
        state.isLoading = true;
      })
      .addCase(getLanguage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isRefreshing = false;
        state.firstLoad = false;
        state.data = action.payload.data;
      })
      .addCase(getLanguage.rejected, (state, action) => {
        state.isLoading = false;
        state.isRefreshing = false;
        state.error = action.error.message;
      });
  },
});

export const LanguageActions = languageSlice.actions;
export default languageSlice.reducer;
