import React, {useEffect, useMemo, useState} from 'react';
import {
  CssBaseline,
  Box,
  Typography,
  Container,
  createTheme,
  ThemeProvider,
  Modal,
} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {getAuth, signInWithPhoneNumber, RecaptchaVerifier} from 'firebase/auth';
import {useTranslation} from 'react-i18next';

import './login.css';
import {Loader, LocalAlert} from '../../commonComponents';
import LoginForm from './LoginForm';
import {UserIdAndPassWordLogin} from '../../otherComponents';
import ApiClient from '../../networking/ApiManager';
import {
  loginHeaderImage,
  loginHeaderImageMobile,
  logoSvg,
} from '../../Resources/Images';
import Colors from '../../Resources/COLORS/Colors';

const theme = createTheme();
function LogInScene() {
  const {t, i18n} = useTranslation();
  const [phone, setPhone] = useState('');
  const [open, setOpen] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [isApiCalled, setIsApiCalled] = useState(false);
  const isPatient = false;
  const [showOtp, setShowOtp] = useState(false);
  const [otp, setOtp] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertText, setAlertText] = useState(t('invalid_phone_number'));
  const [btnText, setBtnText] = useState(t('get_otp'));
  const [loading, setLoading] = useState(false);
  const [errorType, setErrorType] = useState('error');
  const [confirmOtpFunction, setConfirmOtpFunction] = useState(null);
  const [authToken, setAuthToken] = useState(null);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [isUserNameLogin, setIsUserNameLogin] = useState(true);
  const [userNameErr, setUserNameErr] = useState(false);
  const [passwordErr, setPasswordErr] = useState(false);
  const [countryCode, setCountryCode] = useState('🇮🇳 +91');
  const [resent, setResent] = useState(false);
  const toggleTitle = useMemo(() => {
    if (isUserNameLogin) {
      return 'Login With OTP';
    } else {
      return 'Login With User Name';
    }
  }, [isUserNameLogin]);

  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    setBtnText(t('get_otp'));
  }, [t]);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    borderRadius: '20px',
    boxShadow: 24,
  };

  let verifySessionUser = localStorage.getItem('user');
  let departmentCode = localStorage.getItem('departmentCode');
  let languageId = localStorage.getItem('languageId');
  let languageCode = localStorage.getItem('languageCode');

  useEffect(() => {
    const urlString = window.location.href;
    const url = new URL(urlString);
    const loginMode = url.searchParams.get('login_mode');
    const hospitalCode = url.searchParams.get('hospital_code');
    const departmentCode = url.searchParams.get('department_code');
    if (!languageCode || !languageId || loginMode || hospitalCode) {
      if (loginMode) {
        localStorage.setItem('loginMode', loginMode);
      }
      if (hospitalCode) {
        localStorage.setItem('hospitalCode', hospitalCode);
      }
      if (departmentCode) {
        localStorage.setItem('departmentCode', departmentCode);
      }
      const path = languageId
        ? loginMode || hospitalCode
          ? '/registration_login'
          : '/'
        : '/';
      navigate(path, {replace: true});
    }
    verifySessionUser && navigateToHome();
  }, []);

  const navigateToHome = () => {
    document.title = 'MyDocSy';
    navigate('/');
  };

  useEffect(() => {
    if (isApiCalled) {
      if (admin) {
        localStorage.setItem('user', 'doctor');
        window.location.replace('/');
        setAlertText(t('otp_successfully_verified'));
        setErrorType('success');
      } else {
        setOtpError(t('invalid_otp'));
        setPhoneNumberError(t('invalid_phone_number'));
      }
      setIsApiCalled(false);
    }
  }, [isApiCalled]);

  useEffect(() => {
    !verifySessionUser && (document.title = 'Log In');
  }, []);

  useEffect(() => {
    i18n.changeLanguage(languageCode);
  }, [languageCode]);

  useEffect(() => {
    setShowOtp(false);
    setBtnText(t('get_otp'));
    setConfirmOtpFunction(null);
    setOtpError(false);
    setOtp('');
    setPhoneNumberError(false);
  }, [phone, countryCode]);

  const getOtpFromFirebase = () => {
    let newcountryCode;
    if (Array.isArray(countryCode)) {
      newcountryCode = '+' + countryCode[0].replace(/^\D+/g, '');
    } else {
      newcountryCode = '+' + countryCode.replace(/^\D+/g, '');
    }

    setLoading(true);
    window.recaptchaVerifier =
      window.recaptchaVerifier ??
      new RecaptchaVerifier(
        'recaptchaVerify',
        {
          size: 'invisible',
          callback: response => {
            console.log('response');
          },
        },
        auth,
      );

    signInWithPhoneNumber(
      auth,
      `${newcountryCode}${phone}`,
      window.recaptchaVerifier,
    )
      .then(confirmationResult => {
        setResent(true);
        setShowOtp(true);
        setBtnText(t('verify_otp'));
        setConfirmOtpFunction(confirmationResult);
        setLoading(false);
      })
      .catch(error => {
        if (error.code == 'auth/invalid-phone-number') {
          setPhoneNumberError(t('invalid_phone_number'));
        } else {
          setAlertText(t('failed_to_send_otp'));
          setErrorType('error');
          setShowAlert(true);
        }
        setLoading(false);
      });
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (showOtp) {
      verifyOtp();
    } else {
      setOtp('');
      setOtpError(false);
      getOtp();
    }
  };

  const getOtp = () => {
    if (phone.length >= 10) {
      setPhoneNumberError(false);
      getOtpFromFirebase();
    } else {
      setPhoneNumberError(t('phone_number_10_digits'));
      setLoading(false);
    }
  };

  const reset = () => {
    setPhone('');
    setOpen(false);
    setPhoneNumberError(true);
  };

  const yes = () => {
    setResent(false);
    setOpen(false);
    getOtpFromFirebase();
    setOtpError(false);
    setOtp('');
  };

  const verifyOtp = () => {
    if (otp.toString().length == 6) {
      setLoading(true);
      confirmOtpFunction
        .confirm(otp)
        .then(result => {
          setAuthToken(result._tokenResponse.idToken);
          setShowAlert(true);
          loginUser();
          setLoading(false);
          document.title = 'MyDocSy';
          setOtpError(false);
        })
        .catch(error => {
          setOtpError(t('invalid_otp'));
          setLoading(false);
        });
    } else {
      setOtpError(t('invalid_otp'));
      setLoading(false);
    }
  };

  const setSession = (data, user) => {
    let userId = user == 'doctor' ? 'doctor_id' : 'patient_id';
    let userAge = user == 'doctor' ? true : data.age;
  };

  const setDoctorParams = response => {
    document.title = 'MyDocSy';
    setSession(response.data.data, 'doctor');
    localStorage.setItem('role', response.data.data.role);
    setLoading(false);
    setAdmin(response.data.data.super_admin);
    setIsApiCalled(true);
  };

  const loginUser = () => {
    let newcountryCode;
    if (Array.isArray(countryCode)) {
      newcountryCode = '+' + countryCode[0].replace(/^\D+/g, '');
    } else {
      newcountryCode = '+' + countryCode.replace(/^\D+/g, '');
    }

    setLoading(true);
    const formData = {
      phone,
      id_token: authToken,
      country_code: newcountryCode,
    };

    if (departmentCode) {
      formData.department_code = departmentCode;
    }

    ApiClient.doctorsLogin(formData)
      .then(response => {
        setDoctorParams(response);
      })
      .catch(error => {
        console.log(error.response);
        setLoading(false);
      });
  };

  const validateMovileNumber = phNo => {
    const regex = /^[0-9]{0,10}$/;
    if (regex.test(phNo)) {
      setPhone(phNo);
      setShowAlert(false);
    }
  };

  const headerImage =
    window.innerWidth < 700 ? loginHeaderImageMobile : loginHeaderImage;

  const setInputOtp = otp => {
    setOtp(otp);
    setOtpError(null);
  };

  const onToggle = () => {
    setIsUserNameLogin(p => !p);
    setUserNameErr(false);
    setPasswordErr(false);
    setPhoneNumberError(false);
  };

  const alreadyAccoutnBtn = () => {
    return (
      <div>
        <div style={{textAlign: 'center', marginTop: '15px'}}>OR</div>
        <div
          type="submit"
          variant="contained"
          onClick={onToggle}
          style={{
            marginTop: '15px',
            width: '100%',
            minWidth: '320px',
            background: Colors.APPCIRCLE,
            fontSize: '18px',
            textAlign: 'center',
            color: Colors.WHITE,
            fontWeight: '700',
            padding: '12px 0',
            borderRadius: '15px',
            cursor: 'pointer',
            '&:hover': {
              background: Colors.APPCIRCLE,
            },
          }}>
          {toggleTitle}
        </div>
      </div>
    );
  };

  const loginWithUserName = (event, data) => {
    event.preventDefault();

    if (data.username.trim().length == 0) {
      setUserNameErr('Invalid UserName');
    } else {
      setUserNameErr(false);
    }
    if (data.password.trim().length == 0) {
      setPasswordErr('Invalid Password');
    } else {
      setPasswordErr(false);
    }
    if (data.username.trim().length == 0 || data.password.trim().length == 0) {
      return;
    }
    setLoading(true);
    ApiClient.loginWithPassword(data)
      .then(res => {
        setLoading(false);

        setDoctorParams(res);
      })
      .catch(err => {
        setLoading(false);
        setPasswordErr(err.response.data.data);
        setUserNameErr(err.response.data.data);
      });
  };

  return (
    <>
      <div id="recaptchaVerify" style={{display: 'none'}} />
      <div
        style={{
          width: '100%',
          backgroundImage: `url(${headerImage})`,
          backgroundPosition: 'bottom',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          display: 'flex',
          marginBottom: '20px',
        }}>
        <div style={{width: '100%', margin: 'auto', marginTop: '12%'}}>
          <img
            src={logoSvg}
            alt="logo"
            style={{maxWidth: '30%', display: 'block', margin: 'auto'}}
          />
          <div style={{width: '100%'}}>
            <p
              style={{
                fontSize: '1.2rem',
                fontWeight: '700',
                color: Colors.WHITE,
                textAlign: 'center',
              }}>
              {t('sign_in')}
            </p>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignSelf: 'center',
            }}
            id="modal-modal-title"
            variant="h6"
            component="h2">
            Resend Otp
          </Typography>
          <Typography
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignSelf: 'center',
            }}
            id="modal-modal-description">
            Resend OTP To {phone}?
          </Typography>
          <Typography
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignSelf: 'center',
              mt: 2,
              flexDirection: 'column',
            }}
            id="modal-modal-description">
            <hr
              style={{
                borderColor: Colors.SHADOWCOLOR,
                borderWidth: '.01px',
                width: '100%',
              }}
            />
            <Typography
              onClick={reset}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignSelf: 'center',
                // border: '1px solid black',
                color: Colors.APPCIRCLE,
                cursor: 'pointer',
              }}>
              Different Number
            </Typography>
            <hr
              style={{
                borderColor: Colors.SHADOWCOLOR,
                borderWidth: '.1px',
                width: '100%',
              }}
            />
            <Typography
              onClick={yes}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignSelf: 'center',
                color: Colors.APPCIRCLE,
                marginBottom: '10px',
                cursor: 'pointer',
              }}>
              yes
            </Typography>
          </Typography>
        </Box>
      </Modal>

      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            {isUserNameLogin && !isPatient ? (
              <UserIdAndPassWordLogin
                btnText={btnText}
                phone={phone}
                userNameErr={userNameErr}
                passwordErr={passwordErr}
                handleSubmit={loginWithUserName}
                validateMovileNumber={validateMovileNumber}
                showOtp={showOtp}
                setOtp={setOtp}
                otp={otp}
              />
            ) : (
              <LoginForm
                setBtnText={setBtnText}
                resent={resent}
                btnText={btnText}
                phone={phone}
                phoneNumberError={phoneNumberError}
                otpError={otpError}
                handleSubmit={handleSubmit}
                validateMovileNumber={validateMovileNumber}
                showOtp={showOtp}
                setOtp={setInputOtp}
                otp={otp}
                setOpen={setOpen}
                getOtp={getOtp}
                open={open}
                countryCode={countryCode}
                setCountryCode={setCountryCode}
              />
            )}
            {!isPatient && alreadyAccoutnBtn()}
          </Box>
        </Container>
      </ThemeProvider>
      {showAlert && (
        <LocalAlert
          onClose={() => setShowAlert(false)}
          severity={errorType}
          alertText={alertText}
        />
      )}
      <Loader loading={loading} />
    </>
  );
}

export default LogInScene;
