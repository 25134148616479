import {Box} from '@mui/material';
import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router';
import {BodySectionAddEditModal, CommonTable} from '../../commonComponents';
import AsyncThunks from '../../redux/AsyncThunks';

const initalBodySection = {
  id: null,
  name: '',
  lname: {},
  image_url: '',
  image: '',
  department_id: null,
};

const DepartmentBodySection = (_, ref) => {
  const {data, isLoading} = useSelector(state => state.BodySectionsSlice);
  const [bodySectionAddEdit, setBodySectionAddEdit] = useState(false);
  const {id} = useParams();
  const [bodySection, setBodySection] = useState({
    ...initalBodySection,
    department_id: id,
  });
  const {bodydata, hasParts} = useMemo(() => {
    const parts = data.filter(item => item.department_id === +id);
    return {
      bodydata: parts,
      hasParts: parts.length > 0,
    };
  }, [data, id]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!hasParts && id) {
      dispatch(AsyncThunks.getBodySections({department_id: id}));
    }
  }, [id, dispatch, hasParts]);

  React.useImperativeHandle(ref, () => ({
    add: () => {
      setBodySectionAddEdit(true);
    },
  }));

  const onModalClose = () => {
    setBodySectionAddEdit(false);
    setBodySection({...initalBodySection, department_id: id});
  };

  const tableHeaderData = ['Id', 'Name', 'Gender', 'Image'];
  const dataKeys = ['id', 'name', 'gender'];

  return (
    <Box margin={2}>
      {hasParts && <h4 style={{marginTop: '20px'}}>Body section images</h4>}
      {hasParts && (
        <CommonTable
          data={bodydata}
          tableHeaderData={tableHeaderData}
          dataKeys={dataKeys}
          showImageKey={'image_url'}
          showDelete
          showUpdate
          onImageClick={item =>
            navigate(`/departments/${item.id}/body_parts`, {state: {id}})
          }
          onPressUpdate={(id, item) => {
            setBodySection(item);
            setBodySectionAddEdit(true);
          }}
          tableStyle={{width: '100%'}}
          refresh={() =>
            dispatch(AsyncThunks.getBodySections({department_id: id}))
          }
          isFetching={isLoading}
          apiType="bodySection"
          hideToggle
        />
      )}
      <BodySectionAddEditModal
        close={onModalClose}
        visible={bodySectionAddEdit}
        bodySection={bodySection}
        setBodySection={setBodySection}
      />
    </Box>
  );
};

export default React.forwardRef(DepartmentBodySection);
