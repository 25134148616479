import React from 'react';
import {Box, Grid} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {useTableData} from '../../hooks';
import {
  CommonButton,
  CommonDropDown,
  CommonTable,
  Header,
} from '../../commonComponents';

function Subspecialist() {
  const navigate = useNavigate();

  const table = useTableData();

  const createSubspecialist = () => {
    navigate('/subspecialties/new');
  };

  const updateSubspecialist = id => {
    navigate(`/subspecialties/${id}/edit`);
  };

  const tableHeaderData = [
    'Id',
    'Name',
    'Departments Name',
    'Category',
    'Created At',
    'Effective Index',
    'Image',
  ];
  const dataKeys = [
    'id',
    'name',
    'department_name',
    'category',
    'created_at',
    'effective_index',
  ];

  return (
    <Box>
      <Header />
      <Box sx={{margin: 2}}>
        <Grid
          container
          spacing={{xs: 2, md: 3}}
          columns={{xs: 4, sm: 8, md: 12}}>
          <Grid item xs={2} sm={4} md={8}>
            <p style={{fontSize: 30, margin: '15px'}}>Subspecialty :- </p>
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <CommonDropDown
              value={table.departmentId}
              formControlStyle={{width: '100%', margin: '15px -13px'}}
              label="Department Name"
              labelId="Select Department"
              onChange={e => {
                table.setDepartmentId(e);
              }}
              selectStyle={{width: '100%'}}
              data={table.departments}
              setData={'id'}
              showData={'name'}
            />
          </Grid>
        </Grid>

        <CommonButton
          style={{width: '98.4%', backgroundColor: '#405672', marginTop: 2}}
          label="Create Subspecialty"
          onClick={createSubspecialist}
        />

        <CommonTable
          data={table.subspecialisties}
          tableHeaderData={tableHeaderData}
          dataKeys={dataKeys}
          showImageKey="image_url"
          showUpdate
          showDelete
          onPressUpdate={updateSubspecialist}
          tableStyle={{width: '100%'}}
          showDragAndDrop
          refresh={table.getSubspecialties}
          apiType="subspecialtie"
          isFetching={table.isLoading}
          emptyTitle="No Subspecialties Found"
        />
      </Box>
    </Box>
  );
}

export default Subspecialist;
