import React, {useEffect, useState} from 'react';
import {Box, Button, FormControl, Modal} from '@mui/material';
import {CommonTextInput, LocalAlert, CommonDropDown} from '../commonComponents';
import ApiClient from '../networking/ApiManager';
import LanguageDropdown from '../commonComponents/LanguageDropdown';
import Loader from '../commonComponents/Loader';

function OptionsForm(props) {
  const [lsymptomOptionsSign, setSymptomLOptionsSign] = useState(
    props.data ? props.data.loption_label : {},
  );
  const [symptomOptionsCode, setSymptomOptionsCode] = useState(
    props.data ? props.data.option_code : '',
  );
  const [adtionalInput, setAdtionalInput] = useState(
    props.data ? props.data.additional_input_type : '',
  );
  const [optionId, setOptionId] = useState(props.data ? props.data.id : null);
  const [loading, setLoading] = useState(false);
  const [score, setScore] = useState(props?.data?.score);

  useEffect(() => {
    if (Array.isArray(symptomOptionsCode)) {
      setSymptomOptionsCode(symptomOptionsCode[0]);
    }
  }, [symptomOptionsCode]);

  let params = {
    loption_label: lsymptomOptionsSign,
    option_code: symptomOptionsCode,
    additional_input_type: adtionalInput?.toString(),
    symptom_questionnaire_id:
      props.data?.symptom_questionnaire_id || props.questionnaireId,
    score: score,
  };

  if (props.parentId) {
    params.parent_id = props.parentId;
  }

  const clearFormData = () => {
    setSymptomOptionsCode('');
    setOptionId(null);
  };

  const updateData = () => {
    setLoading(true);
    ApiClient.symptomsQuestionsOptionUpdate(params, optionId)
      .then(res => {
        props.refresh && props.refresh();
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        console.log(err.response);
      });
  };

  const deleteData = () => {
    const confirmDelete = window.confirm(
      'Are you sure you want to delete this option?',
    );
    if (confirmDelete) {
      setLoading(true);
      ApiClient.deleteSymptomsQuestionsOption(props.optionId || optionId)
        .then(res => {
          clearFormData();
          props.refresh && props.refresh();
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          console.log(err.response);
        });
    }
  };

  const createData = () => {
    if (
      Object.keys(lsymptomOptionsSign).length === 0 ||
      Object.values(lsymptomOptionsSign)[0] === ''
    ) {
      alert('Please fill option user');
    } else if (!symptomOptionsCode) {
      alert('Please fill option system');
    } else if (!score) {
      alert('Please fill score');
    } else {
      setLoading(true);

      ApiClient.createSymptomsQuestionsOption(params)
        .then(res => {
          setOptionId(res.data.data.id);
          props.refresh && props.refresh();
        })
        .catch(err => {
          console.log(err.response);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const createBtnMargin = !props.isCreate ? '20px 10px 20px 0' : '20px 0px';
  const additionalInputData = [{id: 'string', name: 'String'}];

  const renderOptionSystem = () => {
    const durationData = [
      {name: 'hours'},
      {name: 'days'},
      {name: 'weeks'},
      {name: 'months'},
      {name: 'years'},
    ];
    if (props.data?.option_type == 'duration' || props.isDuration) {
      return (
        <CommonDropDown
          formControlStyle={{
            minWidth: '26ch',
            width: '80vw',
            marginLeft: 8,
          }}
          label="Option System"
          labelId="Option_system"
          onChange={setSymptomOptionsCode}
          value={symptomOptionsCode}
          selectStyle={{width: '100%'}}
          data={durationData}
          setData={'name'}
          showData={'name'}
        />
      );
    } else {
      return (
        <FormControl
          sx={{m: 1, minWidth: '26ch', width: '80vw'}}
          variant="outlined">
          <CommonTextInput
            maxLength={500}
            showLength={150}
            label="Option System"
            style={{m: 1, width: '100%'}}
            onChange={setSymptomOptionsCode}
            value={symptomOptionsCode}
          />
          <div style={{marginTop: '1%'}}>
            <CommonDropDown
              formControlStyle={{
                minWidth: '26ch',
                width: '80vw',
              }}
              label="Additional Input Type"
              labelId="Additional_Input_Type"
              onChange={setAdtionalInput}
              value={adtionalInput}
              selectStyle={{width: '100%'}}
              data={additionalInputData}
              setData={'id'}
              showData={'name'}
            />
          </div>
        </FormControl>
      );
    }
  };

  const renderScoreInput = () => {
    return (
      <FormControl
        sx={{m: 1, minWidth: '26ch', width: '80vw'}}
        variant="outlined">
        <CommonTextInput
          maxLength={500}
          allowNegativeDecimaleNumber
          showLength={150}
          label="Score"
          style={{m: 1, width: '100%'}}
          onChange={setScore}
          value={score}
        />
      </FormControl>
    );
  };

  return (
    <div
      style={{
        textAlign: 'center',
        display: 'flex',
        flexWrap: 'wrap',
        margin: '0 15px 20px 15px',
      }}>
      <div style={{textAlign: 'center', margin: 'auto'}}>
        <FormControl
          sx={{m: 1, minWidth: '26ch', width: '80vw'}}
          variant="outlined">
          <LanguageDropdown
            inputValue={lsymptomOptionsSign}
            onChange={setSymptomLOptionsSign}
            label="Option User"
          />
        </FormControl>
        <div style={{textAlign: 'left'}}>{renderOptionSystem()}</div>
        <div style={{textAlign: 'left'}}>{renderScoreInput()}</div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '80vw',
            margin: 'auto',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          {props.showActions || optionId ? (
            <>
              <Button
                variant="contained"
                sx={{
                  width: '100%',
                  backgroundColor: '#405672',
                  margin: createBtnMargin,
                }}
                component="span"
                onClick={updateData}>
                Update Option
              </Button>
              {props.data?.leaf_node && (
                <Button
                  variant="contained"
                  sx={{
                    width: '100%',
                    backgroundColor: '#405672',
                    margin: '20px 0 20px 10px',
                  }}
                  component="span"
                  onClick={deleteData}>
                  Delete Option
                </Button>
              )}
            </>
          ) : (
            <Button
              variant="contained"
              sx={{
                width: '100%',
                backgroundColor: '#405672',
                margin: '20px 0 20px 10px',
              }}
              component="span"
              onClick={createData}>
              Save
            </Button>
          )}
        </div>
      </div>
      <Loader loading={loading} />
    </div>
  );
}

export default OptionsForm;
