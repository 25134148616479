import React, {useCallback} from 'react';
import {useParams} from 'react-router';
import ApiClient from '../../networking/ApiManager';
import {CommonTable, Header, TableHeaderTitle} from '../../commonComponents';

const BodyPartSymptoms = () => {
  const [bodyPartSymptoms, setBodyPartSymptoms] = React.useState([]);
  const {id} = useParams();

  const getBodyPartSymptoms = useCallback(() => {
    ApiClient.getBodyPartSymptoms(id)
      .then(response => {
        setBodyPartSymptoms(response.data.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, [id]);

  React.useEffect(() => {
    getBodyPartSymptoms();
    return () => {
      setBodyPartSymptoms([]);
    };
  }, [getBodyPartSymptoms]);

  const tableHeaderData = ['Id', 'Name', 'Sign', 'Department', 'Subspecialty'];
  const dataKeys = ['id', 'name', 'sign', 'department', 'subspecialty'];

  return (
    <div>
      <Header />
      <TableHeaderTitle title="Body Part Symptoms : -" />
      <CommonTable
        data={bodyPartSymptoms}
        tableHeaderData={tableHeaderData}
        dataKeys={dataKeys}
        emptyText="No Symptoms Found"
      />
    </div>
  );
};

export default BodyPartSymptoms;
