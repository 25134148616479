import React, {useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router';
import {MultipleSelectChip} from '../../commonComponents';
import ApiClient from '../../networking/ApiManager';

const SymptomKeywordInput = () => {
  const [searchKeyword, setSearchKeyword] = useState([]);
  const [selectedKeyword, setSelectedKeyword] = useState([]);
  const [keyword, setKeyword] = useState('');
  const {id} = useParams();

  const data = useMemo(() => {
    if (keyword) {
      return [{name: 'Add New Keyword', key: 'add'}, ...searchKeyword];
    } else {
      return searchKeyword;
    }
  }, [searchKeyword, keyword]);

  useEffect(() => {
    if (id) {
      selectedKey(id);
    }
  }, [id]);

  const selectedKey = symptom_id => {
    ApiClient.selectedSymptomKeyword(symptom_id)
      .then(res => {
        setSelectedKeyword(res.data.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const findKeyword = name => {
    setKeyword(name);
    if (!name) {
      setSearchKeyword([]);
      return;
    }
    const formKey = {
      name,
      category: 'symptom_synonym',
    };
    ApiClient.searchSymptomKeyword(formKey)
      .then(res => {
        setSearchKeyword(res.data.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const createKeyword = (keyword_id, name = keyword) => {
    if (!name) {
      alert('Please Enter A Keyword');
      return;
    }
    const params = {
      keyword_id,
      name,
      symptom_id: id,
    };
    ApiClient.createSymptomKeyword(params)
      .then(res => {
        const data = res.data.data;
        setSelectedKeyword(prev => [...prev, data]);
      })
      .catch(err => {
        console.log({err});
      });
  };

  const removeClicked = item => {
    setSelectedKeyword(prev => {
      ApiClient.deleteSymptomKeyword(item.keyword_collection_id);
      return prev.filter(
        i => i.keyword_collection_id !== item.keyword_collection_id,
      );
    });
  };

  const handleChangeKey = key => {
    if (key.key === 'add') {
      createKeyword(undefined);
    } else {
      setSelectedKeyword(prev => {
        const hasAlready = prev.find(i => {
          return i.keyword_id === key.keyword_id;
        });
        if (hasAlready) {
          const id = hasAlready.keyword_collection_id;
          id &&
            ApiClient.deleteSymptomKeyword(hasAlready.keyword_collection_id);
          return prev.filter(newItem => newItem.keyword_id !== key.keyword_id);
        } else {
          createKeyword(key.keyword_id, key.name);
          return prev;
        }
      });
    }
  };

  if (!id) {
    return null;
  }

  return (
    <div style={{marginTop: 10}}>
      <MultipleSelectChip
        onChange={findKeyword}
        label="Symptom Keywords"
        placeholder="Symptom Keywords..."
        data={data}
        selectedValue={selectedKeyword}
        onClick={handleChangeKey}
        onChipClick={removeClicked}
        isOptionEqualToValue={(item, value) =>
          item.keyword_id === value.keyword_id
        }
      />
    </div>
  );
};

export default SymptomKeywordInput;
