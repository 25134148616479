import axios from 'axios';
import {getAuth, signOut} from 'firebase/auth';
import Utility from '../commonComponents/Utility';
import EndPoints from './EndPoints';

axios.interceptors.response.use(
  response => {
    if (response?.data?.data?.auth_token) {
      localStorage.setItem('token', response.data.data.auth_token);
    }
    return response;
  },
  error => {
    if (error.response.status === 401) {
      const auth = getAuth();
      if (auth.currentUser) {
        signOut(auth)
          .then(() => {
            localStorage.clear();
            localStorage.clear();
            window.location.pathname = Utility.rootPath;
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        localStorage.clear();
        localStorage.clear();
        window.location.pathname = Utility.rootPath;
      }
    }
    return Promise.reject(error);
  },
);

axios.interceptors.request.use(
  config => {
    const isQuestionnaire = config.url.includes('/patient_responses');
    const isPatient = localStorage.getItem('user') === 'patient';
    const hasP1 = config.url.includes('p1');
    if (isQuestionnaire && !isPatient && hasP1) {
      config.url = config.url.replace('p1', 'd1');
      config.url = config.url.replace('patient_responses', 'nurse');
    }
    if (!config?.params?.ignoreAppendUrl) {
      const token = localStorage.getItem('token');
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    if (config?.params?.ignoreAppendUrl !== undefined) {
      delete config.params.ignoreAppendUrl;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

const baseUrl = process.env.REACT_APP_BASE_URL;

const BASE_URL = baseUrl + '/api/web/d1';
const PATIENT_BASE_URL = baseUrl + '/api/web/p1';

class ApiManager {
  // create apis here

  async uploadDocuments(params) {
    return axios({
      url: PATIENT_BASE_URL + EndPoints.DOCUMENTS,
      method: 'post',
      data: params,
    });
  }

  async transLateSymptom(id) {
    return axios({
      url: BASE_URL + EndPoints.SYMPTOMS + '/' + id + '/translate',
      method: 'post',
    });
  }

  async transLateSubspecialty(id) {
    return axios({
      url: BASE_URL + EndPoints.SUBSPECIALIST + '/' + id + '/translate',
      method: 'post',
    });
  }

  async createFacts(data) {
    return axios({
      url: BASE_URL + EndPoints.FACTS,
      method: 'post',
      data: data,
    });
  }

  async setPartPosition(params) {
    return axios({
      url: BASE_URL + EndPoints.BODYPARTPOSITION,
      method: 'post',
      data: params,
    });
  }

  async createBodySection(params) {
    return axios({
      url: BASE_URL + EndPoints.BODYSECTIONS,
      method: 'post',
      data: params,
    });
  }

  async updateBodySection(id, params) {
    return axios({
      url: BASE_URL + EndPoints.BODYSECTIONS + '/' + id,
      method: 'put',
      data: params,
    });
  }

  async updateSubspecialistScoreRange(id, editId, params) {
    return axios({
      url: BASE_URL + EndPoints.SUBSPECIALTY_SCORE_RANGE(id) + '/' + editId,
      method: 'put',
      data: params,
    });
  }

  async updateTasks(id, editId, params) {
    return axios({
      url: BASE_URL + EndPoints.TASKS(id) + '/' + editId,
      method: 'put',
      data: params,
    });
  }

  async updateActivity(id, params) {
    return axios({
      url: BASE_URL + EndPoints.ACTIVITIES + '/' + id,
      method: 'put',
      data: params,
    });
  }

  async getBodyPartSymptoms(id) {
    return axios({
      url: BASE_URL + EndPoints.BODY_PART_SYMPTOMS(id),
      method: 'get',
    });
  }

  async getVersions(page) {
    return axios({
      url: BASE_URL + EndPoints.VERSIONS + `?page=` + page,
      method: 'get',
    });
  }

  async getSubspecialistRange(id) {
    return axios({
      url: BASE_URL + EndPoints.SUBSPECIALTY_SCORE_RANGE(id),
      method: 'get',
    });
  }

  async getTasks(id, severity) {
    return axios({
      url: BASE_URL + EndPoints.TASKS(id) + `?severity=${severity}`,
      method: 'get',
    });
  }

  async loginWithPassword(params) {
    return axios({
      url: BASE_URL + EndPoints.LOGINWITHUSERNAMEANDPASSWORD,
      method: 'post',
      data: params,
    });
  }

  async AbhaOtpGenerate(params) {
    return axios({
      url: PATIENT_BASE_URL + EndPoints.ABHAOTPGENERATE,
      method: 'post',
      params,
    });
  }

  async AbhaOtpVerify(params) {
    return axios({
      url: PATIENT_BASE_URL + EndPoints.ABHAOTPVERIFY,
      method: 'post',
      params,
    });
  }
  async AbhaUpdateOtp(params) {
    return axios({
      url: PATIENT_BASE_URL + EndPoints.ABHAAADHARUPDATEOTPGENERATE,
      method: 'post',
      params,
    });
  }

  async createCategory(params) {
    return axios({
      url: BASE_URL + EndPoints.SYPMTOMCATEGORIES,
      method: 'post',
      data: params,
    });
  }

  async registrationLogin(params) {
    return axios.post(PATIENT_BASE_URL + EndPoints.REGISTRATION_LOGIN, params);
  }

  async presignedUrls(params) {
    return axios({
      url: PATIENT_BASE_URL + EndPoints.PRESIGNEDURL,
      method: 'post',
      data: params,
    });
  }

  async presignedUrlsForDoctor(params) {
    return axios({
      url: BASE_URL + EndPoints.PRESIGNEDURL,
      method: 'post',
      data: params,
    });
  }

  async createHeading(params) {
    return axios({
      url: BASE_URL + EndPoints.EXAMHEADINGS,
      method: 'post',
      data: params,
    });
  }

  async createSubspecialistRange(id, params) {
    return axios({
      url: BASE_URL + EndPoints.SUBSPECIALTY_SCORE_RANGE(id),
      method: 'post',
      data: params,
    });
  }

  async createTask(id, params) {
    return axios({
      url: BASE_URL + EndPoints.TASKS(id),
      method: 'post',
      data: params,
    });
  }

  async createExamEndItem(params) {
    return axios({
      url: BASE_URL + EndPoints.EXAMENDITEMS,
      method: 'post',
      data: params,
    });
  }

  async createHeadingOptions(params) {
    return axios({
      url: BASE_URL + EndPoints.HEADINGOPTIONS,
      method: 'post',
      data: params,
    });
  }

  async cloneSubspecialist(params, id) {
    return axios({
      url: BASE_URL + EndPoints.SUBSPECIALIST + '/' + id + '/clone',
      method: 'post',
      data: params,
    });
  }

  async cloneSymptoms(params, id) {
    return axios({
      url: BASE_URL + EndPoints.SYMPTOMS + '/' + id + '/clone',
      method: 'post',
      data: params,
    });
  }

  async cloneQuestions(params, id) {
    return axios({
      url: BASE_URL + EndPoints.SYMPTOMSQUESTION + '/' + id + '/clone',
      method: 'post',
      data: params,
    });
  }

  async Translate(id) {
    return axios({
      url: BASE_URL + EndPoints.SYMPTOMSQUESTION + '/' + id + '/translate',
      method: 'post',
    });
  }

  async createLanguage(params) {
    return axios({
      url: BASE_URL + EndPoints.LANGUAGES,
      method: 'post',
      data: params,
    });
  }

  async createActivity(params) {
    return axios({
      url: BASE_URL + EndPoints.ACTIVITIES,
      method: 'post',
      data: params,
    });
  }

  async createTaskSet(params) {
    return axios({
      url: BASE_URL + EndPoints.TASK_SETS,
      method: 'post',
      data: params,
    });
  }

  async createQuistonary(params) {
    return axios({
      url: PATIENT_BASE_URL + EndPoints.STARTQUESTIONARY,
      method: 'post',
      data: params,
    });
  }

  async createSymptomsQuestions(params) {
    return axios({
      url: BASE_URL + EndPoints.SYMPTOMSQUESTION,
      method: 'post',
      data: params,
    });
  }

  async createFollowUpQuestions(params) {
    return axios({
      url: BASE_URL + EndPoints.FollowUp,
      method: 'post',
      data: params,
    });
  }

  async createSymptomsQuestionsOption(params) {
    return axios({
      url: BASE_URL + EndPoints.SYMPTOMSQUIESTIONOPTION,
      method: 'post',
      data: params,
    });
  }

  async createFollowUpOption(params) {
    return axios({
      url: BASE_URL + EndPoints.FollowUpOptions,
      method: 'post',
      data: params,
    });
  }

  async createDepartment(params) {
    return axios({
      url: BASE_URL + EndPoints.DEPARTMENT,
      method: 'post',
      data: params,
    });
  }

  async doctorsLogin(params) {
    return axios({
      url: BASE_URL + EndPoints.DOCTORSLOGIN,
      method: 'post',
      data: params,
    });
  }

  async patientLogin(params) {
    return axios({
      url: PATIENT_BASE_URL + EndPoints.PATIENTLOGIN,
      method: 'post',
      data: params,
    });
  }

  async createSymptoms(params) {
    return axios({
      url: BASE_URL + EndPoints.SYMPTOMS,
      method: 'post',
      data: params,
    });
  }

  async createSubspecialist(params) {
    return axios({
      url: BASE_URL + EndPoints.SUBSPECIALIST,
      method: 'post',
      data: params,
    });
  }

  // get apis here

  async getFacts(id) {
    let url = BASE_URL + EndPoints.FACTS;
    if (id) {
      url = url + '/' + id;
    }

    return axios({
      url: url,
      method: 'get',
    });
  }

  async symptomCategoriesShow(id) {
    return axios({
      url: BASE_URL + `${EndPoints.SYPMTOMCATEGORIES}/${id}`,
      method: 'get',
    });
  }

  async patientsList(params) {
    return axios.get(BASE_URL + EndPoints.PATIENTUPDATE, {params});
  }

  async patientSearch(query) {
    let url = BASE_URL + EndPoints.PATIENTRESPONSESEARCH;
    if (query) {
      url += `/search?query=${query}`;
    }

    return axios({
      url: url,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  async getSymptomCategories(department_id, type) {
    let url = BASE_URL + `${EndPoints.SYPMTOMCATEGORIES}`;

    if (department_id && type) {
      url = url + '?department_id=' + department_id + '&type=' + type;
    } else if (department_id) {
      url = url + '?department_id=' + department_id;
    } else if (type) {
      url = url + '?type=' + type;
    }

    return axios({
      url: url,
      method: 'get',
    });
  }

  async getBodyPartsList(params) {
    return axios({
      url: BASE_URL + EndPoints.BODYSECTIONS,
      method: 'get',
      params,
    });
  }

  async getBodyPartSection(params) {
    return axios({
      url: BASE_URL + EndPoints.GETBODYPARTSECTION,
      method: 'get',
      params,
    });
  }

  async getsingleBodyPartsList(id) {
    return axios({
      url: BASE_URL + EndPoints.BODYSECTIONS + '/' + id,
      method: 'get',
    });
  }

  async getDocuments() {
    return axios({
      url: PATIENT_BASE_URL + EndPoints.DOCUMENTS,
      method: 'get',
    });
  }

  async getPendingResponse(id) {
    return axios({
      url: PATIENT_BASE_URL + EndPoints.PENDING_QUESTIONNAIRE + '/?id=' + id,
      method: 'get',
    });
  }

  async getSyExam(id) {
    return axios({
      url: BASE_URL + `${EndPoints.SUBSPECIALIST}/${id}/sy_exam`,
      method: 'get',
    });
  }

  async getQuestionaryResponse(id) {
    let baseUrl = PATIENT_BASE_URL + EndPoints.STARTQUESTIONARY;
    if (id) {
      baseUrl = baseUrl + '/' + id;
    }
    return axios({
      url: baseUrl,
      method: 'get',
    });
  }

  getResposeDetails(id) {
    return axios({
      url: PATIENT_BASE_URL + EndPoints.STARTQUESTIONARY + '/' + id,
      method: 'get',
    });
  }

  getHospitalDoctors(id) {
    return axios({
      url:
        PATIENT_BASE_URL + EndPoints.STARTQUESTIONARY + '/' + id + '/doctors',
      method: 'get',
    });
  }

  async getTermsConditions() {
    return axios({
      url: '/terms-conditions.html',
      method: 'get',
    });
  }

  async pendingPatientResponses(params) {
    return axios({
      url: PATIENT_BASE_URL + EndPoints.PENDING_QUESTIONNAIRE,
      method: 'get',
      params,
    });
  }

  async getSingleSubspecialist(id) {
    return axios({
      url: BASE_URL + EndPoints.SUBSPECIALIST + '/' + id,
      method: 'get',
    });
  }

  async getSingleDepartment(id) {
    return axios({
      url: BASE_URL + EndPoints.DEPARTMENT + '/' + id,
      method: 'get',
    });
  }

  async searchBodyParts(params) {
    return axios({
      url: BASE_URL + EndPoints.BODY_PART(),
      method: 'get',
      params,
    });
  }

  createBodyPart(params) {
    return axios.post(BASE_URL + EndPoints.BODY_PART(), params);
  }

  updateBodyPart(id, params) {
    return axios.put(BASE_URL + EndPoints.BODY_PART(id), params);
  }

  deleteBodyPart(id) {
    return axios.delete(BASE_URL + EndPoints.BODY_PART(id));
  }

  async getLanguages() {
    return axios({
      url: BASE_URL + EndPoints.LANGUAGES,
      method: 'get',
    });
  }

  async getActivities() {
    return axios({
      url: BASE_URL + EndPoints.ACTIVITIES,
      method: 'get',
    });
  }

  async getTaskSet() {
    return axios({
      url: BASE_URL + EndPoints.TASK_SETS,
      method: 'get',
    });
  }
  async searchSymptomKeyword(params) {
    return axios({
      url: BASE_URL + EndPoints.KEYWORDS_COLLECTION,
      method: 'get',
      params,
    });
  }

  async selectedSymptomKeyword(id) {
    return axios({
      url: BASE_URL + EndPoints.SYMPTOM_KEYWORDS(id),
      method: 'get',
    });
  }

  async createSymptomKeyword(params) {
    return axios({
      url: BASE_URL + EndPoints.SYMPTOM_KEYWORDS_CREATE(),
      method: 'post',
      params,
    });
  }

  async deleteSymptomKeyword(id) {
    return axios({
      url: BASE_URL + EndPoints.SYMPTOM_KEYWORDS_CREATE(id),
      method: 'delete',
    });
  }

  async getPatientResponses(id, params) {
    let url = BASE_URL + EndPoints.STARTQUESTIONARY;
    if (id) {
      url = `${url}/${id}`;
    }
    if (params) {
      url = `${url}?list_type=${params}`;
    }
    return axios({
      url,
      method: 'get',
    });
  }

  async getAPatientsResponses(params) {
    const url = BASE_URL + EndPoints.PATIENTS_RESPONSES;
    return axios.get(url, {params});
  }

  async getDashboard() {
    return axios({
      url: BASE_URL + EndPoints.DASHBOARD,
      method: 'get',
    });
  }

  async getQuestionary() {
    return axios({
      url: BASE_URL + EndPoints.SYMPTOMSQUESTION,
      method: 'get',
    });
  }

  async getProfileData(id) {
    return axios({
      url: BASE_URL + `${EndPoints.PROFILE}/${id}`,
      method: 'get',
    });
  }

  async getSymptoms(id) {
    let url = BASE_URL + EndPoints.SYMPTOMS;
    if (id) {
      url = `${url}?subspecialty_id=${id}`;
    }
    return axios({
      url: url,
      method: 'get',
    });
  }

  async getSingleSymptoms(id) {
    return axios({
      url: BASE_URL + EndPoints.SYMPTOMS + '/' + id,
      method: 'get',
    });
  }

  async editSingleSymptoms(id) {
    return axios({
      url: BASE_URL + EndPoints.SYMPTOMS + '/' + id + '/edit',
      method: 'get',
    });
  }

  async getSymptomsQuestionsOption(id) {
    return axios({
      url:
        BASE_URL +
        `${EndPoints.SYMPTOMSQUIESTIONOPTION}?symptom_questionnaire_id=${id}`,
      method: 'get',
    });
  }

  async getFollowUpOption(id) {
    return axios({
      url:
        BASE_URL +
        `${EndPoints.FollowUpOptions}?symptom_questionnaire_id=${id}`,
      method: 'get',
    });
  }

  async getFollowList(id) {
    return axios({
      url: BASE_URL + EndPoints.FollowUp,
      method: 'get',
    });
  }

  async getQuestionsList(id) {
    let baseUrl = BASE_URL + EndPoints.SYMPTOMSQUESTION;
    if (id) {
      baseUrl = `${baseUrl}?symptom_id=${id}`;
    }
    return axios({
      url: baseUrl,
      method: 'get',
    });
  }

  async getPatient(id) {
    return axios({
      url: PATIENT_BASE_URL + `${EndPoints.PATIENTUPDATE}/${id}`,
      method: 'get',
    });
  }
  async getSingleSymptomQuestionnaires(id) {
    return axios({
      url: BASE_URL + `${EndPoints.SYMPTOMSQUESTION}/${id}`,
      method: 'get',
    });
  }

  async getFollowUpQuestionDetails(id) {
    return axios({
      url: BASE_URL + `${EndPoints.FollowUp}/${id}`,
      method: 'get',
    });
  }

  async getDepartments() {
    return axios({
      url: BASE_URL + EndPoints.DEPARTMENT,
      method: 'get',
    });
  }

  async getSubspecialist() {
    return axios({
      url: BASE_URL + EndPoints.SUBSPECIALIST,
      method: 'get',
    });
  }

  async getSpacialList(id) {
    return axios({
      url: BASE_URL + `${EndPoints.SUBSPECIALIST}?department_id=${id}`,
      method: 'get',
    });
  }

  // update apis here

  async updateAbhaVerify(params) {
    return axios({
      url: PATIENT_BASE_URL + EndPoints.ABHAAADHARUPDATEVERIFY,
      method: 'put',
      data: params,
    });
  }

  async connectDoctor(id, data) {
    return axios({
      url:
        PATIENT_BASE_URL +
        EndPoints.STARTQUESTIONARY +
        '/' +
        id +
        '/' +
        EndPoints.CONNECTDOCTOR,
      method: 'put',
      data: data,
    });
  }

  async updateSymptomCategory(id, data) {
    return axios({
      url: BASE_URL + EndPoints.SYPMTOMCATEGORIES + '/' + id,
      method: 'put',
      data: data,
    });
  }

  async updateFact(data, id) {
    return axios({
      url: BASE_URL + EndPoints.FACTS + '/' + id,
      method: 'put',
      data: data,
    });
  }

  // upload file to server
  async uploadFileToServer(data, url, onUploadProgress) {
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'multipart/form-data');

    return axios({
      url: url,
      method: 'put',
      data: data,
      params: {
        ignoreAppendUrl: true,
      },
      headers: myHeaders,
      onUploadProgress: onUploadProgress,
    });
  }
  // upload file to server

  async updateSubspecialist(id, params) {
    return axios({
      url: BASE_URL + EndPoints.SUBSPECIALIST + '/' + id,
      method: 'put',
      data: params,
    });
  }

  async updateExamHeading(params, id) {
    return axios({
      url: BASE_URL + EndPoints.EXAMHEADINGS + '/' + id,
      method: 'put',
      data: params,
    });
  }

  async updateExamEndItem(params, id) {
    return axios({
      url: BASE_URL + EndPoints.EXAMENDITEMS + '/' + id,
      method: 'put',
      data: params,
    });
  }

  async updateExamHeadingOption(params, id) {
    return axios({
      url: BASE_URL + EndPoints.HEADINGOPTIONS + '/' + id,
      method: 'put',
      data: params,
    });
  }

  async activateDeactivateDepartment(id, endPoint) {
    return axios({
      url: BASE_URL + EndPoints.DEPARTMENT + '/' + id + '/' + endPoint,
      method: 'put',
    });
  }

  async activateDeactivateFact(id, endPoint) {
    return axios({
      url: BASE_URL + EndPoints.FACTS + '/' + id + '/' + endPoint,
      method: 'put',
    });
  }

  async activateDeactivateSymptom(id, endPoint) {
    return axios({
      url: BASE_URL + EndPoints.SYMPTOMS + '/' + id + '/' + endPoint,
      method: 'put',
    });
  }

  async activateDeactivateQuestion(id, endPoint) {
    return axios({
      url: BASE_URL + EndPoints.SYMPTOMSQUESTION + '/' + id + '/' + endPoint,
      method: 'put',
    });
  }

  async updateLanguage(params, id) {
    return axios({
      url: BASE_URL + `${EndPoints.LANGUAGES}/${id}`,
      method: 'put',
      data: params,
    });
  }

  async updateTaskSet(params, id) {
    return axios({
      url: BASE_URL + `${EndPoints.TASK_SETS}/${id}`,
      method: 'put',
      data: params,
    });
  }

  //reorder apis here (start)

  async reorderSymptoms(params, id) {
    return axios({
      url: BASE_URL + `${EndPoints.SYMPTOMS}/${id}/reorder`,
      method: 'put',
      data: params,
    });
  }

  async reOrderQuestions(params, id) {
    return axios({
      url: BASE_URL + `${EndPoints.SYMPTOMSQUESTION}/${id}/reorder`,
      method: 'put',
      data: params,
    });
  }

  async reOrderFollowUpQuestions(params, id) {
    return axios({
      url: BASE_URL + `${EndPoints.FollowUp}/${id}/reorder`,
      method: 'put',
      data: params,
    });
  }

  async reOrderBodySection(params, id) {
    return axios({
      url: BASE_URL + `${EndPoints.BODYSECTIONS}/${id}/reorder`,
      method: 'put',
      data: params,
    });
  }

  // update reorder apis here (close)

  async updateProfile(params, id) {
    return axios({
      url: BASE_URL + `${EndPoints.PROFILE}/${id}`,
      method: 'put',
      data: params,
    });
  }

  async updatePatient(params, id) {
    return axios({
      url: PATIENT_BASE_URL + `${EndPoints.PATIENTUPDATE}/${id}`,
      method: 'put',
      data: params,
    });
  }

  async updateLanguages(params, id) {
    return axios({
      url: BASE_URL + `${EndPoints.LANGUAGES}/${id}`,
      method: 'put',
      params,
    });
  }

  async symptomsQuestionsOptionUpdate(params, id) {
    return axios({
      url: BASE_URL + `${EndPoints.SYMPTOMSQUIESTIONOPTION}/${id}`,
      method: 'put',
      data: params,
    });
  }

  async followUpOptionUpdate(params, id) {
    return axios({
      url: BASE_URL + `${EndPoints.FollowUpOptions}/${id}`,
      method: 'put',
      data: params,
    });
  }

  async symptomQuestionnairesUpdate(params, id) {
    return axios({
      url: BASE_URL + EndPoints.SYMPTOMSQUESTION + '/' + id,
      method: 'put',
      data: params,
    });
  }

  async updateDepartment(id, params) {
    return axios({
      url: BASE_URL + EndPoints.DEPARTMENT + '/' + id,
      method: 'put',
      data: params,
    });
  }

  async updateQuestionnary(params, id) {
    return axios({
      url: PATIENT_BASE_URL + EndPoints.STARTQUESTIONARY + '/' + id,
      method: 'put',
      data: params,
    });
  }

  searchSymptoms(id, params) {
    const baseUrl = Utility.isNurse ? BASE_URL : PATIENT_BASE_URL;
    const url = baseUrl + EndPoints.SYMPTOM_SEARCH(id);
    return axios.get(url, {params});
  }

  async updateFollowUpQuestionnary(params, id) {
    return axios({
      url: BASE_URL + EndPoints.FollowUp + '/' + id,
      method: 'put',
      data: params,
    });
  }

  async updateSymptoms(params, id) {
    return axios({
      url: BASE_URL + EndPoints.SYMPTOMS + '/' + id,
      method: 'put',
      data: params,
    });
  }

  async goBack(id, params) {
    return axios({
      url: PATIENT_BASE_URL + EndPoints.PREVIOUS + '/' + id + '/previous',
      method: 'put',
      params,
    });
  }

  // delete apis here

  async deleteDocuments(data) {
    return axios({
      url: PATIENT_BASE_URL + EndPoints.DOCUMENTS + '/delete',
      method: 'delete',
      data: data,
    });
  }

  async deleteFacts(id) {
    return axios({
      url: BASE_URL + EndPoints.FACTS + '/' + id,
      method: 'delete',
    });
  }

  async deleteDepartmentBody(id) {
    return axios({
      url: BASE_URL + EndPoints.BODYSECTIONS + '/' + id,
      method: 'delete',
    });
  }

  async deleteBodyPartName(id) {
    return axios({
      url: BASE_URL + EndPoints.GETBODYPARTSECTION + '/' + id,
      method: 'delete',
    });
  }

  async deleteCategory(id) {
    return axios({
      url: BASE_URL + EndPoints.SYPMTOMCATEGORIES + '/' + id,
      method: 'delete',
    });
  }

  async logOutPatient() {
    return axios({
      url: PATIENT_BASE_URL + EndPoints.LOGOUTPATIENT,
      method: 'delete',
    });
  }

  async logOutDoctor() {
    return axios({
      url: BASE_URL + EndPoints.LOGOUTDOCTOR,
      method: 'delete',
    });
  }

  async deleteExamHeadingOption(id) {
    return axios({
      url: BASE_URL + EndPoints.HEADINGOPTIONS + '/' + id,
      method: 'delete',
    });
  }

  async deleteExamHeading(id) {
    return axios({
      url: BASE_URL + EndPoints.EXAMHEADINGS + '/' + id,
      method: 'delete',
    });
  }

  async deleteExamEndItem(id) {
    return axios({
      url: BASE_URL + EndPoints.EXAMENDITEMS + '/' + id,
      method: 'delete',
    });
  }

  async deleteSubspecialist(id) {
    return axios({
      url: BASE_URL + EndPoints.SUBSPECIALIST + '/' + id,
      method: 'delete',
    });
  }

  async deleteSymptoms(id) {
    return axios({
      url: BASE_URL + EndPoints.SYMPTOMS + '/' + id,
      method: 'delete',
    });
  }

  async deleteDepartment(id) {
    return axios({
      url: BASE_URL + EndPoints.DEPARTMENT + '/' + id,
      method: 'delete',
    });
  }

  async deleteLanguages(id) {
    return axios({
      url: BASE_URL + `${EndPoints.LANGUAGES}/${id}`,
      method: 'delete',
    });
  }

  async deleteActivity(id) {
    return axios({
      url: BASE_URL + `${EndPoints.ACTIVITIES}/${id}`,
      method: 'delete',
    });
  }

  async deleteTaskSet(id) {
    return axios({
      url: BASE_URL + `${EndPoints.TASK_SETS}/${id}`,
      method: 'delete',
    });
  }

  async deleteSymptomsQuestionsOption(id) {
    return axios({
      url: BASE_URL + `${EndPoints.SYMPTOMSQUIESTIONOPTION}/${id}`,
      method: 'delete',
    });
  }

  async deleteFollowUpOption(id) {
    return axios({
      url: BASE_URL + `${EndPoints.FollowUpOptions}/${id}`,
      method: 'delete',
    });
  }

  async deleteSubspecialistRange(subspecialyId, id) {
    return axios({
      url:
        BASE_URL + EndPoints.SUBSPECIALTY_SCORE_RANGE(subspecialyId) + '/' + id,
      method: 'delete',
    });
  }

  async deleteTasks(subspecialyId, id) {
    return axios({
      url: BASE_URL + EndPoints.TASKS(subspecialyId) + '/' + id,
      method: 'delete',
    });
  }

  // receptionist
  getDoctosList(params) {
    return axios.get(BASE_URL + EndPoints.DOCTORS_LIST, {params});
  }

  registerPatient(params) {
    return axios.post(BASE_URL + EndPoints.REGISTERPATIENT, params);
  }

  updateRegisteredPatient(params) {
    return axios.put(BASE_URL + EndPoints.PATIENT_UPDATE, params);
  }

  registeredPatientsList(params) {
    const url = BASE_URL + EndPoints.REGISTERED_PATIENT;
    return axios.get(url, {params});
  }

  async deleteSymptomsQuestions(id) {
    return axios({
      url: BASE_URL + `${EndPoints.SYMPTOMSQUESTION}/${id}`,
      method: 'delete',
    });
  }

  async deleteFollowUpQuestions(id) {
    return axios({
      url: BASE_URL + `${EndPoints.FollowUp}/${id}`,
      method: 'delete',
    });
  }

  async getDepartmentsList() {
    return axios({
      url: BASE_URL + `${EndPoints.DEPARTMENTS_LIST}`,
      method: 'get',
    });
  }

  async getSubspecialistesList() {
    return axios({
      url: BASE_URL + `${EndPoints.SUBSPECIALISTIES_LIST}`,
      method: 'get',
    });
  }
}

const ApiClient = new ApiManager();
export default ApiClient;
