import React from 'react';

const useLocalState = (key, value) => {
  const storedValue = localStorage.getItem(key);
  const [state, setState] = React.useState(
    typeof storedValue === 'string' ? JSON.parse(storedValue) : value,
  );

  React.useEffect(() => {
    if (state) {
      localStorage.setItem(key, JSON.stringify(state));
    }
  }, [state, key]);

  return [state, setState];
};

export default useLocalState;
