import React, {useState, useEffect} from 'react';
import {Box, Button} from '@mui/material';
import {Checkbox} from 'antd';
import {useNavigate} from 'react-router';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {
  CommonButton,
  CommonTable,
  CommonTextInput,
  Header,
  Loader,
  LocalAlert,
} from '../../commonComponents';
import ApiClient from '../../networking/ApiManager';
import {getLanguage} from '../../redux/Redcuers/LanguageSlice';

function Languages() {
  const [language, setLanguage] = useState('');
  const [code, setCode] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertType, setAlertType] = useState('error');
  const [deleteDataId, setDeleteDataId] = useState(null);
  const [updateDataId, setUpdateDataId] = useState(null);
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const languageData = useSelector(state => state.LanguageSlice.data);

  useEffect(() => {
    dispatch(getLanguage());
  }, [dispatch]);

  const showForm = (action, text) => {
    return (
      <Box>
        <Header />
        <Box sx={{margin: 2}}>
          <h2 style={{margin: '15px 0'}}>{text}</h2>

          <CommonTextInput
            maxLength={100}
            removeWhiteSpace
            label="Language"
            validateStringOnly
            value={language}
            onChange={setLanguage}
            style={{m: 1, width: '100%'}}
          />

          <CommonTextInput
            maxLength={100}
            removeWhiteSpace
            validateStringOnly
            label="Code"
            value={code}
            onChange={setCode}
            style={{m: 1, width: '100%', margin: '15px 0'}}
          />

          <div style={{display: 'flex', flexDirection: 'row'}}>
            <div style={{margin: 3, fontWeight: 'bold'}}></div>
            <Checkbox
              checked={checked}
              onChange={() => setChecked(!checked)}
              inputProps={{'aria-label': 'controlled'}}
            />
            <div
              style={{
                margin: 3,
                fontWeight: '400',
                fontSize: '12px',
                alignSelf: 'center',
              }}>
              Default Checked
            </div>
          </div>

          <CommonButton
            style={{width: '100%', backgroundColor: '#405672', marginTop: 5}}
            label="Create Language"
            onClick={createLanguage}
          />
        </Box>
      </Box>
    );
  };

  const createLanguage = () => {
    const params = {
      locale: language,
      default: checked,
      code: code,
    };

    if (language.length > 0) {
      setLoading(true);
      ApiClient.createLanguage(params)
        .then(res => {
          setShowAlert(true);
          setAlertMessage('Language Created Successfully');
          setAlertType('success');
          setLanguage('');
          setCode('');
          setChecked(checked);
          dispatch(getLanguage(''));
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          setShowAlert(true);
          setAlertMessage('Please Enter Code');
          setAlertType('error');
        });
    } else {
      setShowAlert(true);
      setAlertMessage('Please Enter Language');
      setAlertType('error');
    }
  };

  const updateLanguageData = id => {
    const languageItem = languageData.find(item => item.id === id);
    if (languageItem) {
      const {locale, code} = languageItem;

      setUpdateDataId(id);
      setLanguage(locale);
      setCode(code);
      setChecked(languageData.filter(item => item.id === id)[0].default);
      navigate(`/languages/${id}/edit`, {
        state: {
          code,
          locale,
          translation: languageData.filter(item => item.id === id)[0]
            .json_attributes,
          checked: languageData.filter(item => item.id === id)[0].default,
        },
      });
    }
  };

  const tableHeaderData = [
    'Id',
    'Language',
    'Code',
    'Created At',
    'Activate/Deactivate',
  ];
  const dataKeys = ['id', 'locale', 'code', 'created_at', 'deactivated_at'];

  const showLanguagesData = () => {
    return (
      <CommonTable
        showLabel="default"
        labelFor="locale"
        labelStyle={{
          fontSize: '10px',
          marginLeft: '5px',
          display: 'inline-block',
          padding: '3px',
          borderRadius: '5px',
          backgroundColor: '#405672',
          color: '#fff',
          alignSelf: 'center',
          verticleAlign: 'middle',
        }}
        apiType="language"
        hideToggle
        languageToggle
        data={languageData}
        tableHeaderData={tableHeaderData}
        dataKeys={dataKeys}
        showUpdate
        showDelete
        onPressDelete={deleteLanguages}
        onPressUpdate={updateLanguageData}
        updateDataId={setUpdateDataId}
        deleteDataId={setDeleteDataId}
        tableStyle={{width: '100%'}}
        emptyTitle="No Languages Found"
      />
    );
  };

  const deleteLanguages = id => {
    const confirnDelete = window.confirm(
      'Are you sure you want to delete this language?',
    );
    if (confirnDelete) {
      setLoading(true);
      ApiClient.deleteLanguages(id)
        .then(res => {
          setShowAlert(true);
          dispatch(getLanguage(''));
          setAlertMessage('Language Deleted Successfully');
          setAlertType('success');
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          setShowAlert(true);
          setAlertMessage('Error Deleting Language');
          setAlertType('error');
        });
    }
  };

  return (
    <>
      {showForm('', 'Languages :-')}
      {showAlert && (
        <LocalAlert
          alertText={alertMessage}
          onClose={() => setShowAlert(false)}
          severity={alertType}
        />
      )}
      {languageData && showLanguagesData()}
      <Loader loading={loading} />
    </>
  );
}

export default Languages;
