import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import ApiClient from '../../../networking/ApiManager';

const initialState = {
  data: {},
  isLoading: true,
  isRefreshing: false,
  status: 'idle',
  error: null,
  firstLoad: true,
};

export const getPatientsResponses = createAsyncThunk(
  'PatientsResponsesSlice/getPatientsResponses',
  async (id, thunkAPI) => {
    const state = thunkAPI.getState();
    const data = state.PatientsResponsesSlice.data[id] || [];
    if (data.length > 0) {
      return {id, data: state.PatientsResponsesSlice.data[id]};
    }
    try {
      const response = await ApiClient.getAPatientsResponses({patient_id: id});
      return {id, data: response.data.data};
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

const sliceNameSlice = createSlice({
  name: 'PatientsResponsesSlice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getPatientsResponses.pending, state => {
        state.isLoading = true;
      })
      .addCase(getPatientsResponses.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isRefreshing = false;
        state.firstLoad = false;
        state.data[action.payload.id] = action.payload.data;
      })
      .addCase(getPatientsResponses.rejected, (state, action) => {
        state.isLoading = false;
        state.isRefreshing = false;
        state.error = action.error.message;
      });
  },
});

export default sliceNameSlice.reducer;

export const PatientsResponsesActions = sliceNameSlice.actions;
