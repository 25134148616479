import React, {useMemo, useState, useEffect, useCallback, useRef} from 'react';
import {
  Select,
  InputAdornment,
  TextField,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  InputLabel,
  FormControl,
  createTheme,
  ThemeProvider,
} from '@mui/material';
import Colors from '../Resources/COLORS/Colors';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';

function CommonDropDown(props) {
  const {setData, showData, setData2, showData2, value, dropDownStyle} = props;
  const [searchText, setSearchText] = useState('');
  const selectRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (selectRef.current) {
      selectRef.current.onopen = () => {
        setSearchText('');
      };
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef, searchText]);

  const handleClickOutside = event => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setSearchText('');
    }
  };

  const getValues = useCallback(
    item => {
      let showval, setVal;

      if (setData2 && showData2) {
        setVal = item[setData] + ' ' + item[setData2];
        showval = item[showData] + ' ' + item[showData2];
      } else {
        setVal = item[setData];
        showval = item[showData];
      }

      return [showval, setVal];
    },
    [setData, setData2, showData, showData2],
  );

  const localData = useMemo(() => {
    return props?.data?.filter(item => {
      const [showval] = getValues(item);
      return showval?.toLowerCase().includes(searchText.toLowerCase());
    });
  }, [props.data, searchText, getValues]);

  const returnMapFunction = () => {
    return localData.map(item => {
      const [showval, setVal] = getValues(item);

      return (
        <MenuItem key={item[setData]} value={setVal} style={{width: '100%'}}>
          {showval}
        </MenuItem>
      );
    });
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        ...dropDownStyle,
      },
    },
  };

  const handleOnChange = event => {
    const {
      target: {value},
    } = event;
    props.onChange &&
      props.onChange(typeof value === 'string' ? value.split(',') : value);
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: Colors.APPCIRCLE,
        light: Colors.APPCIRCLE,
        dark: Colors.APPCIRCLE,
      },
    },
  });

  const errrorText = props.error ? '#FF0000' : Colors.BLACK;

  const flexStyle = props.showClose && value ? {display: 'flex'} : {};

  const clearData = () => {
    if (props.showClose && value) {
      return (
        <CloseIcon
          style={{
            display: 'block',
            margin: 'auto',
            marginLeft: '-60px',
            cursor: 'pointer',
            zIndex: '1',
            padding: '5px',
          }}
          onClick={() => {
            props.onChange && props.onChange('');
          }}
        />
      );
    }
  };

  if (props.customize) {
    return (
      <ThemeProvider theme={theme}>
        <FormControl style={props.formControlStyle}>
          <div style={flexStyle}>
            <InputLabel id={props.labelId}>{props.label}</InputLabel>
            <Select
              ref={selectRef}
              error={props.error}
              helperText={props.helperText ?? ''}
              sx={{borderRadius: '15px', color: errrorText, ...props.sx}}
              labelId={props.labelId}
              value={value}
              onChange={handleOnChange}
              input={<OutlinedInput label={props.label} />}
              MenuProps={MenuProps}
              style={props.selectStyle}>
              <ListSubheader
                style={{
                  display: 'flex',
                  height: 55,
                  alignItems: 'center',
                }}>
                <TextField
                  size="small"
                  autoFocus
                  placeholder="Type to search..."
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  value={searchText}
                  onChange={e => setSearchText(e.target.value)}
                  onKeyDown={e => {
                    if (e.key !== 'Escape') {
                      e.stopPropagation();
                    }
                  }}
                />
              </ListSubheader>
              {props.data && returnMapFunction()}
            </Select>

            {clearData()}
          </div>
          {props.error && props.helperText && (
            <div
              style={{
                color: 'red',
                display: 'block',
                textAlign: 'left',
                alignSelf: 'flex-start',
              }}>
              {props.helperText}
            </div>
          )}
        </FormControl>
      </ThemeProvider>
    );
  } else {
    return (
      <FormControl style={props.formControlStyle}>
        <div style={flexStyle}>
          <InputLabel style={props.labelStyle} id={props.labelId}>
            {props.label}
          </InputLabel>
          <Select
            ref={selectRef}
            error={props.error}
            helperText={props.helperText ?? ''}
            sx={{color: errrorText, ...props.sx}}
            labelId={props.labelId}
            value={value}
            onChange={handleOnChange}
            input={<OutlinedInput label={props.label} />}
            MenuProps={MenuProps}
            style={props.selectStyle}>
            <ListSubheader
              style={{
                display: 'flex',
                height: 55,
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <TextField
                inputRef={inputRef}
                fullWidth
                placeholder="Type to search..."
                value={searchText}
                style={{
                  height: '40px',
                }}
                onChange={e => setSearchText(e.target.value)}
                onKeyDown={e => {
                  if (e.key !== 'Escape') {
                    e.stopPropagation();
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon style={{color: '#888'}} />
                    </InputAdornment>
                  ),
                  style: {
                    height: '100%',
                  },
                }}
              />
            </ListSubheader>
            {props.data && returnMapFunction()}
          </Select>

          {clearData()}
        </div>
        {props.error && props.helperText && (
          <div
            style={{
              color: '#d32f2f',
              display: 'block',
              textAlign: 'left',
              alignSelf: 'flex-start',
              fontSize: '12px',
              margin: '0 15px',
            }}>
            {props.helperText}
          </div>
        )}
      </FormControl>
    );
  }
}

export default CommonDropDown;
