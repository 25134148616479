import React, {useState} from 'react';
import {
  CommonDropDown,
  CommonTextInput,
  Header,
  Loader,
  LocalAlert,
} from '../../commonComponents';
import {Button} from '@mui/material';
import ApiClient from '../../networking/ApiManager';
import {useNavigate, useParams, useLocation} from 'react-router';
import LanguageDropdown from '../../commonComponents/LanguageDropdown';
import Utility from '../../commonComponents/Utility';

const CreateUpdateTask = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const editParamId = location.state && location.state.edit;
  const editId = location.state && location.state.id;

  const editsubSpecialtyCategory =
    location.state && location.state.value.subspecialty_category;
  const editParamSeverity = location.state && location.state.value.severity;
  const editParamAdvise = location.state && location.state.value.ladvise;
  const editParamNotification =
    location.state && location.state.value.lnotification;
  const editImage = location.state && location.state.value.image_url;
  const editTitle = location.state && location.state.value.ltitle;
  const editSubTitle = location.state && location.state.value.lsubtitle;
  const editActionUrl = location.state && location.state.value.action_url;
  const editInitialDay =
    location.state && location.state.value.initial_gap_days;
  const editActionUrlType =
    location.state && location.state.value.action_url_type;
  const editRecurringRule =
    location.state && location.state.value.recurring_rule;
  const editActivityId = location.state && location.state.value.activity_id;

  const [loading, setLoading] = useState(false);
  const [alertType, setAlertType] = useState('error');
  const [image, setImage] = useState('');
  const [activityData, setActivityData] = useState('');
  const [serverImage, setServerImage] = useState(editImage || '');
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [subspecialtyCategory, setSubspecialtyCategory] = useState(
    editsubSpecialtyCategory ?? '',
  );
  const [advise, setAdvise] = useState(editParamAdvise || {});
  const [notification, setNotification] = useState(editParamNotification || {});
  const [title, setTitle] = useState(editTitle || '');
  const [subTitle, setSubTitle] = useState(editSubTitle || '');
  const [actionUrlType, setActionUrlType] = useState(editActionUrlType || '');
  const [severity, setSeverity] = useState(editParamSeverity || '');
  const [actionUrl, setActionUrl] = useState(editActionUrl || '');
  const [recurringRule, setRecurringRule] = useState(editRecurringRule || '');
  const [activityId, setActivityId] = useState(editActivityId || '');
  const [initialGapDay, setInitialGapDay] = useState(editInitialDay || '');
  const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

  const [errors, setErrors] = useState({
    subspecialtyCategory: '',
    severity: '',
    actionUrl: '',
    image: '',
  });

  const refreshData = React.useCallback(() => {
    setLoading(true);
    ApiClient.getActivities()
      .then(res => {
        console.warn(res.data.data);
        setActivityData(res.data.data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err.response.data);
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    refreshData();
  }, [refreshData]);

  const createTasks = () => {
    if (
      subspecialtyCategory === '' ||
      severity === '' ||
      (actionUrl.length > 0 && !Utility.urlValidation(actionUrl)) ||
      image.length === 0
    ) {
      const newErrors = {
        subspecialtyCategory:
          subspecialtyCategory === ''
            ? 'Please enter a subspecialty category value'
            : '',
        severity: severity === '' ? 'Please enter severity' : '',
        actionUrl:
          actionUrl.length > 0 && !urlRegex.test(actionUrl)
            ? 'Please enter valid action url'
            : '',
        image: image.length === 0 ? 'Please select an image to upload' : '',
      };
      setErrors(newErrors);
    } else {
      const formData = new FormData();
      for (const key of Object.keys(advise)) {
        formData.append('ladvise[' + key + ']', advise[key]);
      }

      for (const key of Object.keys(notification)) {
        formData.append('lnotification[' + key + ']', notification[key]);
      }
      for (const key of Object.keys(title)) {
        formData.append('ltitle[' + key + ']', title[key]);
      }
      for (const key of Object.keys(subTitle)) {
        formData.append('lsubtitle[' + key + ']', subTitle[key]);
      }

      formData.append('subspecialty_category', subspecialtyCategory);
      formData.append('activity_id', activityId);
      formData.append('severity', severity);
      formData.append('action_url', actionUrl);
      image && formData.append('image', image);
      formData.append('recurring_rule', recurringRule);
      formData.append('action_url_type', actionUrlType);
      formData.append('initial_gap_days', initialGapDay);

      setLoading(true);
      ApiClient.createTask(id, formData)
        .then(res => {
          setShowAlert(true);
          setAlertMessage('Successfully Create Task');
          setAlertType('success');
          setTimeout(() => {
            navigate(-1);
          }, 500);
          setLoading(false);
        })
        .catch(err => {
          console.log(err.response.data);
        });
    }
  };

  const updateTasks = () => {
    if (
      (subspecialtyCategory !== 0 && !subspecialtyCategory) ||
      severity === '' ||
      (!!actionUrl && !Utility.urlValidation(actionUrl)) ||
      (!editImage && image.length === 0)
    ) {
      const newErrors = {
        subspecialtyCategory:
          subspecialtyCategory !== 0 && !subspecialtyCategory
            ? 'Please enter subspecialty category value'
            : '',
        severity: severity === '' ? 'Please enter severity' : '',
        actionUrl:
          actionUrl.length > 0 && !urlRegex.test(actionUrl)
            ? 'Please enter valid action url'
            : '',
        image:
          !editImage && image.length === 0
            ? 'Please select an image to upload'
            : '',
      };
      setErrors(newErrors);
    } else {
      const formData = new FormData();
      for (const key of Object.keys(advise)) {
        formData.append('ladvise[' + key + ']', advise[key]);
      }

      for (const key of Object.keys(notification)) {
        formData.append('lnotification[' + key + ']', notification[key]);
      }

      for (const key of Object.keys(title)) {
        formData.append('ltitle[' + key + ']', title[key]);
      }
      for (const key of Object.keys(subTitle)) {
        formData.append('lsubtitle[' + key + ']', subTitle[key]);
      }
      formData.append('subspecialty_category', subspecialtyCategory);
      formData.append('activity_id', activityId);
      formData.append('severity', severity);
      formData.append('action_url', actionUrl);
      formData.append('image', image);
      formData.append('recurring_rule', recurringRule);
      formData.append('action_url_type', actionUrlType);
      formData.append('initial_gap_days', initialGapDay);

      setLoading(true);

      ApiClient.updateTasks(editId, id, formData)
        .then(res => {
          setShowAlert(true);
          setAlertMessage('Successfully Update Task');
          setAlertType('success');
          setTimeout(() => {
            navigate(-1);
          }, 500);
          setLoading(false);
        })
        .catch(err => {
          console.log(err.response.data);
        });
    }
  };

  const severityList = [
    {name: 'Minimal', id: 'minimal'},
    {name: 'Mild', id: 'mild'},
    {name: 'Moderate', id: 'moderate'},
    {name: 'Severe', id: 'severe'},
    {name: 'Fit', id: 'fit'},
    {name: 'Temporarily Fit', id: 'temporarily_fit'},
    {name: 'Temporarily Unfit', id: 'temporarily_unfit'},
  ];

  const ActionUrlList = [
    {name: 'Youtube', id: 'youtube'},
    {name: 'Image', id: 'image'},
    {name: 'Video', id: 'video'},
    {name: 'Internal Website Url', id: 'internal_website_url'},
    {name: 'External Website Url', id: 'external_website_url'},
    {name: 'Post To Internal Website Url', id: 'post_internal_website_url'},
    {name: 'Start Assessment', id: 'start_assessment'},
  ];

  return (
    <div>
      <Header />
      <div style={{paddingLeft: 20}}>
        <p style={{fontSize: 25}}>
          {`${editParamId ? 'Update' : 'Create'} Task`} :-{' '}
        </p>
      </div>
      <div style={{padding: 20}}>
        <CommonTextInput
          error={!!errors.subspecialtyCategory}
          maxLength={250}
          showLength={125}
          helperText={errors.subspecialtyCategory}
          label="Subspecialty Category"
          style={{m: 1, width: '100%', marginBottom: 20}}
          onChange={value => {
            setSubspecialtyCategory(value);
            setErrors({...errors, subspecialtyCategory: ''});
          }}
          value={subspecialtyCategory}
        />

        <div
          style={{
            gap: 15,
            display: 'flex',
            flexDirection: 'column',
          }}>
          <LanguageDropdown
            inputValue={title}
            onChange={setTitle}
            label="Title"
          />

          <LanguageDropdown
            inputValue={subTitle}
            onChange={setSubTitle}
            label="Subtitle"
          />

          <LanguageDropdown
            inputValue={advise}
            onChange={setAdvise}
            label="Advise"
          />
          <LanguageDropdown
            inputValue={notification}
            onChange={setNotification}
            label="Notification"
          />
        </div>

        <CommonDropDown
          formControlStyle={{
            width: '100%',
            marginBottom: 8,
            marginTop: 20,
          }}
          label="Severity"
          error={!!errors.severity}
          helperText={errors.severity}
          labelId="Severity"
          onChange={value => {
            setSeverity(value);
            setErrors({...errors, severity: ''});
          }}
          labelStyle={{color: !!errors.severity ? '#d32f2f' : 'grey'}}
          value={severity}
          selectStyle={{width: '100%'}}
          data={severityList}
          setData={'id'}
          showData={'name'}
        />

        <CommonDropDown
          formControlStyle={{
            width: '100%',
            marginTop: 15,
          }}
          label="Activity"
          labelId="Activty"
          onChange={value => {
            setActivityId(value);
          }}
          value={activityId}
          selectStyle={{width: '100%'}}
          data={activityData || []}
          setData={'id'}
          showData={'name'}
        />
      </div>

      <div style={{paddingLeft: 20, paddingRight: 20}}>
        <CommonTextInput
          label="Frequency"
          style={{m: 1, width: '100%', marginBottom: 20}}
          onChange={value => {
            setRecurringRule(value);
          }}
          value={recurringRule}
        />

        <CommonTextInput
          error={!!errors.actionUrl}
          helperText={errors.actionUrl}
          maxLength={250}
          showLength={125}
          label="Action Url"
          style={{m: 1, width: '100%', marginBottom: 20}}
          onChange={value => {
            setActionUrl(value);
            setErrors({...errors, actionUrl: ''});
          }}
          value={actionUrl}
        />

        <CommonDropDown
          formControlStyle={{
            width: '100%',
            marginBottom: 8,
          }}
          label="Action Url Type"
          onChange={value => {
            setActionUrlType(value);
          }}
          value={actionUrlType}
          selectStyle={{width: '100%'}}
          data={ActionUrlList}
          setData={'id'}
          showData={'name'}
        />

        <CommonTextInput
          maxLength={250}
          showLength={125}
          validateNumbers
          label="Initial Gap (Days)"
          style={{m: 1, width: '100%', marginBottom: 20, marginTop: 15}}
          onChange={value => {
            setInitialGapDay(value);
          }}
          value={initialGapDay}
        />
      </div>

      <div style={{marginTop: '20px', paddingLeft: 26}}>
        {(image || serverImage) && (
          <div>
            {image && (
              <img
                alt={'name'}
                style={{maxWidth: '200px', maxHeight: '200px'}}
                src={
                  typeof image === 'object' ? URL.createObjectURL(image) : image
                }
              />
            )}
            {!image && serverImage && (
              <img
                alt={'name'}
                style={{maxWidth: '200px', maxHeight: '200px'}}
                src={serverImage}
              />
            )}
          </div>
        )}
      </div>

      <div style={{display: 'flex', paddingLeft: 25}}>
        <div>
          <label htmlFor="file-input">
            <Button
              variant="contained"
              sx={{width: '100%', backgroundColor: '#405672', marginTop: 2}}
              component="span">
              Attach Image
            </Button>
          </label>
          <p style={{color: 'red'}}>{errors.image}</p>
        </div>

        <CommonTextInput
          id="file-input"
          type="file"
          style={{visibility: 'hidden'}}
          onChange={value => {
            setImage(value);
            setErrors({...errors, image: ''});
          }}
        />
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
          marginTop: 20,
          marginBottom: 20,
        }}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#405672',
            marginTop: 2,
            width: '97.5%',
          }}
          component="span"
          onClick={() => {
            editParamId ? updateTasks() : createTasks();
          }}>
          Save Task
        </Button>
      </div>
      {showAlert && (
        <LocalAlert
          severity={alertType}
          alertText={alertMessage}
          onClose={() => setShowAlert(false)}
        />
      )}
      <Loader loading={loading} />
    </div>
  );
};

export default CreateUpdateTask;
