import {Button, Checkbox, FormControlLabel} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {CommonBorderBox, CommonTextInput, Loader} from '../../commonComponents';
import ApiClient from '../../networking/ApiManager';

function SyExamHeadingForm(props) {
  const {
    isLoading,
    setIsLoading,
    refresh,
    leafNode,
    isEndItem,
    departmentId,
    subspecialityId,
    parentId,
    editId,
    headingText,
    setShowLocalAlert,
    setLocalAlertMessage,
  } = props;

  let text = isEndItem ? 'End Item' : 'Heading';
  const actionText = editId ? 'Update ' : 'Add ';

  const [heading, setHeading] = useState(headingText || '');
  const [headingError, setHeadingError] = useState('');
  const [btnText, setBtnText] = useState(actionText + text);

  useEffect(() => {
    setHeadingError(false);
  }, [heading]);

  const updateHeading = () => {
    setIsLoading(true);
    const params = {
      descriptor_code: heading,
    };

    ApiClient.updateExamHeading(params, editId)
      .then(res => {
        refresh();
      })
      .catch(err => {
        console.log(err);
        setLocalAlertMessage('Failed to update exam heading!');
        setShowLocalAlert(true);
        setIsLoading(false);
      });
  };

  const updateEndItem = () => {
    setIsLoading(true);
    const params = {
      descriptor_code: heading,
    };
    ApiClient.updateExamEndItem(params, editId)
      .then(res => {
        refresh();
      })
      .catch(err => {
        console.log(err);
        setLocalAlertMessage('Failed to update exam end item!');
        setShowLocalAlert(true);
        setIsLoading(false);
      });
  };

  const updateData = () => {
    if (isEndItem) {
      updateEndItem();
    } else {
      updateHeading();
    }
  };

  const createData = () => {
    if (isEndItem) {
      createEndItem();
    } else {
      createHeading();
    }
  };

  const createHeading = () => {
    setIsLoading(true);
    const params = {
      department_id: departmentId,
      subspecialty_id: subspecialityId,
      descriptor_code: heading,
      parent_id: parentId,
    };

    ApiClient.createHeading(params)
      .then(res => {
        clearData();
      })
      .catch(err => {
        console.log(err);
        setLocalAlertMessage('Failed to create exam heading!');
        setShowLocalAlert(true);
        setIsLoading(false);
      });
  };

  const createEndItem = () => {
    setIsLoading(true);
    const params = {
      department_id: departmentId,
      subspecialty_id: subspecialityId,
      descriptor_code: heading,
      parent_id: parentId,
    };

    ApiClient.createExamEndItem(params)
      .then(res => {
        console.warn('end', res);
        clearData();
      })
      .catch(err => {
        console.log(err);
        setLocalAlertMessage('Failed to create exam end item!');
        setShowLocalAlert(true);
        setIsLoading(false);
      });
  };

  const clearData = () => {
    refresh();
    setHeading('');
  };

  const deleteData = () => {
    const confirmDelete = window.confirm(
      'Are you sure you want to delete this ' + text + '?',
    );
    if (confirmDelete) {
      if (isEndItem) {
        deleteExamEndItem();
      } else {
        deleteExamHeading();
      }
    }
  };

  const deleteExamEndItem = () => {
    setIsLoading(true);
    ApiClient.deleteExamEndItem(editId)
      .then(res => {
        clearData();
      })
      .catch(err => {
        console.log(err);
        setLocalAlertMessage('Failed to delete exam end item!');
        setShowLocalAlert(true);
        setIsLoading(false);
      });
  };

  const deleteExamHeading = () => {
    setIsLoading(true);
    ApiClient.deleteExamHeading(editId)
      .then(res => {
        clearData();
      })
      .catch(err => {
        console.log(err);
        setLocalAlertMessage('Failed to delete exam heading!');
        setShowLocalAlert(true);
        setIsLoading(false);
      });
  };

  const btnAction = () => {
    if (heading.trim().length === 0) {
      setHeadingError(text + ' is required !');
      return;
    }

    editId ? updateData() : createData();
  };

  return (
    <>
      <CommonTextInput
        label={text}
        error={!!headingError}
        customize
        helperText={headingError}
        style={{width: '100%', margin: '15px 0px 0px 0px'}}
        placeholder={text}
        value={heading}
        onChange={setHeading}
        helperTextStyle={{margin: '0px'}}
      />

      <CommonBorderBox
        onClick={btnAction}
        style={{
          textAlign: 'center',
          padding: '10px',
          margin: '15px 0px 0px 0px',
        }}>
        {btnText}
      </CommonBorderBox>

      {editId && leafNode && (
        <CommonBorderBox
          onClick={deleteData}
          style={{textAlign: 'center', margin: '10px 0', padding: '10px'}}>
          Delete {text}
        </CommonBorderBox>
      )}
      <Loader loading={isLoading} />
    </>
  );
}

export default SyExamHeadingForm;
