import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {Box, Modal} from '@mui/material';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import {Clear} from '@mui/icons-material';
import ApiClient from '../../networking/ApiManager';
import Colors from '../../Resources/COLORS/Colors';
import {
  CommonBorderBox,
  Header,
  Loader,
  LocalAlert,
} from '../../commonComponents';
import SyExamOptionForm from './SyExamOptionForm';
import SyExamHeadingForm from './SyExamHeadingForm';
function SyExam() {
  const {id} = useParams();
  const [data, setData] = useState('');
  const [departmentId, setDepartmentId] = useState('');
  const [subspecialityId, setSubspecialityId] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [parentId, setParentId] = useState(null);
  const [isRefresh, setIsrefresh] = useState(false);
  const [addOption, setAddOption] = useState(false);
  const [addHeading, setAddHeading] = useState(false);
  const [addEndItem, setAddEndItem] = useState(false);
  const [addSubItem, setAddSubItem] = useState(false);
  const [editId, setEditId] = useState(null);
  const [editHeadingText, setEditHeadingText] = useState('');
  const [editOptionSignText, setEditOptionSignText] = useState('');
  const [leafNode, setLeafNode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [askForInput, setAskForInput] = useState(false);
  const [askFordefaultedInput, setAskFordefaultedInput] = useState(false);

  const [showLocalAlert, setShowLocalAlert] = useState(false);
  const [localAlertMessage, setLocalAlertMessage] = useState('');
  const [localAlertType, setLocalAlertType] = useState('error');
  const [showMenuModal, setShowMenuModal] = useState(false);

  useEffect(() => {
    getSyExam(id);
    resetModal();
  }, [isRefresh]);

  const resetModal = () => {
    setOpenModal(false);
    setAddOption(false);
    setAddHeading(false);
    setAddEndItem(false);
    setParentId(null);
    setEditId(null);
    setEditHeadingText('');
    setEditOptionSignText('');
    setLeafNode(false);
    setIsLoading(false);
    setAskForInput(false);
    setShowMenuModal(false);
    setAskFordefaultedInput(false);
    setAddSubItem(false);
  };

  const getSyExam = id => {
    setIsLoading(true);
    ApiClient.getSyExam(id)
      .then(response => {
        let data = response.data.data;
        setData(data);
        setDepartmentId(data.department_id);
        setSubspecialityId(data.subspeciality_id);
        setIsLoading(false);
      })
      .catch(error => {
        console.log(error);
        setIsLoading(false);
      });
  };
  const addOptionButton = (
    parentData = null,
    hideEditButton = false,
    isEndItem,
    isSubItem = false,
    isHeading,
  ) => {
    return (
      <>
        <MoreVertIcon
          onClick={() => setShowMenuModal(parentData?.id)}
          sx={{display: 'block', marginRight: '-8px', marginLeft: 'auto'}}
        />
        {showMenuModal == parentData?.id && (
          <div
            style={{
              justifyContent: 'center',
              borderRadius: '15px 5px 15px 15px',
              border: `2px solid #cccccc`,
              padding: '10px',
              color: Colors.BLACK,
              position: 'absolute',
              background: Colors.WHITE,
              zIndex: '1',
              fontSize: '14px',
              top: '0',
              right: '-8px',
              minWidth: '100px',
            }}>
            <Clear
              onClick={() => resetModal()}
              sx={{
                display: 'block',
                marginRight: '-8px',
                marginLeft: 'auto',
                fontSize: 18,
                cursor: 'pointer',
              }}
            />
            {!hideEditButton && (
              <CommonBorderBox
                onClick={() => {
                  setEditId(parentData?.id);
                  setAddHeading(true);
                  setEditHeadingText(parentData?.descriptor_code);
                  setAddEndItem(isEndItem);
                  setLeafNode(parentData?.leaf_node);
                  setOpenModal(true);
                }}
                style={{
                  padding: '0px 10px',
                  margin: 'auto',
                  borderColor: Colors.LIGHTBLACK,
                  background: Colors.WHITE,
                  textAlign: 'center',
                  marginBottom: '10px',
                }}>
                <p style={{margin: '0px', fontWeight: '600'}}>
                  Edit {isEndItem ? 'End Item' : 'Heading'}
                </p>
              </CommonBorderBox>
            )}
            <CommonBorderBox
              onClick={() => {
                setOpenModal(true);
                setParentId(parentData?.id);
                setAddOption(true);
                setAddSubItem(isSubItem);
              }}
              style={{
                padding: '0px 10px',
                margin: 'auto',
                borderColor: Colors.LIGHTBLACK,
                background: Colors.WHITE,
                textAlign: 'center',
              }}>
              <p style={{margin: '0px', fontWeight: '600'}}> Add Option</p>
            </CommonBorderBox>
          </div>
        )}
      </>
    );
  };

  const addHeadingButton = (parentData = null, isShowBtn) => {
    return (
      <>
        <MoreVertIcon
          onClick={() => {
            setShowMenuModal(parentData.id);
          }}
          sx={{display: 'block', marginRight: '-8px', marginLeft: 'auto'}}
        />
        {showMenuModal == parentData?.id && (
          <div
            style={{
              justifyContent: 'center',
              borderRadius: '15px 5px 15px 15px',
              border: `2px solid #cccccc`,
              padding: '10px',
              color: Colors.BLACK,
              position: 'absolute',
              background: Colors.WHITE,
              zIndex: '1',
              fontSize: '14px',
              top: '0',
              right: '-8px',
              minWidth: '110px',
            }}>
            <Clear
              onClick={() => setShowMenuModal(false)}
              sx={{
                display: 'block',
                marginRight: '-8px',
                marginLeft: 'auto',
                fontSize: 18,
                cursor: 'pointer',
              }}
            />
            {!parentData.parent_option_id && (
              <CommonBorderBox
                onClick={() => {
                  setEditId(parentData?.id);
                  setAddOption(true);
                  setEditOptionSignText(parentData.option_label);
                  setLeafNode(parentData?.leaf_node);
                  setOpenModal(true);
                  setAddSubItem(false);
                  setAskForInput(parentData?.ask_doctor_comment);
                  setAskFordefaultedInput(parentData?.default_selected);
                }}
                style={{
                  padding: '0px 10px',
                  borderColor: Colors.LIGHTBLACK,
                  background: Colors.WHITE,
                  textAlign: 'center',
                  margin: '10px 0px',
                }}>
                <p style={{margin: '0px', fontWeight: '600'}}>Edit Option</p>
              </CommonBorderBox>
            )}

            {parentData.parent_option_id && !isShowBtn && (
              <CommonBorderBox
                onClick={() => {
                  setEditId(parentData?.id);
                  setAddSubItem(true);
                  setEditOptionSignText(parentData?.option_label);
                  setLeafNode(parentData?.leaf_node);
                  setOpenModal(true);
                }}
                style={{
                  padding: '0px 10px',
                  margin: 'auto',
                  borderColor: Colors.LIGHTBLACK,
                  background: Colors.WHITE,
                  textAlign: 'center',
                  marginBottom: '10px',
                }}>
                <p style={{margin: '0px', fontWeight: '600'}}>
                  Edit Sub Option
                </p>
              </CommonBorderBox>
            )}

            {!parentData.parent_option_id && !isShowBtn && (
              <CommonBorderBox
                onClick={() => {
                  setOpenModal(true);
                  setParentId(parentData?.id);
                  setAddSubItem(true);
                }}
                style={{
                  padding: '0px 10px',
                  margin: 'auto',
                  borderColor: Colors.LIGHTBLACK,
                  background: Colors.WHITE,
                  textAlign: 'center',
                }}>
                <p style={{margin: '0px', fontWeight: '590'}}>Add Sub Option</p>
              </CommonBorderBox>
            )}

            {isShowBtn && (
              <>
                <CommonBorderBox
                  onClick={() => {
                    setOpenModal(true);
                    setParentId(parentData.id);
                    setAddHeading(true);
                  }}
                  style={{
                    padding: '0px 10px',
                    borderColor: Colors.LIGHTBLACK,
                    background: Colors.WHITE,
                    textAlign: 'center',
                    margin: '10px 0px',
                  }}>
                  <p style={{margin: '0px', fontWeight: '600'}}>Add Heading</p>
                </CommonBorderBox>
                {parentData?.symptom_questionnaire_id && (
                  <CommonBorderBox
                    onClick={() => {
                      setOpenModal(true);
                      setParentId(parentData.id);
                      setAddEndItem(true);
                    }}
                    style={{
                      padding: '0px 10px',
                      margin: 'auto',
                      borderColor: Colors.LIGHTBLACK,
                      background: Colors.WHITE,
                      textAlign: 'center',
                      margin: '10px 0px',
                    }}>
                    <p style={{margin: '0px', fontWeight: '600'}}>
                      Add End Item
                    </p>
                  </CommonBorderBox>
                )}
              </>
            )}
          </div>
        )}
      </>
    );
  };

  const renderHeading = (headingData, isEndItem, isSubItem, isHeading) => {
    const idStyle =
      showMenuModal == headingData?.id ? {color: Colors.BLACKBORDER} : {};

    return (
      <div
        style={{
          display: 'flex',
          fontSize: 'large',
          position: 'relative',
          ...idStyle,
        }}>
        <div
          style={{
            textAlign: 'justify',
            padding: '5px 10px',
            margin: 'auto',
            maxWidth: '300px',
          }}>
          {headingData.descriptor_code}
        </div>
        {addOptionButton(headingData, false, isEndItem, isSubItem, isHeading)}
      </div>
    );
  };

  const renderOptionBox = (option, suboption) => {
    const idStyle =
      showMenuModal == option?.id ? {color: Colors.BLACKBORDER} : {};
    return (
      <div
        style={{
          display: 'flex',
          fontSize: 'large',
          position: 'relative',
          ...idStyle,
        }}>
        <div
          style={{
            textAlign: 'justify',
            padding: '5px 10px',
            maxWidth: '300px',
          }}>
          {option?.option_label}
        </div>
        {addHeadingButton(option, !option.is_end_option, suboption)}
      </div>
    );
  };

  const renderSubspecialty = (subspecialty, hideEditButton) => {
    const idStyle =
      showMenuModal == undefined && hideEditButton
        ? {color: Colors.BLACKBORDER}
        : {};
    return (
      <div
        style={{
          display: 'flex',
          position: 'relative',
          justifyContent: 'center',
          ...idStyle,
        }}>
        <div
          style={{
            textAlign: 'center',
            padding: '10px 0px 10px 10px',
            margin: 'auto',
            marginRight: '-10px',
          }}>
          {subspecialty.name}
        </div>
        {addOptionButton(null, hideEditButton)}
      </div>
    );
  };

  const childHeadingMap = data => {
    return (
      <ul>
        {data.map((item, index) => {
          return (
            <li key={index}>
              <a>{renderOptionBox(item)}</a>
              {(item.child_headings?.length > 0 ||
                item.exam_end_items?.length > 0 ||
                item.sub_options?.length > 0) && (
                <ul>
                  {returnItem(item.child_headings)}
                  {returnItem(item.exam_end_items, true)}
                  {returnItem(item.sub_options, true)}
                </ul>
              )}
            </li>
          );
        })}
      </ul>
    );
  };

  const returnItem = (data, isEndItem) => {
    if (data) {
      return data.map((option, index) => {
        return (
          <li key={index}>
            <a>
              {option?.is_sub_option
                ? renderOptionBox(option)
                : renderHeading(option, isEndItem)}
            </a>
            {option?.heading_options?.length > 0 &&
              renderTree(option.heading_options)}
          </li>
        );
      });
    }
  };

  const renderTree = data => {
    if (data.length >= 1) {
      return childHeadingMap(data);
    } else {
      return (
        <ul>
          <li>
            <a>{renderOptionBox(data)}</a>
            <ul>
              {returnItem(data.child_headings)}
              {returnItem(data.exam_end_items, true)}
            </ul>
          </li>
        </ul>
      );
    }
  };

  const returnTree = () => {
    return (
      <div className="tree">
        <ul>
          <li>
            <a
              style={{
                minWidth: '250px',
                justifyContent: 'center',
                fontSize: 'large',
              }}>
              {data && renderSubspecialty(data, true)}
            </a>
            <ul style={{display: '-webkit-box'}}>
              {data?.heading_options &&
                data.heading_options.map((option, index) => {
                  return <li key={index}>{renderTree(option)}</li>;
                })}
            </ul>
          </li>
        </ul>
      </div>
    );
  };

  const renderModal = () => {
    const style = {
      position: 'absolute',
      top: '50px',
      boxShadow: 24,
      backgroundColor: '#fff',
      overflow: 'auto',
      maxHeight: '80vh',
      p: 4,
      minWidth: '50%',
      borderRadius: '15px',
    };

    return (
      <Modal
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={openModal}
        onClose={() => resetModal()}
        aria-labelledby="Show Symptom Questionnaire"
        aria-describedby="symptom questionnaire">
        <Box sx={style}>
          {(addOption || addSubItem) && (
            <SyExamOptionForm
              refresh={() => setIsrefresh(!isRefresh)}
              isSubItem={addSubItem}
              departmentId={departmentId}
              subspecialityId={subspecialityId}
              parentId={parentId}
              editId={editId}
              descriptorSign={editOptionSignText}
              leafNode={leafNode}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              setShowLocalAlert={setShowLocalAlert}
              setLocalAlertMessage={setLocalAlertMessage}
              askForInput={askForInput}
              askFordefaultedInput={askFordefaultedInput}
            />
          )}

          {(addHeading || addEndItem) && (
            <SyExamHeadingForm
              refresh={() => setIsrefresh(!isRefresh)}
              departmentId={departmentId}
              subspecialityId={subspecialityId}
              parentId={parentId}
              isEndItem={addEndItem}
              editId={editId}
              headingText={editHeadingText}
              leafNode={leafNode}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              setShowLocalAlert={setShowLocalAlert}
              setLocalAlertMessage={setLocalAlertMessage}
            />
          )}
        </Box>
      </Modal>
    );
  };

  return (
    <div
      style={{
        marginTop: '70px',
        display: 'flex',
        justifyContent: 'center',
      }}>
      <Header />
      {returnTree()}
      {renderModal()}
      <Loader loading={isLoading} />
      {showLocalAlert && (
        <LocalAlert
          severity={localAlertType}
          alertText={localAlertMessage}
          onClose={() => setShowLocalAlert(false)}
        />
      )}
    </div>
  );
}

export default SyExam;
