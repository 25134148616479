import React, {useEffect, useState} from 'react';
import {Box, Button, Modal} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {useNavigate, useParams} from 'react-router-dom';
import ApiClient from '../../networking/ApiManager';
import {
  CommonBorderBox,
  Header,
  Loader,
  LocalAlert,
} from '../../commonComponents';
import Colors from '../../Resources/COLORS/Colors';
import FollowUpQuestionaaryform from './FollowUpQuestionaaryform';
import FollowUpOptionForm from './FollowUpOptionForm';
import BlackCrossIcon from '../../commonComponents/BlackCrossIcon';

function CreateFollowUp(props) {
  const [questionnaireData, setQuestionnaireData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [modelOpen, setModelOpen] = useState(false);

  const [openQuestionnariForm, setOpenQuestionnariForm] = useState(false);
  const [openOptionsForm, setOpenOptionsForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [currentActiveQuestion, setCurrentActiveQuestion] = useState(null);
  const [currentActiveOption, setCurrentActiveOption] = useState(null);
  const [showDropdownOptions, setShowDropdownOptions] = useState(null);

  const navigate = useNavigate();
  const {id} = useParams();
  const questionnaireId = id
    ? id
    : questionnaireData
    ? questionnaireData.id
    : null;

  useEffect(() => {
    (questionnaireId || refresh) && getFollowUpQuestionDetails();
  }, [questionnaireId, refresh]);

  // questionnari apis start

  const getFollowUpQuestionDetails = (id = questionnaireId) => {
    setLoading(true);
    ApiClient.getFollowUpQuestionDetails(id)
      .then(res => {
        setQuestionnaireData(res.data.data);
        setModelOpen(false);
        setLoading(false);
      })
      .catch(err => {
        setModelOpen(false);
        setLoading(false);
        console.log(err);
      });
  };

  // questionnari apis end

  // start tree

  const showAddIcon = (data, size = '3rem') => {
    return (
      <AddIcon
        sx={{
          fontSize: size,
          marginLeft: 80,
          cursor: 'pointer',
          color: '#405672',
          display: 'block',
        }}
        onClick={data}
      />
    );
  };

  const renderQuestionBox = question => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: '10px',
          fontSize: 'large',
        }}>
        <div> {question.descriptor_sign}</div>
        <div>{question.descriptor_code}</div>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <CommonBorderBox
            style={{
              padding: '0px 10px',
              margin: 'auto',
              borderColor: Colors.LIGHTBLACK,
              background: Colors.WHITE,
              textAlign: 'center',
              marginRight: '10px',
              minWidth: '115px',
            }}
            onClick={() => {
              setCurrentActiveQuestion(question);
              setModelOpen(true);
              setOpenOptionsForm(false);
              setOpenQuestionnariForm(true);
            }}>
            <Button size="small">Edit Question</Button>
          </CommonBorderBox>
          {!question.child_follow_up_question && (
            <CommonBorderBox
              style={{
                padding: '0px 10px',
                margin: 'auto',
                borderColor: Colors.LIGHTBLACK,
                background: Colors.WHITE,
                textAlign: 'center',
                marginRight: '10px',
                minWidth: '115px',
              }}
              onClick={() => {
                setShowDropdownOptions(question.show_dropdown_options);
                setOpenQuestionnariForm(false);
                setCurrentActiveOption({symptom_questionnaire_id: question.id});
                setModelOpen(true);
                setOpenOptionsForm(true);
              }}>
              <Button size="small">Add Option</Button>
            </CommonBorderBox>
          )}
          {question.symptom_options?.length == 0 &&
            !question.child_follow_up_question && (
              <CommonBorderBox
                style={{
                  padding: '0px 10px',
                  margin: 'auto',
                  borderColor: Colors.LIGHTBLACK,
                  background: Colors.WHITE,
                  textAlign: 'center',
                  marginRight: '10px',
                  minWidth: '115px',
                }}
                onClick={() => {
                  setCurrentActiveQuestion({parentId: question.id});
                  setModelOpen(true);
                  setOpenOptionsForm(false);
                  setOpenQuestionnariForm(true);
                }}>
                <Button size="small">Add Question</Button>
              </CommonBorderBox>
            )}
        </div>
      </div>
    );
  };

  const renderOptionBox = (option, showDropdownOption) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: '10px',
          fontSize: 'large',
        }}>
        {option.option_label}({option.option_code})
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <CommonBorderBox
            style={{
              padding: '0px 10px',
              margin: 'auto',
              borderColor: Colors.LIGHTBLACK,
              background: Colors.WHITE,
              textAlign: 'center',
              marginRight: '10px',
              minWidth: '115px',
            }}
            onClick={() => {
              setShowDropdownOptions(showDropdownOption);
              setCurrentActiveOption(option);
              setOpenQuestionnariForm(false);
              setOpenOptionsForm(true);
              setModelOpen(true);
            }}>
            <Button size="small">Edit Option</Button>
          </CommonBorderBox>

          {!option.child_id && !option.child_follow_up_question && (
            <CommonBorderBox
              style={{
                padding: '0px 10px',
                margin: 'auto',
                borderColor: Colors.LIGHTBLACK,
                background: Colors.WHITE,
                textAlign: 'center',
                marginRight: '10px',
                minWidth: '115px',
              }}
              onClick={() => {
                setCurrentActiveQuestion({parentId: option.id});
                setModelOpen(true);
                setOpenOptionsForm(false);
                setOpenQuestionnariForm(true);
              }}>
              <Button size="small">Add Question</Button>
            </CommonBorderBox>
          )}
        </div>
      </div>
    );
  };

  const renderSyChartBox = (show, title = 'SyChart') => {
    if (show) {
      return (
        <ul style={{paddingLeft: '0'}}>
          <li style={{margin: 'auto'}}>
            <a
              style={{
                borderRadius: '100000px',
                fontSize: 'large',
                padding: '30px 15px',
              }}>
              {title}
            </a>
          </li>
        </ul>
      );
    }
  };

  const renderTree = data => {
    return (
      <ul>
        <li>
          <a href="#">{questionnaireData && renderQuestionBox(data)}</a>
          {data && data.symptom_options?.length > 0 ? (
            <ul>
              {data.symptom_options.map((option, index) => {
                return (
                  <li key={index}>
                    <a href="#">
                      {questionnaireData &&
                        renderOptionBox(option, data.show_dropdown_options)}
                    </a>
                    {option.child_follow_up_question
                      ? renderTree(option.child_follow_up_question)
                      : renderSyChartBox(option.start_sychart)}
                    {renderSyChartBox(
                      option.child_type == 'Subspecialty' && option.child_id,
                      'Subspecialty',
                    )}
                  </li>
                );
              })}
            </ul>
          ) : (
            <>{data.start_sychart && renderSyChartBox(data.start_sychart)}</>
          )}
        </li>
      </ul>
    );
  };

  // close tree

  const refreshRootElement = (object, object_type) => {
    if (object_type === 'question' && !object.parent_id) {
      navigate('/followups');
    } else {
      setRefresh(!refresh);
    }
  };

  const renderQuestionForm = () => {
    if (currentActiveQuestion) {
      return (
        <FollowUpQuestionaaryform
          questionData={currentActiveQuestion}
          refreshRootElement={object => refreshRootElement(object, 'question')}
        />
      );
    }
  };

  const renderOptionForm = () => {
    if (currentActiveOption) {
      return (
        <FollowUpOptionForm
          optionData={currentActiveOption}
          refreshRootElement={object => refreshRootElement(object, 'option')}
          showDropdownOptions={showDropdownOptions}
        />
      );
    }
  };

  const renderEmptyList = () => {
    return showAddIcon(() => {
      setCurrentActiveQuestion({});
      setOpenQuestionnariForm(true);
      setOpenOptionsForm(false);
      setModelOpen(true);
    }, '3rem');
  };

  return (
    <>
      <Header />
      <Modal
        open={modelOpen}
        onClose={() => setModelOpen(false)}
        aria-labelledby="Show FollowUp Questionnaire"
        aria-describedby="FollowUp questionnaire">
        <Box sx={styles.containerBox}>
          <BlackCrossIcon
            onClick={() => setModelOpen(false)}
            containerStyle={styles.containerStyle}
            style={styles.iconStyle}
          />
          {openOptionsForm && renderOptionForm()}
          {openQuestionnariForm && renderQuestionForm()}
        </Box>
      </Modal>

      <div className="tree">
        {questionnaireData ? (
          renderTree(questionnaireData)
        ) : (
          <FollowUpQuestionaaryform />
        )}
      </div>
      {showAlert && <LocalAlert onClose={() => setShowAlert(false)} />}
      <Loader loading={loading} />
    </>
  );
}

export default CreateFollowUp;

const styles = {
  containerBox: {
    position: 'absolute',
    top: '50px',
    left: '20px',
    boxShadow: 24,
    backgroundColor: '#fff',
    overflow: 'auto',
    maxHeight: '80vh',
    p: 4,
  },
  containerStyle: {
    height: 25,
    position: 'absolute',
    right: 4,
    top: 7,
  },
  iconStyle: {
    height: '15px',
    maxWidth: '17px',
    margin: '5px 8px',
  },
};
