import DepartmentSlice, {DepartmentThunks} from './DepartmentSlice';
import BodyPartsSlice from './BodyPartsSlice';
import SubspecialtiesSlice, {SubspecialtiesThunks} from './SubspecialtiesSlice';
import SymptomsSlice, {SymptomsThunks} from './SymptomsSlice';
import SymptomQuestionnairesSlice, {SymptomQuestionnairesThunks} from './SymptomQuestionnairesSlice';
import FollowUpOptionsSlice, {FollowUpOptionsThunks} from './FollowUpOptionsSlice';
import FollowUpQuestionsSlice, {FollowUpQuestionsThunks} from './FollowUpQuestionsSlice';
import LanguagesSlice, {LanguagesThunks} from './LanguagesSlice';
import BodySectionsSlice, {BodySectionsThunks} from './BodySectionsSlice';
import TaskSlice, {TaskThunks} from './TaskSlice';


const SuperDoctorSlices = {
  DepartmentSlice,
  BodyPartsSlice,
  SubspecialtiesSlice,
  SymptomsSlice,
  SymptomQuestionnairesSlice,
  FollowUpOptionsSlice,
  FollowUpQuestionsSlice,
  LanguagesSlice,
  BodySectionsSlice,
  TaskSlice
};

export default SuperDoctorSlices;

export const SuperDoctorThunks = {
  ...DepartmentThunks,
  ...SubspecialtiesThunks,
  ...SymptomsThunks,
  ...SymptomQuestionnairesThunks,
  ...FollowUpOptionsThunks,
  ...FollowUpQuestionsThunks,
  ...BodySectionsThunks,
  ...LanguagesThunks,
  ...TaskThunks 
};
