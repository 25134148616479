import React, {useEffect, useState} from 'react';
import {Box, Button, Modal} from '@mui/material';
import {LocalAlert, Header, CommonBorderBox} from '../commonComponents';
import ApiClient from '../networking/ApiManager';
import {useNavigate, useParams} from 'react-router-dom';
import {OptionsForm, QuestionnariForm} from './index';
import Loader from '../commonComponents/Loader';
import Colors from '../Resources/COLORS/Colors';
import {useLocalState, useTableData} from '../hooks';
import Utility from '../commonComponents/Utility';
import {useDispatch} from 'react-redux';
import AsyncThunks from '../redux/AsyncThunks';
import BlackCrossIcon from '../commonComponents/BlackCrossIcon';

function CreateQuestionnaire(props) {
  //all states

  const [questionnaireData, setQuestionnaireData] = useState(null);
  const [isCreateQuestionnari, setIsCreateQuestionnari] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [open, setOpen] = useState(false);
  const [openQuestionnariForm, setOpenQuestionnariForm] = useState(false);
  const [openOptionsForm, setOpenOptionsForm] = useState(false);
  const [parentOptionId, setParentOptionId] = useState(null);
  const [parentQuestionnariId, setParentQuestionnariId] = useState(null);
  const [questionnaireDataDataForUpdate, setQuestionnaireDataDataForUpdate] =
    useState(null);
  const [idForUpdateQuestionnari, setIdForUpdateQuestionnari] = useState(null);
  const [showDropDown, setShowDropDown] = useState(true);
  const [image, setImage] = useState('');
  const [firstQuestionnariId, setFirstQuestionnariId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isDurationQuestion, setIsDurationQuestion] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertType, setAlertType] = useState('error');

  //option params state
  const [optionData, setOptionData] = useState(null);
  const [departmentId, setDepartmentId] = useLocalState('department-id', '');
  const [spacialistId, setSpacialistId] = useLocalState('subspecialist-id', '');
  const [symptomsId, setSymptomsId] = useLocalState('symptom-id', '');
  const table = useTableData();
  //option params state end

  // end all states

  //location Data

  const navigate = useNavigate();
  const {id} = useParams();
  const questionnaireId = id
    ? id
    : questionnaireData
    ? questionnaireData.id
    : null;

  //end location Data

  useEffect(() => {
    (questionnaireId || refresh) && getSingleSymptomQuestionnaires();
  }, [questionnaireId, refresh]);

  useEffect(() => {
    setParentOptionId(questionnaireId);
  }, []);

  // questionnari apis start

  const getSingleSymptomQuestionnaires = (id = questionnaireId) => {
    setLoading(true);
    ApiClient.getSingleSymptomQuestionnaires(id)
      .then(res => {
        setImage(res.data.data.image_url);
        setIsCreateQuestionnari(false);
        setQuestionnaireData(res.data.data);
        setDepartmentId(res.data.data.department_id);
        setSpacialistId(res.data.data.subspecialty_id);
        setSymptomsId(res.data.data.symptom_id);
        setQuestionnaireDataDataForUpdate(res.data.data);
        setFirstQuestionnariId(res.data.data.id);
        setOpen(false);
        setLoading(false);
        setIsDurationQuestion(false);
      })
      .catch(err => {
        setOpen(false);
        setLoading(false);
        console.log(err.response);
      });
  };

  const dispatch = useDispatch();

  const createQuestionnari = params => {
    setLoading(true);
    dispatch(AsyncThunks.createSymptomQuestionnaire({params}))
      .unwrap()
      .then(res => {
        if (!firstQuestionnariId) {
          setFirstQuestionnariId(res.data.id);
        }
        getSingleSymptomQuestionnaires(questionnaireId || res.data.id);
        setLoading(false);
        setAlertType('success');
        setAlertMessage('Questions  Created Successfully');
        setShowAlert(true);
      })
      .catch(err => {
        setAlertType('error');
        setAlertMessage('Failed to Create Questions');
        setShowAlert(true);
        setLoading(false);
        console.log(err);
        console.log(err.response);
      });
  };

  const updateQuestionnari = (params, id = questionnaireId) => {
    setLoading(true);
    dispatch(AsyncThunks.updateSymptomQuestionnaire({params, id}))
      .unwrap()
      .then(res => {
        setAlertType('success');
        setAlertMessage('Questions update Successfully');
        setShowAlert(true);
        getSingleSymptomQuestionnaires();
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        setAlertType('error');
        setAlertMessage('Failed to updateQuestions');
        setShowAlert(true);
        console.log(err.response);
      });
  };

  const deleteQuestionnari = id => {
    const confirmDelete = window.confirm(
      'Are you sure you want to delete this item?',
    );
    if (confirmDelete) {
      setLoading(true);
      ApiClient.deleteSymptomsQuestions(id)
        .then(res => {
          getSingleSymptomQuestionnaires();
          if (id === firstQuestionnariId) {
            navigate('/questions');
          }

          setLoading(false);
          setOpen(false);
        })
        .catch(err => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  // questionnari apis end

  // start tree

  // const showAddIcon = (data, size = '3rem') => {
  //   return <AddIcon sx={{ color: 'white' }} onClick={data} />;
  // };

  const renderQuestionBox = question => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: '10px',
          fontSize: 'large',
        }}>
        <div style={{}}>{question.descriptor_code}</div>
        <div>{Utility.addQSId(question) + question.descriptor_sign}</div>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <CommonBorderBox
            style={{
              padding: '0px 10px',
              margin: 'auto',
              borderColor: Colors.LIGHTBLACK,
              background: Colors.WHITE,
              textAlign: 'center',
              marginRight: '10px',
              minWidth: '115px',
            }}>
            <Button
              size="small"
              onClick={() => {
                setParentQuestionnariId(null);
                setIdForUpdateQuestionnari(question.id);
                setOpenQuestionnariForm(true);
                setQuestionnaireDataDataForUpdate(question);
                setOpenOptionsForm(false);
                setOpen(true);
                setShowDropDown(true);
              }}>
              Edit Question
            </Button>
          </CommonBorderBox>
          <CommonBorderBox
            style={{
              padding: '0px 10px',
              margin: 'auto',
              borderColor: Colors.LIGHTBLACK,
              background: Colors.WHITE,
              textAlign: 'center',
              marginRight: '10px',
              minWidth: '115px',
            }}>
            <Button
              size="small"
              onClick={() => {
                setOptionData(null);
                setParentQuestionnariId(null);
                setIdForUpdateQuestionnari(null);
                setOpenQuestionnariForm(false);
                setOpenOptionsForm(false);
                setQuestionnaireDataDataForUpdate(null);
                question && setParentOptionId(question.id);
                setIsDurationQuestion(
                  question.descriptor_code?.toLowerCase() == 'duration',
                );

                setOpen(true);
                questionnaireId
                  ? setOpenOptionsForm(true)
                  : setOpenQuestionnariForm(true);
              }}>
              Add Option
            </Button>
          </CommonBorderBox>
        </div>
      </div>
    );
  };

  const renderOptionBox = option => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: '10px',
          fontSize: 'large',
        }}>
        {Utility.addOPId(option) + option.option_label} ( {option.option_code} )
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <CommonBorderBox
            divStyle={{margin: 'auto'}}
            style={{
              padding: '0px 10px',
              margin: 'auto',
              borderColor: Colors.LIGHTBLACK,
              background: Colors.WHITE,
              textAlign: 'center',
              marginRight: '10px',
              minWidth: '115px',
            }}>
            <Button
              size="small"
              onClick={() => {
                setOptionData(option);
                setIdForUpdateQuestionnari(null);
                setOpenQuestionnariForm(false);
                setQuestionnaireDataDataForUpdate(null);
                setOpenOptionsForm(true);
                setOpen(true);
              }}>
              Edit Option
            </Button>
          </CommonBorderBox>

          {!option.child_questionnaire && (
            <CommonBorderBox
              style={{
                padding: '0px 10px',
                margin: 'auto',
                borderColor: Colors.LIGHTBLACK,
                background: Colors.WHITE,
                textAlign: 'center',
                marginRight: '10px',
                minWidth: '115px',
              }}>
              <Button
                size="small"
                onClick={() => {
                  setOptionData(null);
                  setShowDropDown(false);
                  setParentQuestionnariId(option.id);
                  setIdForUpdateQuestionnari(null);
                  setOpenQuestionnariForm(true);
                  setQuestionnaireDataDataForUpdate(null);
                  setOpenOptionsForm(false);
                  setIsCreateQuestionnari(true);
                  setOpen(true);
                }}>
                Add Question
              </Button>
            </CommonBorderBox>
          )}
        </div>
      </div>
    );
  };

  const renderTree = data => {
    return (
      <ul>
        <li>
          <a href="#" style={{padding: 40}}>
            {data && renderQuestionBox(data)}
          </a>
          <ul>
            {data &&
              data.symptom_options &&
              data.symptom_options.map((option, index) => {
                return (
                  <li key={index}>
                    <a href="#" style={{padding: 40}}>
                      {renderOptionBox(option)}
                    </a>
                    {option.child_questionnaire &&
                      renderTree(option.child_questionnaire)}
                  </li>
                );
              })}
          </ul>
        </li>
      </ul>
    );
  };

  // close tree

  const showQuestionnairiForm = () => {
    return (
      <QuestionnariForm
        showDropDown={showDropDown}
        questionnaireData={
          questionnaireDataDataForUpdate && questionnaireDataDataForUpdate
        }
        isCreate={isCreateQuestionnari}
        createNew={createQuestionnari}
        updateQuestionnari={updateQuestionnari}
        deleteQuestionnari={deleteQuestionnari}
        parentId={parentQuestionnariId}
        departmentId={departmentId}
        spacialistId={spacialistId}
        symptomsId={symptomsId}
        image={image}
      />
    );
  };

  const createQuestionnaireList = () => {
    return (
      <OptionsForm
        refresh={() => setRefresh(!refresh)}
        questionnaireId={parentOptionId}
        isDuration={isDurationQuestion}
        data={optionData}
      />
    );
  };

  // const renderEmptyList = () => {
  //   return showAddIcon(() => {
  //     setOptionData(null);
  //     setParentQuestionnariId(null);
  //     setIdForUpdateQuestionnari(null);
  //     setOpenQuestionnariForm(false);
  //     setOpenOptionsForm(false);
  //     setQuestionnaireDataDataForUpdate(null);
  //     setOpen(false);
  //     questionnaireId ? setOpenOptionsForm(true) : setOpenQuestionnariForm(true);
  //   }, '3rem');
  // };

  const style = {
    position: 'absolute',
    top: '50px',
    left: '20px',
    boxShadow: 24,
    backgroundColor: '#fff',
    overflow: 'auto',
    maxHeight: '80vh',
    p: 4,
  };

  return (
    <>
      <Header />
      <Box sx={{marginBottom: 1, width: '100%'}}>
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="Show Symptom Questionnaire"
          aria-describedby="symptom questionnaire">
          <Box sx={style}>
            <BlackCrossIcon
              onClick={() => setOpen(false)}
              containerStyle={styles.containerStyle}
              style={styles.iconStyle}
            />
            {openQuestionnariForm && showQuestionnairiForm()}
            {openOptionsForm && createQuestionnaireList()}
          </Box>
        </Modal>
        <div className="tree">
          {questionnaireData
            ? renderTree(questionnaireData)
            : showQuestionnairiForm()}
        </div>
        {showAlert && (
          <LocalAlert
            severity={alertType}
            alertText={alertMessage}
            onClose={() => setShowAlert(false)}
          />
        )}
        <Loader loading={loading} />
      </Box>
    </>
  );
}

export default CreateQuestionnaire;

const styles = {
  containerStyle: {
    height: 25,
    position: 'absolute',
    right: 4,
    top: 7,
  },
  iconStyle: {
    height: '15px',
    maxWidth: '17px',
    margin: '5px 8px',
  },
};
