import {Box, Stack, Pagination} from '@mui/material';
import React, {useCallback, useState} from 'react';
import {Header} from '../commonComponents';
import ApiClient from '../networking/ApiManager';
import VersionsTable from '../commonComponents/VersionsTable';

const Versions = props => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const getVersions = useCallback(
    pageNumber => {
      ApiClient.getVersions(page)
        .then(response => {
          setData(response.data.data);
          setTotalPages(response.headers['x-total-pages']);
        })
        .catch(error => {
          console.log(error);
        });
    },
    [page],
  );

  React.useEffect(() => {
    getVersions(page);
  }, [page]);

  const handlePageChange = (event, newPage) => {
    console.log(newPage);
    setPage(newPage);
  };

  const tableHeaderData = [
    'Item Type',
    'Whodunnit',
    'Item ID',
    'Event',
    'Attributes',
    'Created At',
  ];
  const dataKeys = [
    'item_type',
    'whodunnit',
    'item_id',
    'event',
    'changeset',
    'created_at',
  ];

  return (
    <Box>
      <Header />
      <VersionsTable
        version
        data={data}
        tableHeaderData={tableHeaderData}
        dataKeys={dataKeys}
        tableStyle={{width: '100%'}}
        tableContainerStyle={{marginTop: 3}}
        emptyTitle="No Version Data Found"
      />
      <Stack
        spacing={2}
        sx={{
          justifyContent: 'center',
          display: 'flex',
          marginTop: 2,
          marginBottom: 3,
          alignItems: 'center',
        }}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          color="primary"
        />
      </Stack>
    </Box>
  );
};
export default Versions;
