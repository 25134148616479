import React, {useEffect, useState} from 'react';
import {
  Box,
  Button,
  FormControlLabel,
  Checkbox,
  FormGroup,
  FormControl,
  Modal,
} from '@mui/material';
import {
  CommonTextInput,
  LocalAlert,
  CommonDropDown,
  CommonButton,
} from '../commonComponents';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ApiClient from '../networking/ApiManager';
import LanguageDropdown from '../commonComponents/LanguageDropdown';
import CloseIcon from '@mui/icons-material/Close';
import Loader from '../commonComponents/Loader';
import {useSelector} from 'react-redux';
import {useTableData} from '../hooks';
import Colors from '../Resources/COLORS/Colors';

function QuestionnariForm(props) {
  const [departmentId, setDepartmentId] = useState(props.departmentId || '');
  const [spacialistId, setSpacialistId] = useState(props.spacialistId || '');
  const [symptomsId, setSymptomsId] = useState(props.symptomsId || '');
  const [questionType, setQuestionType] = useState('');
  const [lSign, setLSign] = useState({});
  const [code, setCode] = useState('');
  const [hint, setHint] = useState({});
  const [departmentData, setDepartmentData] = useState(null);
  const [symptomsData, setSymptomsData] = useState(null);
  const [spacialistData, setSpacialistData] = useState(null);
  const [image, setImage] = useState('');
  const [serverImage, setServerImage] = useState(props.image || '');
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [checkedForAskPatientImage, setcheckedForAskPatientImage] =
    useState(false);
  const [isMultipleOptionSelect, setIsMultipleOptionSelect] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertType, setAlertType] = useState('error');

  const {departments} = useTableData(true);

  useEffect(() => {
    setDepartmentData(departments);
  }, [departments]);

  useEffect(() => {
    departmentId && getSpacialList();
  }, [departmentId]);

  useEffect(() => {
    departmentId && spacialistId && getSymptoms();
  }, [spacialistId, departmentId]);

  useEffect(() => {
    props.questionnaireData && setStates();
  }, [props.questionnaireData]);

  const questionTypeData = [
    {key: 'multiple_select', name: 'Multiple Select'},
    {key: 'single_select', name: 'Single Select'},
    {key: 'text', name: 'Text'},
    {key: 'slider', name: 'Slider'},
    {key: 'numeric', name: 'Numeric'},
    {key: 'duration_select', name: 'Duration Select'},
  ];

  const setStates = () => {
    setDepartmentId(props.questionnaireData.department_id);
    setSpacialistId(props.questionnaireData.subspecialty_id);
    setSymptomsId(props.questionnaireData.symptom_id);
    setLSign(props.questionnaireData.ldescriptor_sign);
    setCode(props.questionnaireData.descriptor_code);
    setHint(props.questionnaireData.lhint);
    setChecked(props.questionnaireData.in_summary);
    setcheckedForAskPatientImage(
      props.questionnaireData.ask_patient_input_image,
    );
    setIsMultipleOptionSelect(props.questionnaireData.multiple_option_select);
    setQuestionType(props.questionnaireData.question_type);
  };

  const getSymptoms = () => {
    setLoading(true);
    ApiClient.getSymptoms(spacialistId)
      .then(res => {
        setSymptomsData(res.data.data);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
      });
  };

  const getSpacialList = () => {
    setLoading(true);
    ApiClient.getSpacialList(departmentId)
      .then(res => {
        setSpacialistData(res.data.data);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleTranslate = () => {
    setLoading(true);
    ApiClient.Translate(props.questionnaireData.id)
      .then(res => {
        console.warn('trans', res);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
      });
  };

  const saveData = () => {
    if (Object.keys(lSign).length === 0 || Object.values(lSign)[0] === '') {
      setShowAlert(true);
      setAlertMessage('Please provide a descriptor user');
      setAlertType('error');
    } else if (!code) {
      setShowAlert(true);
      setAlertMessage('Please provide a descriptor system');
      setAlertType('error');
    } else {
      let formData = new FormData();
      formData.append('department_id', departmentId);
      formData.append('subspecialty_id', spacialistId);
      formData.append('symptom_id', symptomsId);
      formData.append('question_type', questionType);

      for (const key of Object.keys(lSign)) {
        formData.append('ldescriptor_sign[' + key + ']', lSign[key]);
      }

      for (const key of Object.keys(hint)) {
        formData.append('lhint[' + key + ']', hint[key]);
      }

      formData.append('descriptor_code', code);
      image && formData.append('image', image);
      formData.append('in_summary', checked);
      formData.append('ask_patient_input_image', checkedForAskPatientImage);
      formData.append('multiple_option_select', isMultipleOptionSelect);

      if (props.parentId) {
        formData.append('parent_id', props.parentId);
      }

      if (props.isCreate) {
        props.createNew(formData);
      } else {
        props.updateQuestionnari(
          formData,
          props.questionnaireData && props.questionnaireData.id,
        );
      }
    }
  };
  return (
    <Box sx={{margin: 2}}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <h2 sx={{margin: '15px 0'}}> Create Questions :-</h2>
        {props?.questionnaireData?.id && (
          <div
            onClick={handleTranslate}
            style={{
              background: Colors.APPCIRCLE,
              padding: '5px 25px',
              borderRadius: '15px',
              color: Colors.WHITE,
              fontSize: 'large',
              fontWeight: '600',
              cursor: 'pointer',
              alignSelf: 'center',
              width: 'fit-content',
            }}>
            Translate
          </div>
        )}
      </div>
      {props.showDropDown && (
        <>
          <CommonDropDown
            formControlStyle={{width: '100%', margin: '15px 0'}}
            label="Department Name"
            labelId="demo-multiple-name-label"
            onChange={setDepartmentId}
            value={departmentId}
            selectStyle={{width: '100%'}}
            data={departmentData}
            setData={'id'}
            showData={'name'}
          />

          <CommonDropDown
            formControlStyle={{width: '100%', marginBottom: 15}}
            label="Subspecialty"
            labelId="demo-multiple-spacial-label"
            onChange={setSpacialistId}
            value={spacialistId}
            selectStyle={{width: '100%'}}
            data={spacialistData}
            setData={'id'}
            showData={'name'}
          />

          <CommonDropDown
            formControlStyle={{width: '100%', marginBottom: 15}}
            label="Symptom"
            labelId="demo-multiple-symptoms-label"
            onChange={setSymptomsId}
            value={symptomsId}
            selectStyle={{width: '100%'}}
            data={symptomsData}
            setData={'id'}
            showData={'name'}
          />
          <CommonDropDown
            formControlStyle={{width: '100%', marginBottom: 15}}
            label="Question Type"
            labelId="demo-multiple-symptoms-label"
            onChange={setQuestionType}
            value={questionType}
            selectStyle={{width: '100%'}}
            data={questionTypeData}
            setData={'key'}
            showData={'name'}
          />
        </>
      )}
      <LanguageDropdown
        inputValue={lSign}
        onChange={setLSign}
        label="Descriptor User"
      />
      <CommonTextInput
        maxLength={250}
        showLength={125}
        style={{width: '100%', marginTop: '15px', marginBottom: '15px'}}
        value={code}
        label="Descriptor System"
        onChange={setCode}
      />

      <LanguageDropdown inputValue={hint} onChange={setHint} label="Hint" />
      <div style={{marginTop: '20px'}}>
        {(image || serverImage) && (
          <div>
            {image && (
              <img
                style={{maxWidth: '200px', maxHeight: '200px'}}
                src={URL.createObjectURL(image)}
              />
            )}
            {!image && serverImage && (
              <img
                style={{maxWidth: '200px', maxHeight: '200px'}}
                src={serverImage}
              />
            )}
          </div>
        )}
      </div>
      <div style={{display: 'flex'}}>
        <label htmlFor="file-input">
          <Button
            variant="contained"
            sx={{width: '100%', backgroundColor: '#405672', marginTop: 2}}
            component="span">
            Attach Image
          </Button>
        </label>
      </div>
      <CommonTextInput
        id="file-input"
        type="file"
        style={{visibility: 'hidden'}}
        onChange={setImage}
        maxLength={20}
      />
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={checked} />}
          label="Available in Summary "
          onChange={() => setChecked(!checked)}
        />
      </FormGroup>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={checkedForAskPatientImage} />}
          label="Ask for patient input image"
          onChange={() =>
            setcheckedForAskPatientImage(!checkedForAskPatientImage)
          }
        />
      </FormGroup>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={isMultipleOptionSelect} />}
          label="Allow multiple option select"
          onChange={() => setIsMultipleOptionSelect(!isMultipleOptionSelect)}
        />
      </FormGroup>
      <CommonButton
        style={{
          width: '100%',
          backgroundColor: '#405672',
          margin: '20px 0 20px 0px',
        }}
        label={'Save'}
        onClick={saveData}
      />
      {!props.isCreate && props.questionnaireData?.leaf_node && (
        <CommonButton
          style={{
            width: '100%',
            backgroundColor: '#405672',
            margin: '20px 0 20px 0px',
          }}
          label="Delete Questionary"
          onClick={() => {
            props.deleteQuestionnari(props.questionnaireData.id);
          }}
        />
      )}
      {showAlert && (
        <LocalAlert
          severity={alertType}
          alertText={alertMessage}
          onClose={() => setShowAlert(false)}
        />
      )}
      <Loader loading={loading} />
    </Box>
  );
}

export default QuestionnariForm;
