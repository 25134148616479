import React, {useEffect, useState} from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Modal,
} from '@mui/material';
import ApiClient from '../../networking/ApiManager';
import {
  CommonDropDown,
  CommonTextInput,
  Loader,
  LocalAlert,
} from '../../commonComponents';
import LanguageDropdown from '../../commonComponents/LanguageDropdown';

function FollowUpOptionForm(props) {
  const {showDropdownOptions} = props;

  const [optionCode, setOptionCode] = useState(props.optionData.option_code);
  const [optionId, setOptionId] = useState(props.optionData.id);
  const [loading, setLoading] = useState(false);
  const [childId, setChildID] = useState(props.optionData.child_id);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertType, setAlertType] = useState('error');
  const [childType, setChildType] = useState(
    props.optionData.child_type || 'Subspecialty',
  );
  const [lOptionLabel, setLOptionLabel] = useState(
    props.optionData.loption_label || {},
  );
  const [startSyChart, setStartSyChart] = useState(
    props.optionData.start_sychart,
  );

  let params = {
    loption_label: lOptionLabel,
    option_code: optionCode,
    symptom_questionnaire_id: props.optionData.symptom_questionnaire_id,
    start_sychart: startSyChart,
    child_id: childId,
    child_type: childType,
  };

  const clearFormData = () => {
    setOptionCode('');
    setOptionId(null);
  };

  const updateData = () => {
    if (
      Object.keys(lOptionLabel).length === 0 ||
      Object.values(lOptionLabel)[0] === ''
    ) {
      setShowAlert(true);
      setAlertMessage('Please provide option user');
      setAlertType('error');
    } else if (!optionCode) {
      setShowAlert(true);
      setAlertMessage('Please provide option system');
      setAlertType('error');
    } else {
      setLoading(true);
      ApiClient.followUpOptionUpdate(params, optionId)
        .then(res => {
          props.refreshRootElement(res.data.data);
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  const deleteData = () => {
    const confirmDelete = window.confirm(
      'Are you sure you want to delete this option?',
    );
    if (confirmDelete) {
      setLoading(true);
      ApiClient.deleteFollowUpOption(props.optionId || optionId)
        .then(res => {
          clearFormData();
          props.refreshRootElement(res.data.data);
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          console.log(err.response);
        });
    }
  };

  const createData = () => {
    if (
      Object.keys(lOptionLabel).length === 0 ||
      (Object.values(lOptionLabel)[0] === '' && !optionCode && !childId)
    ) {
      setShowAlert(true);
      setAlertMessage('Please fill something');
      setAlertType('error');
    } else {
      setLoading(true);
      ApiClient.createFollowUpOption(params)
        .then(res => {
          setOptionId(res.data.data.id);
          props.refreshRootElement(res.data.data);
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          console.log('error', err.response);
        });
    }
  };

  const createBtnMargin = !props.isCreate ? '20px 10px 20px 0' : '20px 0px';

  const handleDropDownChange = e => {
    setOptionCode(e[0]);
  };

  const renderOptionSystem = () => {
    const durationData = [
      {code: 'hours', label: 'hours'},
      {code: 'days', label: 'days'},
      {code: 'weeks', label: 'weeks'},
      {code: 'months', label: 'months'},
      {code: 'years', label: 'years'},
    ];

    const showData = showDropdownOptions ? showDropdownOptions : durationData;

    if (
      props.data?.option_type == 'duration' ||
      props.isDuration ||
      showDropdownOptions
    ) {
      return (
        <CommonDropDown
          formControlStyle={{m: 1, minWidth: '26ch', width: '80vw'}}
          label="Option System"
          labelId="Option_system"
          onChange={handleDropDownChange}
          value={optionCode}
          selectStyle={{width: '100%'}}
          data={showData}
          setData={'code'}
          showData={'label'}
        />
      );
    } else {
      return (
        <>
          <FormControl
            sx={{m: 1, minWidth: '26ch', width: '80vw'}}
            variant="outlined">
            <CommonTextInput
              maxLength={500}
              showLength={150}
              label="Option System"
              style={{m: 1, width: '100%'}}
              onChange={setOptionCode}
              value={optionCode}
            />
          </FormControl>
          <FormGroup>
            <FormControlLabel
              style={{marginLeft: -1}}
              control={<Checkbox checked={startSyChart} />}
              label="Start SyChart?"
              onChange={() => setStartSyChart(!startSyChart)}
            />
          </FormGroup>
        </>
      );
    }
  };

  const renderSubspecialityInput = () => {
    return (
      <div style={{textAlign: 'left'}}>
        <CommonTextInput
          maxLength={500}
          showLength={150}
          style={{width: '100%', marginTop: '18px'}}
          value={childId}
          label="Subspeciality"
          onChange={setChildID}
        />
      </div>
    );
  };

  return (
    <div
      style={{
        textAlign: 'center',
        display: 'flex',
        flexWrap: 'wrap',
        margin: '0 15px 20px 15px',
      }}>
      <div style={{textAlign: 'center', margin: 'auto'}}>
        <FormControl
          sx={{m: 1, minWidth: '26ch', width: '80vw'}}
          variant="outlined">
          <LanguageDropdown
            inputValue={lOptionLabel}
            onChange={setLOptionLabel}
            label="Option User"
          />
        </FormControl>
        <div style={{textAlign: 'left'}}>{renderOptionSystem()}</div>
        {!props.optionData.child_follow_up_question &&
          renderSubspecialityInput()}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '80vw',
            margin: 'auto',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          {props.showActions || optionId ? (
            <>
              <Button
                variant="contained"
                sx={{
                  width: '100%',
                  backgroundColor: '#405672',
                  margin: createBtnMargin,
                }}
                component="span"
                onClick={updateData}>
                Update Option
              </Button>

              <Button
                variant="contained"
                sx={{
                  width: '100%',
                  backgroundColor: '#405672',
                  margin: '20px 0 20px 10px',
                }}
                component="span"
                onClick={deleteData}>
                Delete Option
              </Button>
            </>
          ) : (
            <Button
              variant="contained"
              sx={{
                width: '100%',
                backgroundColor: '#405672',
                margin: '20px 0 20px 10px',
              }}
              component="span"
              onClick={createData}>
              Save
            </Button>
          )}
        </div>
      </div>
      {showAlert && (
        <LocalAlert
          severity={alertType}
          alertText={alertMessage}
          onClose={() => setShowAlert(false)}
        />
      )}
      <Loader loading={loading} />
    </div>
  );
}

export default FollowUpOptionForm;
