import React, {useCallback, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useSychart} from '../../Contexts/SychartContext';
import Colors from '../../Resources/COLORS/Colors';

const fontSize = 13;

const SingleBodyPart = ({item, imageLayout, partIndex, partId}) => {
  // submitResponse is a function that is used to submit the response to the server
  const isLeft = partIndex % 2 === 0;
  const {submitResponse} = useSychart();
  const [cords, setCords] = React.useState([{x: 0, y: 0}]);
  // x1 and y1 are the coordinates of the point where the part is located
  const x1 = (item.position_x * imageLayout.reducedBy) / 100;
  const y1 = (item.position_y * imageLayout.reducedBy) / 100;
  const divId = 'assign-box-div-';
  // boxLayout is the coordinates of the box where the title of the part is located
  // this is the area which is gived to each part to show the title of the part and the description
  const [boxLayout, setBoxLayout] = React.useState({x: 0, y: 0});
  const imageWidth = imageLayout.width;
  const imageHeight = imageLayout.height;
  // we have array of signs so we join them with ,
  const signs = Array.isArray(item.symptom_signs)
    ? item.symptom_signs.join(', ')
    : item.symptom_signs;
  // it is to detect if the title of the part is on the left side of the image or on the right side
  const isTitleLeft = boxLayout.x < imageWidth * 0.5;
  // the childRef is the box which is inside the boxLayout and hs a specified are to display the description
  const childRef = React.useRef(null);
  // the parentRef is the boxLayout which is the box which has the title of the part and the description
  // we calculate new xy using so we can draw a line from the point to the boxLayout and show the part nae
  const parentRef = React.useRef(null);
  // we are using this ref to get the width of the title of the part so we can move the title to show between the point
  const nameDivRef = React.useRef(null);

  const onClick = () => {
    // the ponit is clicked so we submit the response
    submitResponse(item.body_part_id, partId);
  };

  // thes both x and y defined postion of the div which will containe description

  const translateX = isTitleLeft
    ? boxLayout.x - item.boxWidth / 4
    : boxLayout.x - item.boxWidth / 2;

  const translateY = boxLayout.y + fontSize * 1.5;

  // these are the cords to draw the line from the point to the boxLayout
  // first x and y are the point coordinates and last x and y are the cords where part name is located

  useEffect(() => {
    if (signs) {
      setCords([
        {
          x: x1,
          y: y1,
        },
        {
          x: isTitleLeft ? item.boxWidth / 1.5 : translateX,
          y: boxLayout.y,
        },
        {
          x: boxLayout.x,
          y: boxLayout.y,
        },
      ]);
    } else {
      setCords([
        {
          x: x1,
          y: y1,
        },
        {
          x: boxLayout.x,
          y: boxLayout.y,
        },
      ]);
    }
  }, [
    signs,
    boxLayout.x,
    boxLayout.y,
    item.boxWidth,
    translateX,
    isTitleLeft,
    x1,
    y1,
  ]);

  const checkForArrangements = useCallback(() => {
    const div = parentRef?.current?.getBoundingClientRect();
    if (div) {
      let pageY = div.y;
      let xCenter = isLeft ? div.width / 4 : div.width + div.width;
      const singelBodyDiv = document.getElementById('single-body-div');
      const singelBodyDivRect = singelBodyDiv.getBoundingClientRect();
      div.x = div.x - singelBodyDivRect.x;
      div.x = div.x + 20;
      pageY = pageY - singelBodyDivRect.y;
      // if xCenter is grate than the device width we will set it to the device width or if it is less than 0 we will set it to 0
      // if pageY is less than 0 we will set it to 0 or if it is greater than the device height we will set it to the device height
      xCenter = xCenter > imageWidth ? imageWidth : xCenter;
      xCenter = xCenter < 0 ? 0 : xCenter;
      pageY = pageY < 0 ? 0 : pageY;
      pageY = pageY > imageHeight ? imageHeight : pageY;
      setBoxLayout({
        x: xCenter,
        y: pageY,
      });
    }
  }, [imageWidth, imageHeight, isLeft]);

  useEffect(() => {
    const signsDiv = childRef?.current?.getBoundingClientRect();
    const nameDiv = nameDivRef?.current?.getBoundingClientRect();
    if (signsDiv) {
      // set height of parent ref to the height of the child ref
      parentRef.current.style.height = signsDiv.height + 'px';
      setCords(prev => {
        const newCords = [...prev];
        const lastY = newCords[newCords.length - 1].y;
        const newHeight = lastY - nameDiv.height / 1.4;
        let finalHeight = 0;
        if (newHeight < 0) {
          finalHeight = 0;
        } else if (newHeight > imageHeight) {
          finalHeight = imageHeight;
        } else {
          finalHeight = newHeight;
        }
        newCords[newCords.length - 1].y = finalHeight;
        if (newCords.length === 3) {
          newCords[newCords.length - 2].y = finalHeight;
        }
        return newCords;
      });
      checkForArrangements();
    }
  }, [boxLayout.x, boxLayout.y, imageHeight, checkForArrangements]);

  useEffect(() => {
    // the box which is equily asign to each part we need its widht and height and x and y to calculate the new xy
    // then we will find the center of the box and show the part name there
    checkForArrangements();
  }, [checkForArrangements]);

  const nameTop = useMemo(() => {
    return cords[cords.length - 1].y;
  }, [cords]);

  return (
    <React.Fragment>
      <div
        ref={parentRef}
        id={divId + partIndex}
        onClick={onClick}
        style={styles.parentDiv(item)}
      />
      <svg style={styles.svg}>
        <circle
          cx={x1}
          cy={y1}
          r={2}
          stroke={Colors.BLUE_PART}
          strokeWidth={1}
          fill={Colors.BLUE_PART}
        />
        <polyline
          points={cords.map(cord => `${cord.x},${cord.y}`).join(' ')}
          fill="none"
          style={styles.polyLine}
        />
        <circle
          cx={cords[cords.length - 1].x}
          cy={cords[cords.length - 1].y}
          r={2}
          stroke={Colors.BLUE_PART}
          strokeWidth={1}
          fill={Colors.BLUE_PART}
        />
      </svg>
      <p
        onClick={onClick}
        ref={nameDivRef}
        style={styles.bodyPartName(boxLayout, nameDivRef, item, nameTop)}>
        {item.body_part_name}
      </p>
      <div
        onClick={onClick}
        ref={childRef}
        style={styles.paragraphDiv(item, isTitleLeft, translateX, translateY)}>
        <p onClick={onClick} style={styles.signParagraph(item)}>
          {signs}
        </p>
      </div>
    </React.Fragment>
  );
};

export default SingleBodyPart;

SingleBodyPart.propTypes = {
  item: PropTypes.object.isRequired,
  imageLayout: PropTypes.object.isRequired,
  imageIndex: PropTypes.number.isRequired,
  isLeft: PropTypes.bool.isRequired,
  partIndex: PropTypes.bool.isRequired,
  partId: PropTypes.number.isRequired,
};

const styles = {
  signParagraph: item => ({
    cursor: 'pointer',
    maxWidth: item.boxWidth / 1.5,
    wordWrap: 'break-word',
  }),
  paragraphDiv: (item, isTitleLeft, translateX, translateY) => ({
    position: 'absolute',
    width: item.boxWidth / 1.5,
    display: 'flex',
    justifyContent: isTitleLeft ? 'flex-start' : 'flex-end',
    transform: `translate(${translateX}px, ${translateY}px)`,
    zIndex: 1,
    cursor: 'pointer',
    fontSize: 12,
  }),
  bodyPartName: (boxLayout, nameDivRef, item, top) => ({
    position: 'absolute',
    cursor: 'pointer',
    width: item.boxWidth / 1.7,
    fontSize,
    color: Colors.BLUE_PART,
    top,
    left: boxLayout.x - nameDivRef?.current?.offsetWidth / 2,
    zIndex: 1,
  }),
  polyLine: {
    stroke: Colors.BLUE_PART,
    strokeWidth: 0.5,
  },
  svg: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  parentDiv: item => ({
    width: item.boxWidth,
    marginVertical: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '2%',
  }),
};
