import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import LanguageDropdown from '../commonComponents/LanguageDropdown';
import CommonTextInput from './CommonTextInput';
import CommonDropDown from './CommonDropDown';
import AddEditModal from './AddEditModal';
import LocalAlert from './LocalAlert';
import {useDispatch} from 'react-redux';
import AsyncThunks from '../redux/AsyncThunks';

const BodySectionAddEditModal = props => {
  const bodySection = props.bodySection;
  const imagePath = bodySection?.image_url;
  const [showAlert, setShowAlert] = React.useState(false);
  const notValid = useMemo(() => {
    return (
      (!bodySection.image && !bodySection.image_url) ||
      Object.keys(bodySection.lname).length === 0 ||
      !bodySection.gender
    );
  }, [bodySection]);
  const dispatch = useDispatch();

  const onChange = (key, value) => {
    props.setBodySection(prev => ({...prev, [key]: value}));
  };

  const bodyParams = () => {
    const formData = new FormData();
    const params = {...bodySection};
    delete params.image_url;
    delete params.id;
    Object.keys(params).forEach(key => {
      if (key === 'lname') {
        Object.keys(params[key]).forEach(lNameKey => {
          formData.append('lname[' + lNameKey + ']', params[key][lNameKey]);
        });
      } else {
        formData.append(key, params[key]);
      }
    });
    return formData;
  };

  const createBodySection = () => {
    if (notValid) {
      setShowAlert(true);
      return;
    }
    dispatch(AsyncThunks.createBodySection({params: bodyParams()}))
      .unwrap()
      .then(res => {
        props.close(false);
      });
  };

  const updateBodySection = () => {
    if (notValid) {
      setShowAlert(true);
      return;
    }
    const params = {id: bodySection.id, params: bodyParams()};
    dispatch(AsyncThunks.updateBodySection(params))
      .unwrap()
      .then(res => {
        props.close(false);
      });
  };

  const onFileChange = file => {
    onChange('image_url', URL.createObjectURL(file));
    onChange('image', file);
  };

  const genderdata = [
    {name: 'Male', value: 'male'},
    {name: 'Female', value: 'female'},
    {name: 'Others', value: 'others'},
  ];

  return (
    <AddEditModal
      visible={props.visible}
      onClick={bodySection.id ? updateBodySection : createBodySection}
      buttonText={'Save'}
      close={() => props.close(false)}>
      {imagePath && (
        <img
          style={{maxWidth: '200px', maxHeight: '200px'}}
          src={imagePath}
          alt="img-path"
        />
      )}
      <CommonTextInput
        type="file"
        style={{width: '100%', margin: '15px 0'}}
        onChange={onFileChange}
      />
      <LanguageDropdown
        inputValue={bodySection?.lname || {}}
        onChange={value => {
          props.setBodySection(prev => ({...prev, lname: value(prev.lname)}));
        }}
        label="Body Section Title"
      />
      <CommonDropDown
        label={'Select Gender'}
        onChange={value => onChange('gender', value)}
        value={bodySection.gender}
        selectStyle={{width: '100%'}}
        data={genderdata}
        setData={'value'}
        showData={'name'}
        formControlStyle={{width: '100%', margin: '15px 0'}}
      />
      {showAlert && (
        <LocalAlert
          onClose={() => setShowAlert(false)}
          severity={'error'}
          alertText={'Please fill all the fields'}
        />
      )}
    </AddEditModal>
  );
};

export default BodySectionAddEditModal;

BodySectionAddEditModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  setData: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  bodySection: PropTypes.object.isRequired,
  setBodySection: PropTypes.func.isRequired,
};
