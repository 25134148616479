import {inputLabelClasses, TextField} from '@mui/material';
import React from 'react';
import Colors from '../Resources/COLORS/Colors';

const FloatinInputField = props => {
  const fieldId = props.helperText
    ? 'outlined-error-helper-text'
    : 'outlined-basic';

  return (
    <TextField
      id={fieldId}
      error={!!props.helperText}
      fullWidth
      InputProps={{
        endAdornment: props.endAdornment,
      }}
      variant="outlined"
      inputProps={{
        maxLength: props.maxLength,
        disabled: props.disabled,
        sx: {
          // if focused set color to appcircle
          fontWeight: '600',
          [`&:focus`]: {
            color: Colors.APPCIRCLE,
          },
          // if not focused set color to black
          [`&:not(:focus)`]: {
            color: Colors.BLACK,
          },
        },
      }}
      InputLabelProps={{
        inputMode: props.inputMode,
        sx: {
          color: Colors.BLACK,
          [`&.${inputLabelClasses.focused}`]: {
            color: Colors.APPCIRCLE,
          },
          marginTop: '18px',
        },
      }}
      {...props}
      autoComplete="off"
      onChange={e => props.onChange(e.target.value)}
      sx={{
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: Colors.BLACK,
          },
          '&:hover fieldset': {
            borderColor: Colors.SHADOWCOLOR,
          },
          '&.Mui-focused fieldset': {
            // add liner gradient to the border
            borderColor: Colors.APPCIRCLE,
          },
          marginTop: '18px',
        },
        ...props.sx,
      }}
    />
  );
};

export default FloatinInputField;
