import {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import AsyncThunks from '../redux/AsyncThunks';
import useLocalState from './useLocalState';

const useTableData = (onlyDepartment = false) => {
  const {
    SubspecialtiesSlice,
    SymptomQuestionnairesSlice,
    DepartmentSlice,
    SymptomsSlice,
  } = useSelector(state => state);
  const loading = useMemo(
    () =>
      SubspecialtiesSlice.isLoading ||
      SymptomQuestionnairesSlice.isLoading ||
      SymptomsSlice.isLoading,
    [
      SubspecialtiesSlice.isLoading,
      SymptomQuestionnairesSlice.isLoading,
      SymptomsSlice.isLoading,
    ],
  );
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  // these are the selected values from the dropdowns departmentId, subspecialistId, symptomId
  const [departmentId, setDepartmentId] = useLocalState('department-id', '');
  const [subspecialistId, setSubspecialistId] = useLocalState(
    'subspecialist-id',
    '',
  );
  const [symptomId, setSymptomId] = useLocalState('symptom-id', '');

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      console.log({AsyncThunks});
    }
  }, []);

  // these are the lists of departments, subspecialisties, symptoms, questions
  // which are fetched from the server and stored in the redux store
  // and indexed by the ids selected from the dropdowns
  const departments = DepartmentSlice.data;

  // these lists are wrapped in useMemo so that they are not re-computed on every render
  const subspecialisties = useMemo(() => {
    return SubspecialtiesSlice.data.filter(
      i => i.department_id === +departmentId,
    );
  }, [departmentId, SubspecialtiesSlice.data]);

  const symptoms = useMemo(() => {
    return SymptomsSlice.data.filter(
      i => i.subspecialty_id === +subspecialistId,
    );
  }, [subspecialistId, SymptomsSlice.data]);

  const questions = useMemo(() => {
    return SymptomQuestionnairesSlice.data.filter(
      i => i.symptom_id === +symptomId,
    );
  }, [symptomId, SymptomQuestionnairesSlice.data]);

  const setFirst = (set, data, id) => {
    if (data?.length > 0) {
      if (!data.find(item => item.id === id)) {
        const [first] = data;
        set(first.id);
      }
    }
  };

  const get = useMemo(() => {
    return {
      department: id => departments.find(item => item.id === +id),
      subspecialist: id => {
        const data = SubspecialtiesSlice.data;
        return data.find(item => item.id === +id);
      },
      symptom: id => {
        const data = SymptomsSlice.data;
        return data.find(item => item.id === +id);
      },
      question: id => {
        const data = SymptomQuestionnairesSlice.data;
        return data.find(item => item.id === +id);
      },
    };
  }, [
    departments,
    SubspecialtiesSlice.data,
    SymptomsSlice.data,
    SymptomQuestionnairesSlice.data,
  ]);

  // these are the useEffects which are used to fetch the data from the server whenever the ids changes for the dropdowns
  useEffect(() => {
    if (!onlyDepartment) {
      setFirst(setDepartmentId, departments, departmentId);
    }
  }, [departments, departmentId, setDepartmentId, onlyDepartment]);

  useEffect(() => {
    setFirst(setSubspecialistId, subspecialisties, subspecialistId);
  }, [subspecialisties, setSubspecialistId, subspecialistId]);

  useEffect(() => {
    setFirst(setSymptomId, symptoms, symptomId);
  }, [symptoms, setSymptomId, symptomId]);

  const getDepartments = useCallback(() => {
    return dispatch(AsyncThunks.getDepartments());
  }, [dispatch]);

  const getSubspecialties = useCallback(() => {
    return dispatch(
      AsyncThunks.getSubspecialties({department_id: departmentId}),
    );
  }, [dispatch, departmentId]);

  const getSymptoms = useCallback(() => {
    return dispatch(
      AsyncThunks.getSymptoms({
        department_id: departmentId,
        subspecialty_id: subspecialistId,
      }),
    );
  }, [departmentId, dispatch, subspecialistId]);

  const getSymptomQuestionnaires = useCallback(() => {
    return dispatch(
      AsyncThunks.getSymptomQuestionnaires({
        department_id: departmentId,
        subspecialty_id: subspecialistId,
        symptom_id: symptomId,
      }),
    );
  }, [departmentId, dispatch, subspecialistId, symptomId]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (departments?.length === 0) {
      getDepartments();
    }
  }, [departments?.length, getDepartments]);

  useEffect(() => {
    if (departmentId) {
      getSubspecialties();
    }
  }, [departmentId, getSubspecialties]);

  useEffect(() => {
    if (subspecialistId) {
      getSymptoms();
    }
  }, [subspecialistId, getSymptoms]);

  useEffect(() => {
    if (subspecialistId && departmentId) {
      getSymptomQuestionnaires();
    }
  }, [getSymptomQuestionnaires, subspecialistId, departmentId]);

  const dropDownProps = {
    departmentDropDown: {
      value: departmentId,
      onChange: setDepartmentId,
      data: departments,
      label: 'Department Name',
      labelId: 'Select Department',
      setData: 'id',
      showData: 'name',
    },
    subspecialtyDropDown: {
      value: subspecialistId,
      onChange: setSubspecialistId,
      data: subspecialisties,
      label: 'Subspecialty Name',
      labelId: 'Select Subspecialty',
      setData: 'id',
      showData: 'name',
    },
    symptomDropDown: {
      value: symptomId,
      onChange: setSymptomId,
      data: symptoms,
      label: 'Symptom Name',
      labelId: 'Select Symptom',
      setData: 'id',
      showData: 'name',
    },
  };

  return {
    isLoading,
    setIsLoading,
    departments,
    subspecialisties,
    symptoms,
    questions,
    departmentId,
    subspecialistId,
    symptomId,
    setDepartmentId,
    setSubspecialistId,
    setSymptomId,
    getSymptomQuestionnaires,
    getSubspecialties,
    getSymptoms,
    stopLoader: () => setIsLoading(false),
    getDepartments,
    departmentLoading: DepartmentSlice.isLoading,
    get,
    ...dropDownProps,
  };
};

export default useTableData;
