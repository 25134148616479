import React from 'react';
import {Box, Grid} from '@mui/material';
import {useNavigate} from 'react-router';
import {useTableData} from '../../hooks';
import {
  CommonButton,
  CommonDropDown,
  CommonTable,
  Header,
} from '../../commonComponents';

function Symptoms() {
  const table = useTableData();
  const navigate = useNavigate();

  const updateSymptom = symptomId => {
    navigate(`/symptoms/${symptomId}/edit`);
  };

  const showSymptoms = symptomId => {
    navigate(`/symptoms/${symptomId}`);
  };

  const tableHeaderData = [
    'Id',
    'Symptom System',
    'Symptom User',
    'Departments Name',
    'Subspecialties Name',
    'Created At',
    'Image',
  ];
  const dataKeys = [
    'id',
    'name',
    'sign',
    'department_name',
    'subspecialty_name',
    'created_at',
  ];

  return (
    <Box>
      <Header />
      <Box sx={{margin: 2}}>
        <Grid
          container
          spacing={{xs: 2, md: 3}}
          columns={{xs: 4, sm: 8, md: 12}}>
          <Grid item xs={2} sm={4} md={8}>
            <p style={{fontSize: 30, margin: '15px'}}>Symptoms :- </p>
          </Grid>
          <Grid display="flex" direction="row" item xs={2} sm={4} md={4}>
            <CommonDropDown
              formControlStyle={{width: '120%', margin: '15px'}}
              selectStyle={{width: '100%'}}
              {...table.departmentDropDown}
            />
            <CommonDropDown
              formControlStyle={{width: '120%', margin: '15px'}}
              selectStyle={{width: '100%'}}
              {...table.subspecialtyDropDown}
            />
          </Grid>
        </Grid>
        <CommonButton
          style={{width: '98.4%', backgroundColor: '#405672', marginTop: 2}}
          label="Create Symptom"
          onClick={() => navigate('/symptoms/new')}
        />
        <CommonTable
          data={table.symptoms}
          tableHeaderData={tableHeaderData}
          showImageKey="image_url"
          dataKeys={dataKeys}
          showDelete
          showTree
          showUpdate
          onPressUpdate={updateSymptom}
          onPressShow={showSymptoms}
          tableStyle={{width: '100%'}}
          showDragAndDrop
          apiType="symptom"
          isFetching={table.isLoading}
          emptyTitle="No Symptoms Found"
          refresh={table.getSymptoms}
        />
      </Box>
    </Box>
  );
}
export default Symptoms;
