import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  bodyParts: [],
};

export const counterSlice = createSlice({
  name: 'BodyPartsList',
  initialState,
  reducers: {
    setBodyParts: (state, action) => {
      state.bodyParts = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const BodyPartsActions = counterSlice.actions;

export default counterSlice.reducer;
