import React, {useCallback, useEffect, useState} from 'react';
import {
  LocalAlert,
  CommonTextInput,
  CommonDropDown,
  Header,
  MultipleSelectChip,
  CommonButton,
} from '../../commonComponents';
import {Box, Button, Modal} from '@mui/material';
import ApiClient from '../../networking/ApiManager';
import {useNavigate, useParams} from 'react-router-dom';
import LanguageDropdown from '../../commonComponents/LanguageDropdown';
import Loader from '../../commonComponents/Loader';
import Colors from '../../Resources/COLORS/Colors';
import {useDispatch} from 'react-redux';
import {useTableData} from '../../hooks';
import SymptomKeywordInput from './SymptomKeywordInput';
import AsyncThunks from '../../redux/AsyncThunks';
import BlackCrossIcon from '../../commonComponents/BlackCrossIcon';

function CreateSymptoms(props) {
  const navigate = useNavigate();
  const {id} = useParams();
  const table = useTableData();
  const [name, setName] = useState('');
  const [departmentList, setDepartmentList] = useState(null);
  const [departmentId, setDepartmentId] = useState('');
  const [departmentIdForClone, setDepartmentIdForClone] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertType, setAlertType] = useState('error');
  const [spacialList, setSpacialList] = useState('');
  const [spacialListForClone, setSpacialListForClone] = useState('');
  const [spacialistId, setSpacialistId] = useState('');
  const [spacialistIdForCLone, setSpacialistIdForCLone] = useState('');
  const [lSign, setLSign] = useState({});
  const [lSignShort, setLSignShort] = useState({});
  const [loading, setLoading] = useState(false);
  const [cloneModalOpen, setCloneModalOpen] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const label = {inputProps: {'aria-label': 'Checkbox demo'}};
  const [text, setText] = useState('');
  const [lHint, setLHint] = useState({});
  const [video, setVideo] = useState('');
  const [image, setImage] = useState('');
  const [serverImage, setServerImage] = useState('');
  const [serverVideo, setServerVideo] = useState('');
  const [audio, setAudio] = useState('');
  const [serverAudio, setServerAudio] = useState('');
  const [inputCategories, setInputCategories] = useState([]);
  const [outputCategoies, setOutputCategoies] = useState([]);
  const [ictCode, setIctCode] = useState('');
  const [selectedInputCategory, setSelectedInputCategory] =
    useState('InputCategory');
  const [selectedOutputCategory, setSelectedOutputCategory] =
    useState('OutputCategory');
  const {departments} = useTableData(true);
  const [isBtnDiasable, setIsBtnDisable] = useState(false);
  const isSmallDevive = window.innerWidth <= 768;

  useEffect(() => {
    setDepartmentList(departments);
  }, [departments]);

  useEffect(() => {
    getSymptomCategories();
    getSymptomCategory();
  }, [departmentId]);

  useEffect(() => {
    departmentId && getSpacialList(setSpacialList, departmentId);
  }, [departmentId]);

  useEffect(() => {
    departmentIdForClone &&
      getSpacialList(setSpacialListForClone, departmentIdForClone);
  }, [departmentIdForClone]);

  const getSingleSymptomsData = useCallback(() => {
    const symptom = table.get.symptom(id);
    if (!symptom) return;
    setSelectedOutputCategory(symptom.output_category_id);
    setSelectedInputCategory(symptom.input_category_id);
    setName(symptom.name || '');
    setServerImage(symptom.image_url || '');
    setIctCode(symptom.ict_code || '');
    setServerAudio(symptom.audio_url || '');
    setServerVideo(symptom.video_url || '');
    setDepartmentId(symptom.department_id || '');
    setSpacialistId(symptom.subspecialty_id || '');
    setLSign(symptom.lsign);
    setLSignShort(symptom.lsign_short);
    setLHint(symptom.lhint);
  }, [id, table.get]);

  useEffect(() => {
    if (id) {
      getSingleSymptomsData();
    }
  }, [id, getSingleSymptomsData]);

  const getSymptomCategories = () => {
    setLoading(true);
    ApiClient.getSymptomCategories(departmentId, selectedInputCategory)
      .then(res => {
        departmentId && setInputCategories(res.data.data);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
      });
  };

  const getSymptomCategory = () => {
    setLoading(true);
    ApiClient.getSymptomCategories(departmentId, selectedOutputCategory)
      .then(res => {
        departmentId && setOutputCategoies(res.data.data);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
      });
  };

  const transLateSymptom = () => {
    setLoading(true);
    ApiClient.transLateSymptom(id)

      .then(res => {
        setLoading(false);
        setAlertMessage(res.data.data);
        setAlertType('success');
        setShowAlert(true);
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    findText();
  }, []);

  const findText = text => {
    setText(text);
    const formData = {
      name: text,
    };
    ApiClient.searchBodyParts(formData)
      .then(res => {
        const parts = res.data.data.filter(item =>
          item.symptom_ids.includes(+id),
        );
        setSelectedIds([...parts]);
        setSearchData(res.data.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getSpacialList = (setState, id) => {
    setLoading(true);
    ApiClient.getSpacialList(id)

      .then(res => {
        setState(res.data.data);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
      });
  };

  const dispatch = useDispatch();
  const forFinal = func => {
    func
      .unwrap()
      .then(res => {
        navigate('/symptoms');
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const createSymptoms = params => {
    if (validateInput()) {
      forFinal(dispatch(AsyncThunks.createSymptom({params})));
    } else {
      setLoading(false);
      console.log('Someting went wrong.');
    }
  };

  const updateSymptoms = params => {
    if (validateInput()) {
      forFinal(dispatch(AsyncThunks.updateSymptom({params, id})));
    } else {
      setLoading(false);
      console.log('Someting went wrong.');
    }
  };

  const handleClone = () => {
    setIsBtnDisable(true);
    const params = {
      id: id,
      department_id: departmentIdForClone,
      subspecialty_id: spacialistIdForCLone,
    };

    setLoading(true);
    ApiClient.cloneSymptoms(params, id)
      .then(res => {
        setLoading(false);
        setAlertMessage(res.data.data);
        setAlertType('success');
        setShowAlert(true);
        setCloneModalOpen(false);
        setIsBtnDisable(false);
      })
      .catch(err => {
        console.log(err.response.data);
        setLoading(false);
        setAlertMessage('Failed to clone Symptom');
        setAlertType('error');
        setShowAlert(true);
        setIsBtnDisable(false);
      });
  };

  const handleOnClick = () => {
    let formData = new FormData();

    for (const key of Object.keys(lSign)) {
      formData.append('lsign[' + key + ']', lSign[key]);
    }

    for (const key of Object.keys(lSignShort)) {
      formData.append('lsign_short[' + key + ']', lSignShort[key]);
    }

    for (const key of Object.keys(lHint)) {
      formData.append('lhint[' + key + ']', lHint[key]);
    }

    image && formData.append('image', image);
    video && formData.append('video', video);
    audio && formData.append('audio', audio);
    formData.append('name', name.trim());
    formData.append('ict_code', ictCode.trim());
    formData.append('department_id', departmentId);
    formData.append('subspecialty_id', spacialistId);
    formData.append('input_category_id', selectedInputCategory);
    formData.append('output_category_id', selectedOutputCategory);
    formData.append('body_part_ids', selectedIds.map(i => i.id).toString());
    setLoading(true);
    if (id) {
      updateSymptoms(formData);
    } else {
      createSymptoms(formData);
    }
  };

  const validateInput = () => {
    if (!departmentId) {
      setShowAlert(true);
      setAlertMessage('Please provide department');
      setAlertType('error');
      return false;
    } else if (!spacialistId) {
      setShowAlert(true);
      setAlertMessage('Please provide Subspecialty');
      setAlertType('error');
      return false;
    } else if (!name) {
      setShowAlert(true);
      setAlertMessage('Please provide Symptom System');
      setAlertType('error');
      return false;
    } else if (
      Object.keys(lSign).length === 0 ||
      Object.values(lSign)[0] === ''
    ) {
      setShowAlert(true);
      setAlertMessage('Please provide Symptom User');
      setAlertType('error');
      return false;
    } else {
      return true;
    }
  };

  const showMedia = (state1, state2, type, alt, setState1, setState2) => {
    return (
      <>
        {(state1 || state2) && (
          <div style={{display: 'flex'}}>
            {state1 && (
              <>
                {type === 'image' && (
                  <img
                    alt={alt}
                    style={{maxWidth: '200px', maxHeight: '200px'}}
                    src={URL.createObjectURL(state1)}
                  />
                )}
                {type === 'video' && (
                  <video width="200" maxHeight="200" controls>
                    <source
                      src={URL.createObjectURL(state1)}
                      type="video/mp4"
                    />
                  </video>
                )}
                {type === 'audio' && (
                  <audio controls>
                    <source
                      src={URL.createObjectURL(state1)}
                      type="audio/mpeg"
                    />
                  </audio>
                )}
              </>
            )}
            {!state1 && state2 && (
              <>
                {type === 'image' && (
                  <img
                    alt={alt}
                    style={{maxWidth: '200px', maxHeight: '200px'}}
                    src={state2}
                  />
                )}
                {type === 'video' && (
                  <video width="200" maxHeight="200" controls>
                    <source src={state2} type="video/mp4" />
                  </video>
                )}
                {type === 'audio' && (
                  <audio controls>
                    <source src={state2} type="audio/mpeg" />
                  </audio>
                )}
              </>
            )}
          </div>
        )}
      </>
    );
  };

  const showCloneButton = (btnAction, style, boxStyle, bigBtn) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: bigBtn ? 'center' : 'flex-end',
          margin: '10px 20px',
          ...boxStyle,
        }}>
        <div
          onClick={isBtnDiasable ? null : btnAction}
          style={{
            background: Colors.APPCIRCLE,
            padding: isSmallDevive ? '5px 20px' : '5px 25px',
            borderRadius: '15px',
            color: Colors.WHITE,
            fontSize: isSmallDevive ? 'small' : 'large',
            fontWeight: '600',
            cursor: 'pointer',
            ...style,
            alignSelf: 'center',
            width: '100%',
            marginRight: bigBtn ? 0 : '17px',
            marginBottom: '50px',
          }}>
          Clone
        </div>
      </div>
    );
  };

  const cloneForm = () => {
    return (
      <div>
        {showFields(
          departmentIdForClone,
          spacialistIdForCLone,
          spacialListForClone,
          setDepartmentIdForClone,
          setSpacialistIdForCLone,
        )}
      </div>
    );
  };

  const showFields = (
    showState1,
    ShowState2,
    spacialListData,
    setState1,
    setState2,
  ) => {
    return (
      <>
        <CommonDropDown
          formControlStyle={{width: '100%'}}
          label="Department Name"
          labelId="Department_Name"
          onChange={setState1}
          value={showState1}
          selectStyle={{width: '100%'}}
          data={departmentList || []}
          setData={'id'}
          showData={'name'}
        />

        <CommonDropDown
          formControlStyle={{width: '100%', margin: '15px  0'}}
          label="Subspecialty"
          labelId="Subspecialty Name"
          onChange={setState2}
          value={ShowState2}
          selectStyle={{width: '100%'}}
          data={spacialListData || []}
          setData={'id'}
          showData={'name'}
        />

        <CommonDropDown
          formControlStyle={{width: '100%'}}
          label="Input category"
          labelId="InputCategory"
          onChange={setSelectedInputCategory}
          value={selectedInputCategory}
          selectStyle={{width: '100%'}}
          data={inputCategories || []}
          setData={'id'}
          showData={'name'}
        />

        <CommonDropDown
          formControlStyle={{width: '100%', margin: '15px 0'}}
          label="Output category"
          labelId="OutputCategory"
          onChange={setSelectedOutputCategory}
          value={selectedOutputCategory}
          selectStyle={{width: '100%'}}
          data={outputCategoies || []}
          setData={'id'}
          showData={'name'}
        />
      </>
    );
  };

  const cloneModal = () => {
    return (
      <Modal
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={cloneModalOpen}
        onClose={() => setCloneModalOpen(false)}
        aria-labelledby="Show Clone Data"
        aria-describedby="Clone Data">
        <Box sx={styles.containerBox}>
          <BlackCrossIcon
            onClick={() => setCloneModalOpen(false)}
            containerStyle={styles.containerStyle}
            style={styles.iconStyle}
          />
          {cloneForm()}
          {showCloneButton(
            handleClone,
            {textAlign: 'center'},
            {margin: '10px, 0px'},
            true,
          )}
        </Box>
      </Modal>
    );
  };

  const handleChange = item => {
    setSelectedIds(prev => {
      if (prev.some(i => i.id === item.id)) {
        return prev.filter(newItem => newItem.id !== item.id);
      } else {
        return [...prev, item];
      }
    });
  };

  return (
    <Box>
      <Header />
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <h2 style={{margin: '15px 0', marginLeft: '20px'}}>
          {' '}
          {id ? 'Update' : 'Create'} Symptom :-
        </h2>
        {id && (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                margin: '12px -20px -38px 0px',
              }}>
              <div
                onClick={transLateSymptom}
                style={{
                  background: Colors.APPCIRCLE,
                  padding: '5px 20px',
                  borderRadius: '15px',
                  color: Colors.WHITE,
                  fontSize: isSmallDevive ? 'small' : 'large',
                  fontWeight: '600',
                  cursor: 'pointer',
                  alignSelf: 'center',
                  marginBottom: '50px',
                }}>
                Translate
              </div>
              {showCloneButton(() => setCloneModalOpen(true))}
            </div>

            <style jsx>{`
              @media (max-width: 768px) {
                div {
                  font-size: 12px;
                }
              }
            `}</style>
          </>
        )}
      </div>

      <Box sx={{margin: 2}}>
        {showFields(
          departmentId,
          spacialistId,
          spacialList,
          setDepartmentId,
          setSpacialistId,
        )}
        <CommonTextInput
          maxLength={250}
          showLength={125}
          style={{width: '100%', marginBottom: '15px'}}
          value={name}
          label="Symptom System"
          onChange={setName}
        />
        <div style={{display: 'flex', flexDirection: 'column', gap: 13}}>
          <LanguageDropdown
            inputValue={lSign}
            onChange={setLSign}
            label="Symptom User"
          />
          <LanguageDropdown
            inputValue={lSignShort}
            onChange={setLSignShort}
            label="Symptom User Short"
          />
          <LanguageDropdown
            inputValue={lHint}
            onChange={setLHint}
            label="Hint"
          />
        </div>
        <MultipleSelectChip
          data={searchData}
          selectedValue={selectedIds}
          onClick={handleChange}
          onChipClick={handleChange}
          label="Body Parts"
          placeholder="Select Body Parts..."
        />
        <SymptomKeywordInput />
        <div style={{marginTop: 15}}>
          <CommonTextInput
            maxLength={250}
            showLength={125}
            style={{width: '100%', marginBottom: '15px'}}
            value={ictCode}
            label="Ict Code"
            onChange={setIctCode}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
          }}>
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}>
            <label for="file-input">
              <Button
                variant="contained"
                sx={{width: '100%', backgroundColor: '#405672', marginTop: 2}}
                component="span">
                Attach Image
              </Button>
            </label>
            <CommonTextInput
              id={'file-input'}
              type="file"
              style={{visibility: 'hidden'}}
              onChange={setImage}
              accept="image/*"
            />
            {showMedia(
              image,
              serverImage,
              'image',
              name,
              setImage,
              setServerImage,
            )}
          </div>

          <div
            style={{
              margin: '0 15px 15px 15px',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}>
            <label for="file-input-video">
              <Button
                variant="contained"
                sx={{width: '100%', backgroundColor: '#405672', marginTop: 2}}
                component="span">
                {'Add Video'}
              </Button>
            </label>

            <CommonTextInput
              id={'file-input-video'}
              type="file"
              style={{visibility: 'hidden'}}
              onChange={setVideo}
              accept="video/*"
            />
            {showMedia(
              video,
              serverVideo,
              'video',
              name,
              setVideo,
              setServerVideo,
            )}
          </div>

          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}>
            <label for="file-input-audio">
              <Button
                variant="contained"
                sx={{width: '100%', backgroundColor: '#405672', marginTop: 2}}
                component="span">
                Add Audio
              </Button>
            </label>
            <CommonTextInput
              id={'file-input-audio'}
              type="file"
              style={{visibility: 'hidden'}}
              onChange={setAudio}
              accept="audio/mp3,audio/*;capture=microphone"
            />
            {showMedia(
              audio,
              serverAudio,
              'audio',
              name,
              setAudio,
              setServerAudio,
            )}
          </div>
        </div>

        <CommonButton
          style={{backgroundColor: '#405672', marginTop: 2, width: '100%'}}
          component="span"
          label={'Save'}
          onClick={handleOnClick}
        />
      </Box>

      {showAlert && (
        <LocalAlert
          severity={alertType}
          alertText={alertMessage}
          onClose={() => setShowAlert(false)}
        />
      )}
      <Loader loading={loading} />
      {cloneModal()}
    </Box>
  );
}
export default CreateSymptoms;

const styles = {
  containerBox: {
    position: 'absolute',
    top: '50px',
    boxShadow: 24,
    backgroundColor: '#fff',
    overflow: 'auto',
    maxHeight: '80vh',
    p: 6,
    minWidth: '50%',
    height: '35%',
    paddingTop: '40px',
  },
  containerStyle: {
    height: 25,
    position: 'absolute',
    right: 4,
    top: 7,
  },
  iconStyle: {
    height: '15px',
    maxWidth: '17px',
    margin: '5px 8px',
  },
};
