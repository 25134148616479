import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router';
import {BodyPartHeader, BodyPartsSearchModal, styles} from '.';
import PropTypes from 'prop-types';
import {Arrow} from 'react-absolute-svg-arrows';
import ApiClient from '../../networking/ApiManager';
import Utility from '../../commonComponents/Utility';

const BodyModal = () => {
  const [bodyPartSections, setBodyPartSections] = useState([]);
  const [bodyImage, setBodyImage] = useState('');
  const [imageLayout, setImageLayout] = useState('');
  const partCords = useRef({x: 0, y: 0});
  const searchRef = useRef(null);
  const {id} = useParams();

  const getBodyPartSection = useCallback(() => {
    const params = {
      body_section_id: id,
    };
    ApiClient.getBodyPartSection(params)
      .then(res => {
        setBodyPartSections(res.data.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, [id]);

  const getsingleBodyPartsList = useCallback(() => {
    ApiClient.getsingleBodyPartsList(id)
      .then(res => {
        const url = res.data.data.image_url;
        const container = document.getElementById('body-part-container');
        Utility.getImageMeta(url, img => {
          const headrHeight =
            document.getElementById('body-part-header').offsetHeight + 20;
          container.style.width = `${img.width}px`;
          container.style.height = `${img.height}px`;
          container.style.display = 'flex';
          container.style.margin = 'auto';
          container.style.paddingTop = `${headrHeight}px`;

          setImageLayout({
            width: img.width,
            height: img.height,
          });
          setBodyImage(url);
        });
      })
      .catch(err => {
        console.log(err);
      });
  }, [id]);

  useEffect(() => {
    getBodyPartSection();
    getsingleBodyPartsList();
  }, [getBodyPartSection, getsingleBodyPartsList]);

  const onPartClick = useCallback(
    bodyPart => {
      const params = {
        body_part_id: bodyPart.id,
        position_x: partCords.current.x,
        position_y: partCords.current.y,
        body_section_id: id,
      };
      ApiClient.setPartPosition(params)
        .then(() => {
          getBodyPartSection();
          searchRef.current.close();
        })
        .catch(err => {
          console.log(err);
        });
    },
    [getBodyPartSection, id],
  );

  const bodyImageClick = useCallback(
    e => {
      const {offsetX, offsetY} = e.nativeEvent;
      const isLessThen = offsetX < imageLayout.width * 0.2;
      const isGreaterThen75 = offsetX > imageLayout.width * 0.8;
      if (isLessThen || isGreaterThen75) {
        alert(
          'Please select a point within 20 to 80% of the image or upload a new image',
        );
        return;
      }
      partCords.current.x = offsetX;
      partCords.current.y = offsetY;
      searchRef.current.show();
    },
    [imageLayout.width],
  );

  return (
    <div id="body-part-container">
      <BodyPartHeader
        bodyParts={bodyPartSections}
        setBodyParts={setBodyPartSections}
      />
      <BodyPartsSearchModal ref={searchRef} onPartClick={onPartClick} />
      <div style={styles.bodyImageContainer}>
        <div
          style={{
            width: imageLayout.width,
            height: imageLayout.height,
          }}>
          <img
            src={bodyImage}
            alt="body"
            style={{
              position: 'absolute',
              width: imageLayout.width,
              height: imageLayout.height,
            }}
          />
          <div
            onClick={bodyImageClick}
            style={{
              position: 'absolute',
              width: imageLayout.width,
              height: imageLayout.height,
            }}>
            <div style={styles.dashLine(imageLayout, true)} />
            <div style={styles.dashLine(imageLayout, false)} />
            {bodyPartSections.map((item, index) => (
              <BodyPart
                index={index}
                imgWidth={imageLayout.width}
                imgHeight={imageLayout.height}
                key={index}
                bodyPart={item}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BodyModal;

const BodyPart = ({bodyPart, imgWidth}) => {
  const isLeft = bodyPart.position_x < imgWidth / 2;
  const endPoints = {x: bodyPart.position_x, y: bodyPart.position_y};
  const startPoint = {x: bodyPart.position_x, y: bodyPart.position_y};
  const fontSize = 12;
  const spaceFromTip = 2;
  // Adjusting the x so the name ends at the tip of the arrow
  const adjustX = bodyPart.body_part_name.length * (fontSize / 1.8);
  if (isLeft) {
    startPoint.x = imgWidth * 0.12;
  } else {
    endPoints.x = imgWidth * 0.85;
  }

  return (
    <React.Fragment>
      <div style={{position: 'relative'}}>
        <div
          style={{
            fontSize,
            position: 'absolute',
            left: isLeft ? startPoint.x - adjustX : endPoints.x + spaceFromTip,
            top: bodyPart.position_y - 12,
          }}>
          {bodyPart.body_part_name}
        </div>
      </div>
      <Arrow
        config={{
          dotEndingRadius: 0,
          arrowHeadEndingSize: '0',
          arrowColor: '#000',
        }}
        tooltip={bodyPart.body_part_name}
        startPoint={startPoint}
        endPoint={endPoints}
      />
    </React.Fragment>
  );
};

BodyPart.propTypes = {
  bodyPart: PropTypes.object,
  imgWidth: PropTypes.number,
};
