import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  inputLabelClasses,
  FormHelperText,
} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import Colors from '../Resources/COLORS/Colors';

const FloatingLabelDropDown = (props, ref) => {
  const selectId = props.helperText
    ? 'demo-simple-select-error'
    : 'demo-simple-select';

  const labelId = props.helperText
    ? 'demo-simple-select-error-label'
    : 'demo-simple-select-label';

  const returnMapFunction = () => {
    return props.data.map(item => {
      const label = props.selectLabel ? item[props.selectLabel] : item.label;
      const value = props.selectValue ? item[props.selectValue] : item.value;
      return <MenuItem value={value}>{label}</MenuItem>;
    });
  };

  return (
    <FormControl
      error={!!props.helperText}
      sx={{
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: Colors.BLACK,
          },
          '&:hover fieldset': {
            borderColor: Colors.SHADOWCOLOR,
          },
          '&.Mui-focused fieldset': {
            // add liner gradient to the border
            borderColor: Colors.APPCIRCLE,
          },
          marginTop: '18px',
        },
        ...props.sx,
      }}
      fullWidth>
      <InputLabel
        aria-autocomplete="none"
        sx={{
          color: Colors.BLACK,
          [`&.${inputLabelClasses.focused}`]: {
            color: Colors.APPCIRCLE,
          },
          marginTop: '18px',
        }}
        id={labelId}>
        {props.label}
      </InputLabel>
      <Select
        labelId={labelId}
        inputRef={ref}
        inputProps={{
          sx: {
            fontWeight: '600',
            [`&:focus`]: {
              color: Colors.APPCIRCLE,
            },
            [`&:not(:focus)`]: {
              color: Colors.BLACK,
            },
          },
        }}
        id={selectId}
        {...props}
        MenuProps={{sx: {maxHeight: '300px'}}}
        value={props?.value?.toString()}
        label={props.label}
        onChange={e => props.onChange(e.target.value)}>
        {props.data && returnMapFunction()}
      </Select>
      {!!props.helperText && (
        <FormHelperText>{props.helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

export default React.forwardRef(FloatingLabelDropDown);

FloatingLabelDropDown.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  data: PropTypes.array,
  selectLabel: PropTypes.string,
  selectValue: PropTypes.string,
};
