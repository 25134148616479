import {AppBar, Button} from '@mui/material';
import React from 'react';
import {useLocation, useNavigate} from 'react-router';
import {styles} from '.';
import PropTypes from 'prop-types';
import ApiClient from '../../networking/ApiManager';
import {SearchBoxModal} from '../../commonComponents';

const BodyPartHeader = props => {
  const navigate = useNavigate();
  const location = useLocation();
  const editModalRef = React.useRef(null);

  const goTo = () => navigate(`/departments/${location.state.id}/edit`);

  const onRemove = part => {
    ApiClient.deleteBodyPartName(part.id)
      .then(() => {
        props.setBodyParts(prev => prev.filter(item => item.id !== part.id));
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <AppBar id="body-part-header" component="nav" sx={styles.appBar}>
      <Button
        onClick={goTo}
        variant="contained"
        sx={styles.leftBtn}
        component="span">
        <div style={{fontWeight: 'bold'}}>Back</div>
      </Button>
      <Button
        onClick={() => editModalRef.current.show()}
        variant="contained"
        sx={styles.leftBtn}
        component="span">
        <div style={{fontWeight: 'bold'}}>Edit Body Part</div>
      </Button>
      <SearchBoxModal
        ref={editModalRef}
        data={props.bodyParts}
        filterKeys={['body_part_name']}
        title="Edit Body Part"
        onRemoveClick={onRemove}
      />
    </AppBar>
  );
};

export default BodyPartHeader;

BodyPartHeader.propTypes = {
  bodyParts: PropTypes.array,
  setBodyParts: PropTypes.func,
};
