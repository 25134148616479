import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import ApiClient from '../../networking/ApiManager';
import {useNavigate} from 'react-router-dom';
import Utility from '../../commonComponents/Utility';

function ShowSymptomstree() {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const {id} = useParams();

  useEffect(() => {
    id && getSingleSymptomsData();
  }, [id]);

  const getSingleSymptomsData = () => {
    ApiClient.getSingleSymptoms(id)
      .then(res => {
        setData(res.data.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const renderQuestionBox = question => {
    return (
      <div
        style={{
          display: 'flex',
          fontSize: 'large',
          position: 'relative',
        }}>
        <div
          style={{
            textAlign: 'justify',
            padding: '5px 10px',
            margin: 'auto',
            maxWidth: '300px',
          }}>
          <div style={{fontWeight: 'bold'}}>{question.descriptor_code}</div>
          <div style={{textAlign: 'center'}}>
            {' '}
            {Utility.addQSId(question) + question.descriptor_sign}
          </div>
        </div>
      </div>
    );
  };

  const renderOptionBox = option => {
    return (
      <div
        style={{
          display: 'flex',
          fontSize: 'large',
          position: 'relative',
        }}>
        <div
          style={{
            textAlign: 'justify',
            padding: '5px 10px',
            maxWidth: '300px',
          }}>
          {Utility.addOPId(option) + option.option_label} ( {option.option_code}{' '}
          )
        </div>
      </div>
    );
  };

  const renderSymptoms = symptoms => {
    return (
      <div
        style={{
          display: 'flex',
          position: 'relative',
          justifyContent: 'center',
        }}>
        <div
          style={{
            textAlign: 'center',
            padding: '10px',
            fontSize: 'large',
            margin: 'auto',
          }}>
          <div style={{fontWeight: 'bold'}}>
            {Utility.addSYId(symptoms) + symptoms.name}
          </div>
          <div style={{textAlign: 'center'}}>{symptoms.sign}</div>
        </div>
      </div>
    );
  };

  const renderTree = data => {
    return (
      <ul>
        <li>
          <a>{data && renderQuestionBox(data)}</a>
          <ul>
            {data?.symptom_options &&
              data.symptom_options.map((option, index) => {
                return (
                  <li key={index}>
                    <a>{renderOptionBox(option)}</a>
                    {option.child_questionnaire &&
                      renderTree(option.child_questionnaire)}
                  </li>
                );
              })}
          </ul>
        </li>
      </ul>
    );
  };

  return (
    <div className="tree">
      <ul>
        <li>
          <a>{data && renderSymptoms(data)}</a>
          <ul className="rightMargin" style={{display: '-webkit-box'}}>
            {data?.symptom_questionnaires &&
              data.symptom_questionnaires.map((question, index) => {
                return <li key={index}>{renderTree(question)}</li>;
              })}
          </ul>
        </li>
      </ul>
    </div>
  );
}

export default ShowSymptomstree;
