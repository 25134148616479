import React from 'react';
import {CircularProgress, Backdrop} from '@mui/material';

export default function Loader(props) {
  return (
    <Backdrop
      sx={{color: '#7b04fe', zIndex: theme => theme.zIndex.drawer + 1}}
      open={props.loading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
