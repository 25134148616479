import {Box, Grid} from '@mui/material';
import React from 'react';
import {useNavigate} from 'react-router';
import useFollowUps from '../../hooks/SuperDoctor/useFollowUps';
import {CommonButton, CommonTable, Header} from '../../commonComponents';

const FollowUp = props => {
  const {followUpQuestions} = useFollowUps();
  const navigate = useNavigate();

  const updateFollowUpQuestionnary = id => {
    navigate(`/followups/${id}/edit`);
  };

  const tableHeaderData = ['Id', 'Descriptor System', 'Descriptor User', ''];
  const dataKeys = ['id', 'descriptor_code', 'descriptor_sign'];

  return (
    <Box>
      <Header />
      <Grid container spacing={{xs: 2, md: 3}} columns={{xs: 4, sm: 8, md: 12}}>
        <Grid item xs={2} sm={4} md={8}>
          <p style={{fontSize: 30, margin: '15px'}}>FollowUp :- </p>
        </Grid>
      </Grid>
      <CommonButton
        style={{width: '98.4%', backgroundColor: '#405672', marginTop: 2}}
        label="Create Follow Up"
        onClick={() => navigate('/followups/new')}
      />
      <CommonTable
        data={followUpQuestions}
        tableHeaderData={tableHeaderData}
        dataKeys={dataKeys}
        showImageKey={'image_url'}
        showUpdate
        showDelete
        hideToggle
        onPressUpdate={updateFollowUpQuestionnary}
        apiType="followUpQuestion"
        tableStyle={{width: '100%'}}
        tableContainerStyle={{marginTop: 3}}
        emptyTitle="No FollowUps Found"
      />
    </Box>
  );
};

export default FollowUp;
