import {combineReducers} from '@reduxjs/toolkit';
import SyChartDoctorSlices from './SychartDoctor';
import SuperDoctorSlices from './SuperDoctor';
import UserDataSlice from './UserDataSlice';
import ReceptionistSlices from './ReceptionistReducer';
import NurseSlices from './NurseReducers';
import PatietnListSlices from './PatientListReducer';
import LanguageSlice from './LanguageSlice';
const reducers = combineReducers({
  UserDataSlice,
  LanguageSlice,
  ...SyChartDoctorSlices,
  ...SyChartDoctorSlices,
  ...SuperDoctorSlices,
  ...ReceptionistSlices,
  ...NurseSlices,
  ...PatietnListSlices,
});

export default reducers;
