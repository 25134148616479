import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import ApiClient from '../../../networking/ApiManager';

const initialState = {
  data: [],
  query: '',
  isLoading: true,
  isRefreshing: false,
  status: 'idle',
  error: null,
  firstLoad: true,
};

export const getDoctors = createAsyncThunk(
  'DoctorsListSlice/getDoctors',
  async (query, thunkAPI) => {
    try {
      const response = await ApiClient.getDoctosList({query});
      return {query, data: response.data.data};
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

const sliceNameSlice = createSlice({
  name: 'DoctorsListSlice',
  initialState,
  reducers: {
    setSearchQuery: (state, action) => {
      if (!state.firstLoad) {
        state.isLoading = true;
        state.isRefreshing = true;
      }
      state.query = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getDoctors.pending, state => {
        state.isLoading = true;
      })
      .addCase(getDoctors.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isRefreshing = false;
        state.firstLoad = false;
        state.data = action.payload.data;
      })
      .addCase(getDoctors.rejected, (state, action) => {
        state.isLoading = false;
        state.isRefreshing = false;
        state.error = action.error.message;
      });
  },
});

export default sliceNameSlice.reducer;

export const DoctorsListActions = sliceNameSlice.actions;
