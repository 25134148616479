import Actions from '../redux/Actions';
import store from '../redux/Store';
import {Strings} from '../Resources';

class Utility {
  static isNurse = localStorage.getItem('role') === 'nurse';
  static isUae = window.location.hostname.split('.')[0] === 'uae';
  static isStaging = window.location.hostname.split('.')[0] === 'staging';
  static isAdmin = localStorage.getItem('user') === 'admin';
  static isUhid = localStorage.getItem('loginMode') === 'uhid';

  static getImageMeta(image, cb) {
    const img = new Image();
    img.src = image;
    img.onload = () => {
      cb(img);
    };
  }

  static firstUpperCase(str) {
    if (typeof str !== 'string') {
      return str;
    } else {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
  }

  static urlValidation = str => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlRegex.test(str);
  };

  static removeUnderScore(str) {
    if (Array.isArray(str)) {
      let formattedName = str.map(function (name) {
        return name.replace(/_/g, ' ');
      });
      return formattedName;
    } else {
      let formattedName = str.replace(/_/g, ' ');
      return formattedName;
    }
  }

  static formatDate(forFormat) {
    const date = new Date(forFormat);
    return date.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: 'numeric',
      hour12: true,
    });
  }

  static getInsurancePlanName(insaurance) {
    return (
      Strings.INSURANCE_DATA.find(
        item => item.insurance_plan === insaurance.insurance_plan,
      )?.name || ''
    );
  }

  static normlise(str) {
    if (typeof str !== 'string') {
      return str;
    } else {
      return str
        .replace(/([A-Z])/g, ' $1')
        .replace(/_/g, ' ')
        .toLowerCase()
        .replace(/ie/g, 'y')
        .trim();
    }
  }

  static successAlert(message) {
    store.dispatch(Actions.setAlertMessages({success: message}));
  }

  static errorAlert(message) {
    store.dispatch(Actions.setAlertMessages({error: message}));
  }

  static addQSId(item, key) {
    const id = key ? item[key] : item.id;
    return '[QS-' + id + '] ';
  }

  static addOPId(item, key) {
    const id = key ? item[key] : item.id;
    return '[OP-' + id + '] ';
  }

  static addSYId(item, key) {
    const id = key ? item[key] : item.id;
    return '[SY-' + id + '] ';
  }
}

export default Utility;
