import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import ListComponent from './ListComponent';
import Loader from './Loader';
import Utility from './Utility';

function VersionsTable(props) {
  const [loading, setLoading] = React.useState(false);

  const returnTableHeader = () => {
    return props.tableHeaderData.map((header, index) => {
      return (
        <TableCell key={index} align="center">
          {header}
        </TableCell>
      );
    });
  };

  const returnTableBody = (row, index) => {
    return (
      <TableRow key={row.id.toString()}>
        {props.dataKeys.map((key, index) => (
          <TableCell key={index} align="center">
            <div>
              {key === 'changeset' || key === 'object' ? (
                row['changeset'] &&
                row['changeset'].length > 0 &&
                row['changeset'].map(changeKey => {
                  const ver3 = {};
                  const jsonArr = changeKey?.json_attributes;
                  const ver1 = !!jsonArr && jsonArr[0];
                  const ver2 = !!jsonArr && jsonArr[1];

                  if (ver1 && ver2) {
                    for (const key in ver1) {
                      if (
                        ver1.hasOwnProperty(key) &&
                        ver2.hasOwnProperty(key) &&
                        ver1[key] !== ver2[key]
                      ) {
                        ver3[key] = ver2[key];
                      }
                    }
                  }
                  const versionKeys = Object.keys(ver3);
                  const versionValues = Object.values(ver3);
                  return (
                    <div
                      style={{
                        display: 'flex',
                      }}
                      key={changeKey}>
                      <strong>
                        {Utility.removeUnderScore(Object.keys(changeKey))} →{' '}
                      </strong>
                      {changeKey[Object.keys(changeKey)].map((e, p) => {
                        console.log('k', changeKey.updated_at);

                        return (
                          <div
                            key={p}
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                            }}>
                            {p === 0 ? (
                              <div
                                style={{
                                  color: 'red',
                                  display: 'flex',
                                }}>
                                {e === null
                                  ? 'null '
                                  : typeof e === 'object'
                                  ? JSON.stringify(versionKeys.toLocaleString())
                                  : changeKey.updated_at
                                  ? Utility.formatDate(e)
                                  : changeKey.created_at
                                  ? Utility.formatDate(e)
                                  : JSON.stringify(e)}
                                →
                              </div>
                            ) : (
                              <div
                                style={{
                                  color: 'green',
                                  display: 'flex',
                                }}>
                                {e === null
                                  ? 'null '
                                  : typeof e === 'object'
                                  ? JSON.stringify(
                                      versionValues.toLocaleString(),
                                    )
                                  : changeKey.updated_at
                                  ? Utility.formatDate(e)
                                  : changeKey.created_at
                                  ? Utility.formatDate(e)
                                  : JSON.stringify(e)}
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  );
                })
              ) : key === 'whodunnit' ? (
                <div>
                  {row['whodunnit'] === null ? (
                    <div>null</div>
                  ) : row['whodunnit'] &&
                    typeof row['whodunnit'] === 'object' ? (
                    Object.entries(row['whodunnit']).map(
                      ([changeKey, changeValue]) => (
                        <div
                          style={{
                            display: 'flex',
                          }}
                          key={changeKey}>
                          <strong>
                            {Utility.removeUnderScore(
                              changeKey.charAt(0).toUpperCase() +
                                changeKey.slice(1),
                            )}{' '}
                            →{' '}
                          </strong>
                          {typeof changeValue === 'object'
                            ? JSON.stringify(changeValue)
                            : changeValue}
                        </div>
                      ),
                    )
                  ) : row['whodunnit'] && row['whodunnit'].length > 0 ? (
                    row['whodunnit'].map(changeKey => {
                      return (
                        <div
                          style={{display: 'flex'}}
                          key={Object.keys(changeKey)}>
                          <strong>{Object.keys(changeKey)} → </strong>
                          {Object.values(changeKey).map(value => (
                            <div key={value}>
                              {typeof value === 'object'
                                ? JSON.stringify(value)
                                : value}
                            </div>
                          ))}
                        </div>
                      );
                    })
                  ) : (
                    <div>Whodunnit is not an object or has length 0</div>
                  )}
                </div>
              ) : key === 'created_at' ? (
                Utility.formatDate(row.created_at)
              ) : (
                row[key]
              )}
            </div>
          </TableCell>
        ))}
      </TableRow>
    );
  };

  return (
    <TableContainer
      elevation={4}
      sx={[{marginTop: 2}, props.tableContainerStyle]}
      component={Paper}>
      <Table sx={props.tableStyle}>
        <TableHead>
          <TableRow>{returnTableHeader()}</TableRow>
        </TableHead>
        <TableBody>
          <ListComponent
            freeStyle
            renderItem={returnTableBody}
            {...props}
            data={props.data}
          />
        </TableBody>
      </Table>
      <Loader loading={loading} />
    </TableContainer>
  );
}

export default VersionsTable;
