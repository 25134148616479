import React, {useMemo} from 'react';
import {useUserData} from '../../commonComponents';
import {
  doctorFamaleDefault,
  doctorMaleDefault,
  otherDefault,
} from '../../Resources/Images';

const DoctorImage = props => {
  const {userData} = useUserData();

  const defaultUrl = useMemo(() => {
    const gender = userData?.gender?.toLowerCase() || '';
    switch (gender) {
      case 'male':
        return doctorMaleDefault;
      case 'female':
        return doctorFamaleDefault;
      default:
        return otherDefault;
    }
  }, [userData.gender]);

  const imgSrc = useMemo(() => {
    if (userData.image_url) {
      return userData.image_url;
    } else {
      return defaultUrl;
    }
  }, [userData.image_url, defaultUrl]);

  return (
    <img
      src={imgSrc}
      className={props.className}
      alt="patient"
      onError={e => {
        e.target.onerror = null;
        e.target.src = defaultUrl;
      }}
    />
  );
};

export default DoctorImage;
