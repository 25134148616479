import React, {Suspense} from 'react';
import Routes from './routes/index';
import './fireBase/FirebaseSetup';
import store from './redux/Store';
import {Provider} from 'react-redux';
import './i18n.js';
import GlobalAlerts from './otherComponents/GlobalAlerts';

export default function DocsyApp(props) {
  return (
    <Provider store={store}>
      <Suspense fallback="loading">
        <GlobalAlerts />
        <Routes />
      </Suspense>
    </Provider>
  );
}
