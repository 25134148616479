import {initializeApp} from 'firebase/app';
import {getToken, getMessaging, onMessage} from 'firebase/messaging';

const VAPID_KEY =
  'BEIzg5XGbHGPDqwkyjucdSxnad-p9hpxcJTOV9NyUdLZNWSpmJ1AiLYRM2I2wuREGkfG5_BSq7wgHvXKL5ELCUQ';

const firebaseConfig = {
  apiKey: 'AIzaSyDCtrWlfciEdF8ILJ33fXoukb-Iw44X988',
  authDomain: 'docsy-18843.firebaseapp.com',
  projectId: 'docsy-18843',
  storageBucket: 'docsy-18843.appspot.com',
  messagingSenderId: '597361424389',
  appId: '1:597361424389:web:0b6cc3f999c2e6dca5c46c',
  measurementId: 'G-GH6Y0R5L8L',
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const getOrRegisterServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    return window.navigator.serviceWorker
      .getRegistration('/firebase-push-notification-scope')
      .then(serviceWorker => {
        if (serviceWorker) return serviceWorker;
        return window.navigator.serviceWorker.register(
          '/firebase-messaging-sw.js',
          {
            scope: '/firebase-push-notification-scope',
          },
        );
      });
  }
  throw new Error('The browser doesn`t support service worker.');
};

export const getFirebaseToken = () =>
  getOrRegisterServiceWorker().then(serviceWorkerRegistration =>
    getToken(messaging, {
      vapidKey: VAPID_KEY,
      serviceWorkerRegistration,
    }),
  );
