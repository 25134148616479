import React from 'react';
import ApiClient from '../networking/ApiManager';

function useCallApi() {
  const apiCall = (path, id, callBack, handleError) => {
    ApiClient[path](id)
      .then(res => {
        callBack(res.data.data);
      })
      .catch(err => {
        handleError(err);
      });
  };

  const postApiCall = (
    path,
    data,
    callBack,
    handleError,
    setShowAlert,
    setAlertType,
    setAlertMessage,
  ) => {
    ApiClient[path](data)
      .then(res => {
        callBack(res.data.data);
      })
      .catch(err => {
        handleError(err);
      });
  };

  const putApiCall = (path, data, callBack, handleError, id) => {
    ApiClient[path](data, id)
      .then(res => {
        callBack(res.data.data);
      })
      .catch(err => {
        handleError(err);
      });
  };

  const deleteApiCall = (path, id, callBack, handleError) => {
    ApiClient[path](id)
      .then(res => {
        callBack(res.data.data);
      })
      .catch(err => {
        handleError(err);
      });
  };

  return {apiCall, postApiCall, putApiCall, deleteApiCall};
}

export default useCallApi;
