import {Box} from '@mui/system';
import {Modal} from '@mui/material';
import React, {useEffect, useState} from 'react';
import ApiClient from '../networking/ApiManager';
import CommonDropDown from './CommonDropDown';
import Colors from '../Resources/COLORS/Colors';
import BlackCrossIcon from './BlackCrossIcon';

const CloneModal = props => {
  const [departmentIdForClone, setDepartmentIdForClone] = useState('');
  const [spacialistIdForCLone, setSpacialistIdForCLone] = useState('');
  const [symptomIdForClone, setsymptomIdForClone] = useState('');
  const [symptoms, setSymptoms] = useState([]);
  const [subspecialty, setSubspecialty] = useState([]);

  const getSpacialList = id => {
    ApiClient.getSpacialList(id)
      .then(res => {
        setSubspecialty(res.data.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getSymptoms = id => {
    ApiClient.getSymptoms(id)
      .then(res => {
        setSymptoms(res.data.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (!props.open) {
      setSymptoms([]);
      setSubspecialty([]);
      setDepartmentIdForClone('');
      setSpacialistIdForCLone('');
      setsymptomIdForClone('');
    }
  }, [props.open]);

  const onPress = () => {
    props.handleClone(
      departmentIdForClone,
      spacialistIdForCLone,
      symptomIdForClone,
    );
  };

  return (
    <Modal
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="Show Clone Data"
      aria-describedby="Clone Data">
      <Box sx={styles.containerBox}>
        <BlackCrossIcon
          onClick={props.onClose}
          containerStyle={styles.containerStyle}
          style={styles.iconStyle}
        />
        <CommonDropDown
          formControlStyle={{width: '100%'}}
          label="Department Name"
          labelId="Department_Name"
          onChange={e => {
            setDepartmentIdForClone(e);
            getSpacialList(e);
          }}
          value={departmentIdForClone}
          selectStyle={{width: '100%'}}
          data={props.departmentsList || []}
          setData={'id'}
          showData={'name'}
        />

        <CommonDropDown
          formControlStyle={{width: '100%', margin: '15px  0'}}
          label="Subspecialty"
          labelId="Subspecialty Name"
          onChange={e => {
            setSpacialistIdForCLone(e);
            getSymptoms(e);
          }}
          value={spacialistIdForCLone}
          selectStyle={{width: '100%'}}
          data={subspecialty || []}
          setData={'id'}
          showData={'name'}
        />
        <CommonDropDown
          formControlStyle={{
            width: '100%',
            marginTop: '2px',
            marginBottom: '15px',
          }}
          label="Symptom"
          labelId="Symptom_Name"
          onChange={e => setsymptomIdForClone(e)}
          value={symptomIdForClone}
          selectStyle={{width: '100%'}}
          data={symptoms || []}
          setData={'id'}
          showData={'name'}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            margin: '15px, 0px',
          }}>
          <div
            onClick={props.isButtonDisable ? null : onPress}
            style={{
              background: Colors.APPCIRCLE,
              padding: '5px 25px',
              borderRadius: '15px',
              color: Colors.WHITE,
              fontSize: 'large',
              fontWeight: '600',
              cursor: 'pointer',
              width: '100%',
              textAlign: 'center',
            }}>
            Clone
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default CloneModal;

const styles = {
  containerBox: {
    position: 'absolute',
    top: '50px',
    boxShadow: 24,
    backgroundColor: '#fff',
    overflow: 'auto',
    maxHeight: '80vh',
    p: 6,
    minWidth: '50%',
  },
  containerStyle: {
    height: 25,
    position: 'absolute',
    right: 8,
    top: 8,
  },
  iconStyle: {
    height: '14px',
    maxWidth: '17px',
    margin: '5px 8px',
  },
};
