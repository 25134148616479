import React, {useEffect} from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes as BrowseRoutes,
} from 'react-router-dom';


import {getAuth, signOut} from 'firebase/auth';

import Utility from '../commonComponents/Utility';
import SuperAdminRoutes from './SuperAdminRoutes';
import { useDispatch } from 'react-redux';
import AsyncThunks from '../redux/AsyncThunks';
import { LogInScene } from '../SuperDoctor/login';
import Welcome from '../SuperDoctor/login/Welcome';

const Routes = () => {
  let sessionUser = localStorage.getItem('user');
  const dispatch = useDispatch();

  useEffect(() => {
    getQueryStringValue();
    dispatch(AsyncThunks.getLanguage(''));
  }, []);

  const logOutSessionUser = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {})
      .catch(error => {
        console.log(error);
      });
  };

  const getQueryStringValue = () => {
    const urlString = window.location.href;
    const url = new URL(urlString);
    const departmentCode = url.searchParams.get('department_code');
    if (departmentCode) {
      localStorage.setItem('departmentCode', departmentCode);
    }
  };

  const handleLogout = () => {
    const urlString = window.location.href;
    const url = new URL(urlString);
    const isLogout = url.searchParams.get('logout');
    if (isLogout) {
      // clear params
      localStorage.clear();
      window.history.replaceState({}, document.title, Utility.rootPath);
      window.location.pathname = Utility.rootPath;
      logOutSessionUser();
    } else {
      navigateToLogin();
    }
  };

  useEffect(() => {
    handleLogout();
  }, [localStorage, window.location.pathname]);

  const navigateToLogin = () => {
    if (
      !sessionUser &&
      window.location.pathname !== '/login' &&
      window.location.pathname !== '/'
    ) {
      document.title = 'MyDocSy';
      window.location.pathname = '/';
    } else if(window.location.pathname === '/download_link') {
      document.title = 'MyDocSy';
      window.location.pathname = Utility.rootPath;
    }
  };


  if (sessionUser === 'doctor') {
    return <SuperAdminRoutes />;
  } else {
    return (
      <Router>
        <BrowseRoutes>
        <Route path="/" exact element={<Welcome />} />
          <Route path="/login" element={<LogInScene />} />
        </BrowseRoutes>
      </Router>
    );
  }
};

export default Routes;
