import React, {useState} from 'react';
import PropTypes from 'prop-types';
import ApiClient from '../../networking/ApiManager';
import {AddEditModal, LocalAlert} from '../../commonComponents';
import LanguageDropdown from '../../commonComponents/LanguageDropdown';

const BodyPartAddEditModal = props => {
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertType, setAlertType] = useState('error');
  const [showAlert, setShowAlert] = useState(false);

  const validateInput = () => {
    if (
      Object.keys(props.lSign).length === 0 ||
      Object.values(props.lSign)[0] === ''
    ) {
      setShowAlert(true);
      setAlertType('error');
      setAlertMessage('Please fill body part tittle');
      return false;
    } else {
      return true;
    }
  };

  const createBodyPart = () => {
    if (validateInput()) {
      props.setLoading(true);
      ApiClient.createBodyPart({
        lname: props.lSign,
        ldescription: props.ldescription,
        lprompt: props.lprompt,
      })
        .then(res => {
          props.close(false);
          props.setLoading(false);
          props.setData(prevState => [...prevState, res.data.data]);
          window.scrollTo(0, document.body.scrollHeight);
        })
        .catch(error => {
          props.setLoading(false);

          console.warn(error);
        });
    } else {
      console.log('something went wrong');
    }
  };

  const updateBodyPart = () => {
    if (validateInput()) {
      props.setLoading(true);
      ApiClient.updateBodyPart(props.selectedId, {
        lname: props.lSign,
        ldescription: props.ldescription,
        lprompt: props.lprompt,
      })
        .then(res => {
          props.close(false);
          props.setLoading(false);
          props.setData(prev => {
            return prev.map(item => {
              if (item.id === props.selectedId) {
                return res.data.data;
              }
              return item;
            });
          });
        })
        .catch(error => {
          props.setLoading(false);
          console.log(error);
        });
    } else {
      console.log('something went wrong');
    }
  };

  return (
    <>
      <AddEditModal
        visible={props.visible}
        close={() => props.close(false)}
        onClick={props.selectedId ? updateBodyPart : createBodyPart}
        buttonText={'save'}>
        <LanguageDropdown
          inputValue={props.lSign}
          onChange={value => {
            props.setLSign(value);
          }}
          label="Body Part Title"
        />
        <div style={{margin: '15px 0px', width: '100%'}}>
          <LanguageDropdown
            inputValue={props.ldescription}
            onChange={value => {
              props.setLDescription(value);
            }}
            label="Body Part Description"
          />
        </div>
        <LanguageDropdown
          inputValue={props.lprompt}
          onChange={value => {
            props.setLprompt(value);
          }}
          label="Body Part Prompt"
        />
      </AddEditModal>
      {showAlert && (
        <LocalAlert
          severity={alertType}
          alertText={alertMessage}
          onClose={() => setShowAlert(false)}
        />
      )}
    </>
  );
};

export default BodyPartAddEditModal;

BodyPartAddEditModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  setData: PropTypes.func.isRequired,
  lSign: PropTypes.string.isRequired,
  lprompt: PropTypes.string.isRequired,
  ldescription: PropTypes.string.isRequired,
  setLSign: PropTypes.func.isRequired,
  setLDescription: PropTypes.func.isRequired,
  selectedId: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  setLprompt: PropTypes.func.isRequired,
};
