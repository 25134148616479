import React, {useState, useEffect} from 'react';
import {Box} from '@mui/material';
import {useNavigate} from 'react-router';
import {
  CommonButton,
  CommonTable,
  Header,
  Loader,
  LocalAlert,
} from '../../commonComponents';
import ApiClient from '../../networking/ApiManager';
import LanguageDropdown from '../../commonComponents/LanguageDropdown';

function CreateActivity() {
  const [activity, setActivity] = useState('');
  const [data, setData] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertType, setAlertType] = useState('error');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const isActivityEmpty =
    !activity || Object.values(activity).some(entry => entry === '');

  const refreshData = React.useCallback(() => {
    setLoading(true);
    ApiClient.getActivities()
      .then(res => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err.response.data);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    refreshData();
  }, [refreshData]);

  const showForm = (action, text) => {
    return (
      <Box>
        <Header />
        <Box sx={{margin: 2}}>
          <h2 style={{margin: '15px 0'}}>{text}</h2>
          <LanguageDropdown
            inputValue={activity}
            onChange={setActivity}
            label="Activity"
          />
          <CommonButton
            style={{
              width: '100%',
              backgroundColor: '#405672',
              marginTop: '2%',
            }}
            label="Create Activity"
            onClick={createActivity}
          />
        </Box>
      </Box>
    );
  };

  const createActivity = () => {
    const formData = new FormData();
    for (const key of Object.keys(activity)) {
      formData.append('lname[' + key + ']', activity[key]);
    }
    if (!isActivityEmpty) {
      setLoading(true);
      ApiClient.createActivity(formData)
        .then(res => {
          setShowAlert(true);
          setAlertMessage('Activity Created Successfully');
          setAlertType('success');
          setActivity('');
          refreshData();
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          setShowAlert(true);
          setAlertType('error');
        });
    } else {
      setShowAlert(true);
      setAlertMessage('Please Enter Activity');
      setAlertType('error');
    }
  };

  const updateActivity = (rawId, value) => {
    navigate(`/activities/${rawId}/edit`, {
      state: {edit: rawId, value},
    });
    refreshData();
  };

  const tableHeaderData = ['Id', 'Name', 'Created At', 'Updated At'];
  const dataKeys = ['id', 'name', 'created_at', 'updated_at'];

  const showActivityData = () => {
    return (
      <CommonTable
        showLabel="default"
        labelFor="locale"
        labelStyle={{
          fontSize: '10px',
          marginLeft: '5px',
          display: 'inline-block',
          padding: '3px',
          borderRadius: '5px',
          backgroundColor: '#405672',
          color: '#fff',
          alignSelf: 'center',
          verticleAlign: 'middle',
        }}
        hideToggle
        languageToggle
        data={data}
        tableHeaderData={tableHeaderData}
        dataKeys={dataKeys}
        showUpdate
        showDelete
        onPressDelete={deleteActivity}
        onPressUpdate={updateActivity}
        tableStyle={{width: '100%'}}
        emptyTitle="No Activity Found"
      />
    );
  };

  const deleteActivity = id => {
    const confirnDelete = window.confirm(
      'Are you sure you want to delete this Activity?',
    );
    if (confirnDelete) {
      setLoading(true);
      ApiClient.deleteActivity(id)
        .then(res => {
          setShowAlert(true);
          setAlertMessage('Activity Deleted Successfully');
          setAlertType('success');
          refreshData();
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          setShowAlert(true);
          setAlertMessage('Error Deleting Activity');
          setAlertType('error');
        });
    }
  };

  return (
    <>
      {showForm('', 'Activities :-')}
      {showAlert && (
        <LocalAlert
          alertText={alertMessage}
          onClose={() => setShowAlert(false)}
          severity={alertType}
        />
      )}
      {data && showActivityData()}
      <Loader loading={loading} />
    </>
  );
}

export default CreateActivity;
