import {Box, Button} from '@mui/material';
import React, {useCallback, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router';
import debounce from 'lodash/debounce';
import ApiClient from '../../networking/ApiManager';
import {CommonTextInput, Header, LocalAlert} from '../../commonComponents';
import Colors from '../../Resources/COLORS/Colors';

const UpdateTaskSet = () => {
  const {id} = useParams();
  const navigate = useNavigate();

  const location = useLocation();
  const {taskSetValue} = location.state || {};

  const [taskSetValues, setTaskSetValues] = useState(taskSetValue || '');

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertType, setAlertType] = useState('error');

  const updatetaskSet = useCallback(() => {
    const params = {
      name: taskSetValues,
    };
    setTaskSetValues('');

    ApiClient.updateTaskSet(params, id)
      .then(response => {
        setShowAlert(true);
        setAlertMessage('Task Set Updated Successfully');
        setAlertType('success');
        setTimeout(() => {
          navigate(-1);
        }, 500);
      })
      .catch(error => {
        console.log(error);
        setShowAlert(true);
        setAlertMessage('Please Enter Task Set');
        setAlertType('error');
      });
  }, [taskSetValues, id, navigate]);

  const handleChange = debounce((newValue, setterFunction) => {
    setterFunction(newValue);
  }, 0);

  return (
    <div>
      <Box>
        <Header />
        <Box sx={{margin: 2, overflowY: 'auto'}}>
          {id && (
            <div style={{display: 'flex'}}>
              <h2 style={{margin: '15px 0'}}>{'Update Task Set:-'}</h2>
              <Button
                variant="contained"
                onClick={() => navigate(`/task/${id}`)}
                sx={{
                  width: '8%',
                  backgroundColor: Colors.APPCIRCLE,
                  position: 'absolute',
                  right: '4%',
                  marginTop: '0.3%',
                  height: '5.5%',
                  fontSize: '16px',
                }}
                component="span">
                Task List
              </Button>
            </div>
          )}

          <CommonTextInput
            maxLength={100}
            label="Task Set"
            value={taskSetValues}
            onChange={e => handleChange(e, setTaskSetValues)}
            style={{m: 1, width: '100%'}}
          />
          <div
            style={{
              height: 40,
              padding: 20,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Button
              variant="contained"
              sx={{
                width: '98%',
                backgroundColor: '#405672',
                marginTop: 2,
                position: 'absolute',
              }}
              onClick={updatetaskSet}>
              Update Task Set
            </Button>
          </div>
        </Box>
      </Box>
      {showAlert && (
        <LocalAlert
          alertText={alertMessage}
          onClose={() => setShowAlert(false)}
          severity={alertType}
        />
      )}
    </div>
  );
};

export default UpdateTaskSet;
