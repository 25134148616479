import React from 'react';
import PropTypes from 'prop-types';
import './commonbutton.css';

export const CommonButton = props => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <button
        {...props}
        disabled={props.loading}
        onClick={props.onClick}
        className={`commonbtn commonbutton ${
          props.loading ? 'commonbutton--loading' : ''
        } pointer`}>
        <span className="commonbutton__text">{props.label}</span>
      </button>
    </div>
  );
};

export default CommonButton;

CommonButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool,
};
