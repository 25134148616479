import {Grid} from '@mui/material';
import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import BodyPartAddEditModal from './BodyPartAddEditModal';
import Actions from '../../redux/Actions';
import ApiClient from '../../networking/ApiManager';
import {
  CommonButton,
  CommonTable,
  Header,
  Loader,
} from '../../commonComponents';

const BodyPartList = () => {
  const data = useSelector(state => state.BodyPartsSlice.bodyParts);
  const [bodyPartLsign, setBodyPartLsign] = React.useState({});
  const [bodyPartLDescription, setBodyPartLDescription] = React.useState({});
  const [bodyPartPrompt, setBodyPartPrompt] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [addEditModal, setAddEditModal] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const setData = useCallback(
    param => {
      const isCb = typeof param === 'function';
      dispatch(Actions.setBodyParts(isCb ? param(data) : param));
    },
    [dispatch, data],
  );

  const getBodyParts = useCallback(() => {
    ApiClient.searchBodyParts()
      .then(response => {
        setData(response.data.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, [setData]);

  useEffect(() => {
    if (data.length === 0) {
      getBodyParts();
    }
  }, [getBodyParts, data.length]);

  const tableHeaderData = ['Id', 'Name', 'Description'];
  const dataKeys = ['id', 'name', 'description'];

  const onPressUpdate = (id, item) => {
    setSelectedId(id);
    setBodyPartLsign(item.lname);
    setBodyPartLDescription(item.ldescription);
    setAddEditModal(true);
    setBodyPartPrompt(item.lprompt);
  };

  const onPressDelete = id => {
    setLoading(true);
    const isFinal = window.confirm(
      'Are you sure you want to delete this body part?',
    );
    if (!isFinal) {
      return;
    }
    ApiClient.deleteBodyPart(id)
      .then(() => {
        setLoading(false);
        setData(prevState => prevState.filter(data => data.id !== id));
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  };

  const modalClose = () => {
    setAddEditModal(false);
    setBodyPartLsign({});
    setBodyPartLDescription({});
    setSelectedId('');
    setBodyPartPrompt({});
  };

  const onShowClick = id => {
    navigate(`/bodyparts/${id}/symptoms`);
  };

  return (
    <div>
      <Header />
      <Grid item xs={2} sm={4} md={8}>
        <p style={{fontSize: 30, margin: '15px'}}>Body Parts :- </p>
      </Grid>
      <CommonButton
        style={{width: '98.4%', backgroundColor: '#405672', marginTop: 2}}
        label="Create Body Part"
        onClick={() => setAddEditModal(true)}
      />
      <CommonTable
        data={data}
        tableHeaderData={tableHeaderData}
        dataKeys={dataKeys}
        showUpdate
        showDelete
        onShowClick={onShowClick}
        onPressDelete={onPressDelete}
        onPressUpdate={onPressUpdate}
        tableStyle={{width: '100%'}}
        emptyTitle="No Body Parts Found"
      />
      <BodyPartAddEditModal
        setLoading={setLoading}
        visible={addEditModal}
        close={modalClose}
        lSign={bodyPartLsign}
        ldescription={bodyPartLDescription}
        lprompt={bodyPartPrompt}
        selectedId={selectedId}
        setLSign={setBodyPartLsign}
        setData={setData}
        setLDescription={setBodyPartLDescription}
        setLprompt={setBodyPartPrompt}
      />
      <Loader loading={loading} />
    </div>
  );
};

export default BodyPartList;
