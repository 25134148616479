import {createSlice} from '@reduxjs/toolkit';
import {DefaultSlice} from './DefaultSlice';

const type = 'followUpQuestions';
const slice = new DefaultSlice(type);
slice.assignThunkNames(type);
const sliceName = 'FollowUpQuestionsSlice';
const thunks = slice.getThunks(sliceName);

const FollowUpQuestionsThunks = thunks.reduce((acc, current, index) => {
  const thunkName = slice.thunkNames[index];
  return {...acc, [thunkName]: current.create};
}, {});

export const FollowUpQuestionsSlice = createSlice({
  name: sliceName,
  initialState: slice.initialState,
  extraReducers(builder) {
    slice.extraReducers(slice.asyncThunks, builder);
  },
});

// Action creators are generated for each case reducer function
export const FollowUpQuestionsActions = FollowUpQuestionsSlice.actions;
export {FollowUpQuestionsThunks};

export default FollowUpQuestionsSlice.reducer;
