import React, {useState, useEffect} from 'react';
import {
  CommonDropDown,
  CommonTable,
  Header,
  Loader,
  LocalAlert,
} from '../../commonComponents';
import {Button} from '@mui/material';
import ApiClient from '../../networking/ApiManager';
import {useNavigate, useParams} from 'react-router';
import Colors from '../../Resources/COLORS/Colors';

const TaskList = () => {
  const {id} = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [patientId, setPatientId] = useState(null);
  const [alertType, setAlertType] = useState('error');
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [severity, setSeverity] = useState(
    localStorage.getItem('selectedSeverity') || '',
  );

  const tableHeaderData = [
    'Id',
    'Subspecialty Category',
    'Activity',
    'Subtitle',
    'Severity',
    'Advise',
    'Action Url',
    'Notification',
    'Activate/Deactivate',
    'Image',
  ];
  const dataKeys = [
    'id',
    'subspecialty_category',
    'activity_name',
    'subtitle',
    'severity',
    'advise',
    'action_url',
    'notification',
    'deactivated_at',
  ];

  const severityList = [
    {name: 'Minimal', id: 'minimal'},
    {name: 'Mild', id: 'mild'},
    {name: 'Moderate', id: 'moderate'},
    {name: 'Severe', id: 'severe'},
    {name: 'Fit', id: 'fit'},
    {name: 'Temporarily Fit', id: 'temporarily_fit'},
    {name: 'Temporarily Unfit', id: 'temporarily_unfit'},
  ];

  const updateTasks = (rawId, value) => {
    navigate(`/task/${rawId}/edit`, {
      state: {edit: rawId, value, id},
    });
    refreshData();
  };

  const deleteTasks = async deleteId => {
    const confirmDelete = window.confirm(
      'Are you sure you want to delete this task?',
    );
    if (!confirmDelete) {
      return;
    }
    setLoading(true);

    ApiClient.deleteTasks(id, deleteId)
      .then(response => {
        setShowAlert(true);
        setAlertMessage('Successfully Delete Task');
        setAlertType('success');
        refreshData();
        setData(response.data.data);
      })
      .catch(err => {
        console.log(err.response.data);
      });
  };

  const refreshData = React.useCallback(() => {
    setLoading(true);
    ApiClient.getTasks(id, severity)
      .then(res => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err.response.data);
        setLoading(false);
      });
  }, [id, severity]);

  useEffect(() => {
    refreshData();
  }, [id, patientId, refreshData]);

  const handleSeverityChange = value => {
    setSeverity(value);
    localStorage.setItem('selectedSeverity', value);
  };

  return (
    <div>
      <Header />

      <div style={{paddingLeft: 20, paddingTop: 25}}>
        <p style={{fontSize: 25}}>Task List :- </p>
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 20,
        }}>
        <CommonDropDown
          formControlStyle={{
            width: '10%',
            color: 'red',
            position: 'absolute',
            marginBottom: '7%',
            right: '18%',
            top: '10.5%',
          }}
          label="Severity"
          labelId="Severity"
          onChange={handleSeverityChange}
          labelStyle={{color: 'grey'}}
          value={severity}
          selectStyle={{width: '100%', height: '1%'}}
          data={severityList}
          setData={'id'}
          showData={'name'}
        />
        <Button
          variant="contained"
          sx={{
            backgroundColor: Colors.APPCIRCLE,
            marginTop: 2,
            width: '10%',
            position: 'absolute',
            marginBottom: '7%',
            right: '7%',
            top: '8.7%',
            height: 56,
            fontSize: '16px',
          }}
          component="span"
          onClick={() => navigate(`/task/${id}/new`)}>
          New Task
        </Button>
      </div>
      <div style={{padding: '1%'}}>
        <CommonTable
          data={data}
          tableHeaderData={tableHeaderData}
          dataKeys={dataKeys}
          showImageKey="image_url"
          showUpdate
          showDelete
          hideToggle
          languageToggle
          showDragAndDrop={!!severity}
          apiType="Task"
          taskSetId={id}
          onPressUpdate={updateTasks}
          tableStyle={{width: '100%'}}
          tableContainerStyle={{marginTop: 3}}
          onPressDelete={deleteTasks}
          refresh={refreshData}
          emptyTitle="No Task Found"
        />
      </div>
      {showAlert && (
        <LocalAlert
          severity={alertType}
          alertText={alertMessage}
          onClose={() => setShowAlert(false)}
        />
      )}
      <Loader loading={loading} />
    </div>
  );
};

export default TaskList;
