import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Switch,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import {useDispatch} from 'react-redux';
import AsyncThunks from '../redux/AsyncThunks';
import Utility from './Utility';
import ListComponent from './ListComponent';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import Loader from './Loader';
import LanguageSwitch from './LanguageSwitch';

function CommonTable(props) {
  const dispatch = useDispatch();
  const type = Utility.firstUpperCase(props.apiType);

  const [loading, setLoading] = React.useState(false);

  const showOtherOptions = (id, isActive) => {
    if (props.showOtherOption) {
      return props.showOtherOption(id, isActive);
    }
  };

  const returnTableHeader = () => {
    return props.tableHeaderData.map((header, index) => {
      return (
        <TableCell key={index} align="center">
          {header}
        </TableCell>
      );
    });
  };

  const deleteItem = id => {
    const confirm = window.confirm(
      'Are you sure you want to delete this ' +
        Utility.normlise(props.apiType) +
        '?',
    );
    if (confirm) {
      const method = `delete${type}`;
      dispatch(AsyncThunks[method]({id}));
    }
  };

  const renderSwitch = row => {
    const tableIsActive = row.deactivated_at === null;
    const toggle = isActive => {
      const upper = type;
      const method = `toggle${upper}`;
      dispatch(AsyncThunks[method]({id: row.id, isActive}));
    };
    return (
      <Switch
        onChange={e => toggle(e.target.checked)}
        defaultChecked={tableIsActive}
      />
    );
  };

  const languageSwitch = row => {
    return <LanguageSwitch row={row} type={type} taskSetId={props.taskSetId} />;
  };

  const disabled = !props.showDragAndDrop;

  const returnTableBody = (row, index) => {
    return (
      <Draggable
        isDragDisabled={disabled}
        key={row.id.toString()}
        draggableId={row.id.toString()}
        index={index}>
        {provided => (
          <TableRow
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            key={row.id.toString()}>
            {props.dataKeys.map((key, index) => {
              if (
                props.showLabel &&
                row[props.labelFor] == row[key] &&
                row.default
              ) {
                return (
                  <TableCell key={index} align="center">
                    {row[key]}
                    <span style={props.labelStyle}>{props.showLabel}</span>
                  </TableCell>
                );
              } else if (key === 'deactivated_at') {
                return (
                  <TableCell key={index} align="center">
                    {props.apiType &&
                      props.languageToggle &&
                      languageSwitch(row)}
                  </TableCell>
                );
              } else {
                return (
                  <TableCell key={index} align="center">
                    {key === 'follow_up_question'
                      ? row[key]?.descriptor_code || (
                          <h4 style={{color: 'red'}}>No Followups</h4>
                        )
                      : key === 'created_at'
                      ? Utility.formatDate(row.created_at)
                      : key === 'updated_at'
                      ? Utility.formatDate(row.updated_at)
                      : row[key]}
                  </TableCell>
                );
              }
            })}
            {props.showImageKey && (
              <TableCell align="center">
                {row[props.showImageKey] && (
                  <img
                    onClick={() =>
                      props.onImageClick && props.onImageClick(row)
                    }
                    src={row[props.showImageKey]}
                    alt="table-img"
                    style={{
                      maxHeight: '200px',
                      maxWidth: '200px',
                      cursor: props.onImageClick ? 'pointer' : 'none',
                    }}
                  />
                )}
              </TableCell>
            )}

            {(props.showUpdate || props.showDelete || props.showClone) && (
              <TableCell align="center">
                <div style={{display: 'flex', justifyContent: 'center'}}>
                  {props.showOtherOption && (
                    <>{showOtherOptions(row.id, row.deactivated_at)}</>
                  )}
                  {props.apiType && !props.hideToggle && renderSwitch(row)}
                  {props.showClone && (
                    <ContentCopyIcon
                      onClick={() => {
                        props.showCloneForm && props.showCloneForm(row.id);
                        props.onPressCopy(row.id);
                      }}
                      style={{
                        fontSize: '2rem',
                        cursor: 'pointer',
                        color: 'red',
                        marginLeft: '10px',
                      }}
                    />
                  )}
                  {props.onShowClick && (
                    <VisibilityIcon
                      onClick={() => {
                        props.onShowClick(row.id, row);
                      }}
                      style={{
                        fontSize: '2rem',
                        cursor: 'pointer',
                        color: 'green',
                        marginLeft: '10px',
                      }}
                    />
                  )}
                  {props.showTree && (
                    <AccountTreeIcon
                      onClick={() => {
                        props.showSymptoms && props.showSymptoms(row.id);
                        props.onPressShow(row.id);
                      }}
                      style={{
                        fontSize: '2rem',
                        cursor: 'pointer',
                        color: 'green',
                      }}
                    />
                  )}
                  {props.showUpdate && (
                    <EditIcon
                      onClick={() => {
                        props.updateDataId && props.updateDataId(row.id);
                        props.onPressUpdate(row.id, row);
                      }}
                      style={{
                        fontSize: '2rem',
                        cursor: 'pointer',
                        color: 'green',
                        marginLeft: '10px',
                      }}
                    />
                  )}
                  {props.showDelete && (
                    <DeleteIcon
                      onClick={() => {
                        if (
                          props.apiType &&
                          !props.languageToggle &&
                          !props.taskSetId
                        ) {
                          deleteItem(row.id);
                        } else {
                          props.deleteDataId && props.deleteDataId(row.id);
                          props.onPressDelete(row.id);
                        }
                      }}
                      style={{
                        fontSize: '2rem',
                        cursor: 'pointer',
                        color: 'red',
                        marginLeft: '10px',
                      }}
                    />
                  )}
                </div>
              </TableCell>
            )}
          </TableRow>
        )}
      </Draggable>
    );
  };

  const handleDragEnd = result => {
    if (!result.destination) return;
    setLoading(true);
    const items = Array.from(props.data);
    const taskId = props.taskSetId;
    const [reorderedItem] = items.splice(result.source.index, 1);
    const id = reorderedItem.id;
    const method = props.taskSetId ? `reorderTask${type}` : `reorder${type}`;
    const params = {row_order_position: result.destination.index};
    dispatch(AsyncThunks[method]({id, params, taskId}))
      .unwrap()
      .then(() => {
        if (props.refresh) {
          props
            .refresh()
            .unwrap()
            .then(() => {
              setLoading(false);
            });
        } else {
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 10,
        paddingLeft: 15,
        paddingRight: 15,
      }}>
      <TableContainer
        elevation={4}
        sx={props.tableContainerStyle}
        component={Paper}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable isDropDisabled={disabled} droppableId="table">
            {provided => (
              <Table
                {...provided.droppableProps}
                ref={provided.innerRef}
                sx={props.tableStyle}>
                <TableHead>
                  <TableRow>
                    {returnTableHeader()}
                    {(props.showUpdate || props.showDelete) && (
                      <TableCell align="center">Actions</TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <ListComponent
                    freeStyle
                    renderItem={returnTableBody}
                    {...props}
                    data={props.data}
                  />
                  {provided.placeholder}
                </TableBody>
                <Loader loading={loading} />
              </Table>
            )}
          </Droppable>
        </DragDropContext>
      </TableContainer>
    </div>
  );
}

export default CommonTable;
