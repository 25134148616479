import React, {useEffect, useState} from 'react';
import ApiClient from '../networking/ApiManager';
import {useSelector, useDispatch} from 'react-redux';
import Actions from '../redux/Actions';

function useUserData() {
  const userData = useSelector(state => state.UserDataSlice.userData);
  const dispatch = useDispatch();

  let sessionUser = localStorage.getItem('user');
  const isPatient = sessionUser == 'patient';
  const token = localStorage.getItem('token');

  useEffect(() => {
    if (Object.keys(userData).length === 0 && !!token) {
      if (!isPatient) {
        getDoctorDetails();
      } else {
        getUserDetails();
      }
    }
  }, []);

  const getUserDetails = () => {
    ApiClient.getPatient()
      .then(res => {
        let data = res.data.data;
        dispatch(Actions.setUserData(data));
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getDoctorDetails = () => {
    ApiClient.getProfileData()
      .then(res => {
        let data = res.data.data;
        const currentDoctor = data.super_admin ? 'doctor' : 'admin';
        if (sessionUser == currentDoctor) {
          dispatch(Actions.setUserData(data));
        } else {
          localStorage.clear();
          window.location.pathname = '/';
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getSetUserData = () => {
    return userData;
  };
  return {getSetUserData, userData};
}

export default useUserData;
