import React, {useState, useEffect} from 'react';

import {Box, Button} from '@mui/material';
import {useNavigate} from 'react-router';
import {
  CommonButton,
  CommonTable,
  CommonTextInput,
  Header,
  Loader,
  LocalAlert,
} from '../../commonComponents';
import ApiClient from '../../networking/ApiManager';

function TaskSet() {
  const [taskSetData, settaskSetData] = useState('');
  const [taskSetValue, setTaskSetValue] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertType, setAlertType] = useState('error');
  const [deleteDataId, setDeleteDataId] = useState(null);
  const [updateDataId, setUpdateDataId] = useState(null);
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getTaskData();
  }, []);

  const showForm = (action, text) => {
    return (
      <Box>
        <Header />
        <Box sx={{margin: 2}}>
          <h2 style={{margin: '15px 0'}}>{text}</h2>

          <CommonTextInput
            maxLength={100}
            label="Task Set Name"
            value={taskSetValue}
            onChange={setTaskSetValue}
            style={{
              width: '98.2%',
              display: 'flex',
              alignSelf: 'center',
              marginLeft: 16,
            }}
          />
          <CommonButton
            style={{width: '98.3%', backgroundColor: '#405672', marginTop: 20}}
            label="Create Task Set"
            onClick={createtaskSet}
          />
        </Box>
      </Box>
    );
  };

  const getTaskData = () => {
    setLoading(true);
    ApiClient.getTaskSet()
      .then(res => {
        settaskSetData(res.data.data);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        setShowAlert(true);
        setAlertType('error');
      });
  };

  const createtaskSet = () => {
    const params = {
      name: taskSetValue,
    };

    setLoading(true);
    ApiClient.createTaskSet(params)
      .then(res => {
        console.log(res.data.data);
        setShowAlert(true);
        setTaskSetValue('');
        getTaskData();
        setAlertMessage('Task Set Created Successfully');
        setAlertType('success');
        setChecked(checked);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        setShowAlert(true);
        setAlertMessage('Error Creating Task Set');
        setAlertType('error');
      });
  };

  const updatetaskSet = id => {
    const selectedTaskSet = taskSetData.find(taskSet => taskSet.id === id);
    if (selectedTaskSet) {
      setTaskSetValue(selectedTaskSet.name);
      navigate(`/task_sets_update/${id}/edit`, {
        state: {
          taskSetValue: selectedTaskSet.name,
        },
      });
    }
  };

  const tableHeaderData = ['Id', 'name', 'Created At', 'Updated At'];
  const dataKeys = ['id', 'name', 'created_at', 'updated_at'];

  const showtaskSetDataData = () => {
    return (
      <div style={{paddingLeft: '1%', paddingRight: '1%'}}>
        <CommonTable
          showLabel="default"
          labelFor="locale"
          labelStyle={{
            fontSize: '10px',
            marginLeft: '5px',
            display: 'inline-block',
            padding: '3px',
            borderRadius: '5px',
            backgroundColor: '#405672',
            color: '#fff',
            alignSelf: 'center',
            verticleAlign: 'middle',
          }}
          hideToggle
          languageToggle
          data={taskSetData}
          tableHeaderData={tableHeaderData}
          dataKeys={dataKeys}
          showUpdate
          showDelete
          onPressDelete={deletetaskSet}
          onPressUpdate={updatetaskSet}
          updateDataId={setUpdateDataId}
          deleteDataId={setDeleteDataId}
          tableStyle={{width: '100%'}}
          emptyTitle="No Task Set Found"
        />
      </div>
    );
  };

  const deletetaskSet = id => {
    const confirnDelete = window.confirm(
      'Are you sure you want to delete this Task set?',
    );
    if (confirnDelete) {
      setLoading(true);
      ApiClient.deleteTaskSet(id)
        .then(res => {
          setShowAlert(true);
          getTaskData();
          setAlertMessage('Task Set Deleted Successfully');
          setAlertType('success');
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          setShowAlert(true);
          setAlertMessage('Error Deleting Task Set');
          setAlertType('error');
        });
    }
  };

  return (
    <>
      {showForm('', 'Task Set :-')}
      {showAlert && (
        <LocalAlert
          alertText={alertMessage}
          onClose={() => setShowAlert(false)}
          severity={alertType}
        />
      )}
      {taskSetData && showtaskSetDataData()}
      <Loader loading={loading} />
    </>
  );
}

export default TaskSet;
