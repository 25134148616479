import React, {useRef} from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {PropTypes} from 'prop-types';
import {Chip} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const MultipleSelectChip = ({
  data,
  onChipClick,
  selectedValue,
  onChange,
  ...props
}) => {
  return (
    <Autocomplete
      multiple
      noOptionsText={props.noOptionsText}
      sx={{margin: '20px 0'}}
      id="checkboxes-tags-demo"
      options={data}
      clearIcon={false}
      disableCloseOnSelect
      renderTags={values => {
        return values.map(value => {
          return (
            <Chip
              key={value.id}
              style={{marginRight: 5, marginTop: 5}}
              size="medium"
              clickable
              onDelete={() => {
                onChipClick(value);
              }}
              label={value.name}
            />
          );
        });
      }}
      isOptionEqualToValue={
        props.isOptionEqualToValue
          ? props.isOptionEqualToValue
          : (item, value) => item.id === value.id
      }
      value={selectedValue}
      filterOptions={x => x}
      getOptionLabel={option => option.name}
      renderOption={(params, option, {selected}) => {
        const onClick = () => {
          props.onClick ? props.onClick(option) : onChipClick(option);
        };
        const isAdd = option.key === 'add';
        return (
          <li onClick={onClick} {...params}>
            <Checkbox
              icon={isAdd ? <AddIcon /> : icon}
              onClick={onClick}
              checkedIcon={checkedIcon}
              style={{marginRight: isAdd ? 0 : 8}}
              checked={selected}
            />
            <div
              className="pointer"
              style={{fontWeight: isAdd ? 800 : 400}}
              onClick={onClick}>
              {option.name}
            </div>
          </li>
        );
      }}
      style={{width: 'auto'}}
      renderInput={params => (
        <TextField
          {...params}
          value={props.value}
          onChange={e => onChange && onChange(e.target.value)}
          label={props.label}
          disabled
          placeholder={props.placeholder}
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
    />
  );
};
export default MultipleSelectChip;

MultipleSelectChip.propTypes = {
  data: PropTypes.array,
  onChipClick: PropTypes.func,
  selected: PropTypes.object,
  handleChange: PropTypes.func,
  isSelected: PropTypes.func,
  selectedValue: PropTypes.array,
};
