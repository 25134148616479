import React, {useEffect, useState} from 'react';
import {
  CommonButton,
  CommonDropDown,
  CommonTextInput,
  Header,
  Loader,
  LocalAlert,
} from '../../commonComponents';
import {Button} from '@mui/material';
import ApiClient from '../../networking/ApiManager';
import {useNavigate, useParams, useLocation} from 'react-router';
import LanguageDropdown from '../../commonComponents/LanguageDropdown';

const CreateSubspecialistRange = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const editParamId = location.state && location.state.edit;
  const editParamMin = location.state && location.state.value.min;
  const editParammax = location.state && location.state.value.max;
  const editParamseverity = location.state && location.state.value.severity;
  const editParamtitle = location.state && location.state.value.ltitle;
  const editEffectiveIndex =
    location.state && location.state.value.effective_index;
  const editParamPatientCost =
    location.state && location.state.value.cost_per_patient;
  const editId = location.state && location.state.id;

  const [loading, setLoading] = useState(false);
  const [alertType, setAlertType] = useState('error');
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [min, setMin] = useState(editParamMin ?? null);
  const [max, setMax] = useState(editParammax ?? null);
  const [title, setTitle] = useState(editParamtitle || {});
  const [severity, setSeverity] = useState(editParamseverity || '');
  const [patientCost, setpatientCost] = useState(editParamPatientCost ?? null);
  const [effectiveIndex, setEffectiveIndex] = useState(
    editEffectiveIndex ?? null,
  );
  const [errors, setErrors] = useState({
    min: '',
    max: '',
    title: '',
    severity: '',
    patientCost: '',
  });

  const createSubspecialistRange = () => {
    const numericMin = parseFloat(min);
    const numericMax = parseFloat(max);

    if (
      (min !== 0 && !min) ||
      (max !== 0 && !max) ||
      severity === '' ||
      (patientCost !== 0 && !patientCost)
    ) {
      const newErrors = {
        min: min !== 0 && !min ? 'Please enter a min value' : '',
        max: max !== 0 && !max ? 'Please enter a max value' : '',
        severity: severity === '' ? 'Please enter severity' : '',
        patientCost:
          patientCost !== 0 && !patientCost
            ? 'Please enter cost per patient'
            : '',
      };
      setErrors(newErrors);
    } else if (numericMax < numericMin) {
      setErrors({...errors, max: 'Max value should be greater than min value'});
    } else {
      const params = {
        subspecialty_id: id,
        min,
        max,
        title,
        severity,
        effective_index: effectiveIndex || '',
        cost_per_patient: patientCost,
      };

      const formData = new FormData();

      for (const key in params) {
        formData.append(key, params[key]);
      }
      for (const key of Object.keys(title)) {
        formData.append('ltitle[' + key + ']', title[key]);
      }

      setLoading(true);
      ApiClient.createSubspecialistRange(id, formData)
        .then(res => {
          setShowAlert(true);
          setAlertMessage('Successfully Create Score Range');
          setAlertType('success');
          setTimeout(() => {
            navigate(-1);
          }, 500);
          setLoading(false);
        })
        .catch(err => {
          console.log(err.response.data);
        });
    }
  };

  const updateSubspecialistScoreRange = () => {
    const numericMin = parseFloat(min);
    const numericMax = parseFloat(max);

    if (
      (min !== 0 && !min) ||
      (max !== 0 && !max) ||
      severity === '' ||
      (patientCost !== 0 && !patientCost)
    ) {
      const newErrors = {
        min: min !== 0 && !min ? 'Please enter min value' : '',
        max: max !== 0 && !max ? 'Please enter max value' : '',
        severity: severity === '' ? 'Please enter severity' : '',
        patientCost:
          patientCost !== 0 && !patientCost
            ? 'Please enter cost per patient'
            : '',
      };
      setErrors(newErrors);
    } else if (numericMax < numericMin) {
      setErrors({...errors, max: 'Max value should be greater than min value'});
    } else {
      const params = {
        subspecialty_id: id,
        min,
        max,
        title,
        severity,
        effective_index: effectiveIndex || '',
        cost_per_patient: patientCost,
      };

      const formData = new FormData();

      for (const key in params) {
        formData.append(key, params[key]);
      }
      for (const key of Object.keys(title)) {
        formData.append('ltitle[' + key + ']', title[key]);
      }
      setLoading(true);
      ApiClient.updateSubspecialistScoreRange(editId, editParamId, formData)
        .then(res => {
          setShowAlert(true);
          setAlertMessage('Successfully Create Score Range');
          setAlertType('success');
          setTimeout(() => {
            navigate(-1);
          }, 500);
          setLoading(false);
        })
        .catch(err => {
          console.log(err.response.data);
        });
    }
  };

  const severityList = [
    {name: 'Minimal', id: 'minimal'},
    {name: 'Mild', id: 'mild'},
    {name: 'Moderate', id: 'moderate'},
    {name: 'Severe', id: 'severe'},
    {name: 'Fit', id: 'fit'},
    {name: 'Temporarily Fit', id: 'temporarily_fit'},
    {name: 'Temporarily Unfit', id: 'temporarily_unfit'},
  ];

  return (
    <div>
      <Header />
      <div style={{paddingLeft: 20}}>
        <p style={{fontSize: 25}}>
          {`${editParamId ? 'Update' : 'Create'} Score Range`} :-{' '}
        </p>
      </div>
      <div style={{padding: 20}}>
        <CommonTextInput
          allowNegativeDecimaleNumber
          error={!!errors.min}
          helperText={errors.min}
          label="Min"
          style={{m: 1, width: '100%', marginBottom: 20}}
          onChange={value => {
            setMin(value);
            setErrors({...errors, min: ''});
          }}
          value={min}
        />

        <CommonTextInput
          error={!!errors.max}
          helperText={errors.max}
          allowNegativeDecimaleNumber
          label="Max"
          style={{m: 1, width: '100%', marginBottom: 20}}
          onChange={value => {
            setMax(value);
            setErrors({...errors, max: ''});
          }}
          value={max}
        />
        <LanguageDropdown
          inputValue={title}
          onChange={setTitle}
          label="Title"
        />

        <CommonTextInput
          maxLength={250}
          error={!!errors.effectiveIndex}
          helperText={errors.effectiveIndex}
          label="Effective Index"
          validateDeciNumbers
          style={{m: 1, width: '100%', marginBottom: 20, marginTop: 20}}
          onChange={value => {
            setEffectiveIndex(value);
            setErrors({...errors, effectiveIndex: ''});
          }}
          value={effectiveIndex}
        />

        <CommonDropDown
          formControlStyle={{width: '100%', marginBottom: 20, color: 'red'}}
          label="Severity"
          error={!!errors.severity}
          helperText={errors.severity}
          labelId="Severity"
          onChange={value => {
            setSeverity(value);
            setErrors({...errors, severity: ''});
          }}
          labelStyle={{color: !!errors.severity ? '#d32f2f' : 'grey'}}
          value={severity}
          selectStyle={{width: '100%'}}
          data={severityList}
          setData={'id'}
          showData={'name'}
        />

        <CommonTextInput
          maxLength={250}
          showLength={125}
          error={!!errors.patientCost}
          helperText={errors.patientCost}
          validateDeciNumbers
          label="Cost Per Patient"
          style={{m: 1, width: '100%', marginBottom: 20}}
          onChange={value => {
            setpatientCost(value);
            setErrors({...errors, patientCost: ''});
          }}
          value={patientCost}
        />
      </div>

      <CommonButton
        style={{
          backgroundColor: '#405672',
          marginTop: 2,
          width: '98%',
        }}
        label={'Save'}
        onClick={() => {
          editParamId
            ? updateSubspecialistScoreRange()
            : createSubspecialistRange();
        }}
      />
      {showAlert && (
        <LocalAlert
          severity={alertType}
          alertText={alertMessage}
          onClose={() => setShowAlert(false)}
        />
      )}
      <Loader loading={loading} />
    </div>
  );
};

export default CreateSubspecialistRange;
