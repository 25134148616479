import React from 'react';
import {
  OutlinedInput,
  TextField,
  createTheme,
  ThemeProvider,
} from '@mui/material';
import Colors from '../Resources/COLORS/Colors';

function CommonTextInput(props) {
  const setData = e => {
    let value = e.target.value;

    if (props.removeWhiteSpace) {
      value = value.replace(/\s/g, '');
    }

    // validate negative number
    if (props.validateNegative) {
      if (value.includes('-')) {
        value = value.replace('-', '');
      }
    }
    // validate string only
    if (props.validateStringOnly) {
      value = value.replace(/[^a-zA-Z]/g, '');
    }

    // Allow negative and positive numbers
    if (props.validateNegativePositiveNumber) {
      value = value.replace(/[^0-9-]/g, '');
    }

    if (props.allowNegativeDecimaleNumber) {
      const regex = /^-?\d*\.?\d*$/;
      if (!regex.test(value)) {
        return;
      }
    }

    // set Max Length for text
    if (props.maxLength) {
      if (value.length > props.maxLength) {
        value = value.substring(0, props.maxLength);
      }
    }
    // validate Numbers only
    if (props.validateNumbers) {
      value = value.replace(/[^0-9]/g, '');
    }
    if (props.validateDeciNumbers) {
      value = value.replace(/[^0-9.]/g, '');

      const dotCount = value.split('.').length - 1;
      if (dotCount > 1) {
        value = value.slice(0, value.lastIndexOf('.'));
      }
    }

    if (props.type === 'file') {
      if (props.multiple) {
        value = [];
        Object.keys(e.target.files).map(item => {
          value.push(e.target.files[item]);
        });

        props?.onChange(value);
      } else {
        props?.onChange(e.target.files[0]);
      }
    } else {
      props?.onChange(value);
    }
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: Colors.APPCIRCLE,
        light: Colors.APPCIRCLE,
        dark: Colors.APPCIRCLE,
      },
    },
  });

  const inputColor = props.error ? '#FF0000' : Colors.BLACK;

  if (props.type === 'file' && !props.showFileIcon) {
    return (
      <input
        value=""
        type="file"
        multiple={props.multiple}
        onChange={setData}
        style={props.style}
        accept={props.accept}
        id={props.id}
        className={props.className}
      />
    );
  } else if (props.showFileIcon) {
    return (
      <div className={`file-input ${props.className}`} style={props.sytle}>
        <input
          multiple={props.multiple}
          type="file"
          onChange={setData}
          accept={props.accept}
          name="file-input"
          id="file-input"
          className="file-input__input"
          value=""
        />
        <label className="file-input__label" for="file-input">
          <img
            src={props.icon}
            alt="file-upload-icon"
            style={props.iconStyle}
          />
        </label>
      </div>
    );
  } else if (props.customize) {
    return (
      <ThemeProvider theme={theme}>
        <OutlinedInput
          multiple={props.multiple}
          error={props.error}
          helperText={props.helperText ?? ''}
          type={props.type || 'text'}
          style={props.style}
          sx={{
            borderRadius: '15px',
            color: inputColor,
            ...props.sx,
            '& .Mui-disabled': {
              color: '#000000',
              '-webkit-text-fill-color': '#000000 !important',
              cursor: 'not-allowed',
            },
          }}
          name={props.name}
          multiline={props.multiline}
          id={props.id}
          minRows={props.minRows}
          maxRows={props.maxRows}
          autoComplete={'off'}
          autoFocus={props.autoFocus}
          required={props.required}
          margin={props.margin}
          onChange={setData}
          value={props.value}
          placeholder={props.placeholder}
          elevation={props.elevation}
          disabled={props.disabled}
          className={props.className}
          inputProps={props.inputProps}
        />
        {props.error && props.helperText && (
          <div
            style={{
              color: 'red',
              display: 'block',
              textAlign: 'left',
              alignSelf: 'flex-start',
              margin: '0 15px',
              ...props.helperTextStyle,
            }}>
            {props.helperText}
          </div>
        )}
      </ThemeProvider>
    );
  } else {
    return (
      <>
        <TextField
          multiple={props.multiple}
          error={props.error}
          helperText={props.helperText ?? ''}
          type={props.type || 'text'}
          style={props.style}
          multiline={props.multiline}
          id={props.id}
          minRows={props.minRows}
          maxRows={props.maxRows}
          label={props.label || ' '}
          variant={props.variant || 'outlined'}
          autoComplete={'off'}
          autoFocus={props.autoFocus}
          required={props.required}
          margin={props.margin}
          onChange={setData}
          value={props.value}
          placeholder={props.placeholder}
          elevation={props.elevation}
          disabled={props.disabled}
          className={props.className}
          maxLength={props.maxLength}
          InputProps={{
            endAdornment: (
              <div
                style={{
                  marginLeft: '8px',
                }}>
                {/* {comment.length > 150 ? `${comment.length}/250` : null} */}
                {props.value?.length > props.showLength
                  ? props.value?.length + '/' + props.maxLength
                  : null}
              </div>
            ),
          }}
        />
      </>
    );
  }
}

export default CommonTextInput;
