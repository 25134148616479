import React, { useContext } from 'react';

const SychartContext = React.createContext();

const SychartProvider = SychartContext.Provider;

const useSychart = () => {
  const context = useContext(SychartContext);

  if (!context) {
    throw new Error('useSychart must be used within a SychartProvider');
  }

  return context;
};

export { SychartProvider, useSychart };
