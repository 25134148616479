import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import ApiClient from '../../../networking/ApiManager';

const initialState = {
  data: [],
  query: '',
  isLoading: true,
  isRefreshing: false,
  status: 'idle',
  error: null,
  firstLoad: true,
};

export const registeredPatientsList = createAsyncThunk(
  'RegistrationPatientListSlice/searchPatients',
  async (query, thunkAPI) => {
    try {
      const response = await ApiClient.registeredPatientsList({query});
      return {query, data: response.data.data};
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

const sliceNameSlice = createSlice({
  name: 'RegistrationPatientListSlice',
  initialState,
  reducers: {
    setRegisterPatientSearchQuery: (state, action) => {
      if (!state.firstLoad) {
        state.isLoading = true;
        state.isRefreshing = true;
      }
      state.query = action.payload;
    },
    updatePatient: (state, action) => {
      const {id, ...rest} = action.payload;
      const index = state.data.findIndex(item => item.id === id);
      state.data[index] = {...state.data[index], ...rest};
    },
    addPatient: (state, action) => {
      state.data = [action.payload, ...state.data];
    },
    setPatientStatus: (state, action) => {
      state.data = state.data.map(item => {
        if (item.id === action.payload.id) {
          return {...item, ...action.payload};
        }
        return item;
      });
    },
  },
  extraReducers: builder => {
    builder
      .addCase(registeredPatientsList.pending, state => {
        state.isLoading = true;
      })
      .addCase(registeredPatientsList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isRefreshing = false;
        state.firstLoad = false;
        state.data = action.payload.data;
      })
      .addCase(registeredPatientsList.rejected, (state, action) => {
        state.isLoading = false;
        state.isRefreshing = false;
        state.error = action.error.message;
      });
  },
});

export default sliceNameSlice.reducer;

export const RegistrationPatientListActions = sliceNameSlice.actions;
