import React, {useEffect, useState} from 'react';
import {Box, Grid} from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import {useLocalState, useTableData} from '../../hooks';
import {
  CommonButton,
  CommonDropDown,
  CommonTable,
  Header,
  Loader,
  LocalAlert,
} from '../../commonComponents';
import ApiClient from '../../networking/ApiManager';

function Category() {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertType, setAlertType] = useState('error');
  const [loading, setLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useLocalState(
    'question-category',
    '',
  );
  const [categoryData, setCategoryData] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useLocalState(
    'question-department-id',
    '',
  );
  const navigate = useNavigate();

  const {departments} = useTableData(true);

  const {id} = useParams();

  const typeList = [
    {name: 'Input Category', key: 'InputCategory'},
    {name: 'Output Category', key: 'OutputCategory'},
  ];

  useEffect(() => {
    if (selectedCategory && selectedDepartment) {
      getFilterCategory();
    } else {
      setSelectedDepartment(departments[0].id);
      setSelectedCategory(typeList[0].key);
    }
  }, [selectedCategory, selectedDepartment]);

  const getFilterCategory = (department_id, type) => {
    setLoading(true);
    ApiClient.getSymptomCategories(selectedDepartment, selectedCategory)
      .then(res => {
        setCategoryData(res.data.data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const createCategory = () => {
    navigate('/category/new');
  };

  const updateCategory = id => {
    navigate(`/category/${id}/edit`);
  };

  const deleteCategory = id => {
    const confirmDelete = window.confirm(
      'Are you sure you want to delete this Category?',
    );
    if (confirmDelete) {
      setLoading(true);
      ApiClient.deleteCategory(id)
        .then(res => {
          getFilterCategory();
          setShowAlert(true);
          setAlertMessage('Category deleted successfully');
          setAlertType('success');
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          console.log(err.response.data);
          setShowAlert(true);
          setAlertMessage('Failed to delete Category');
          setAlertType('error');
        });
    } else {
      setLoading(false);
    }
  };

  const categoryFilter = () => {
    return (
      <CommonDropDown
        value={selectedCategory}
        formControlStyle={{width: '100%', margin: '15px 0'}}
        label="Category"
        labelId="category"
        onChange={setSelectedCategory}
        selectStyle={{width: '100%'}}
        data={typeList}
        setData={'key'}
        showData={'name'}
      />
    );
  };

  const departmentDropdown = () => {
    return (
      <CommonDropDown
        value={selectedDepartment}
        formControlStyle={{width: '100%', margin: '15px 0'}}
        label="Select Department"
        labelId="Departments"
        onChange={setSelectedDepartment}
        selectStyle={{width: '100%'}}
        data={departments}
        setData={'id'}
        showData={'name'}
      />
    );
  };

  const tableHeaderData = ['Id', 'Name', 'Category Type', 'department Name'];
  const dataKeys = ['id', 'name', 'category_name', 'department_name'];

  return (
    <Box>
      <Header />
      <Box sx={{margin: 2}}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}>
          <Grid item xs={2} sm={4} md={8}>
            <p
              style={{
                fontSize: 30,
                margin: '15px 0px',
                position: 'absolute',
                left: 32,
              }}>
              Category :-{' '}
            </p>
          </Grid>
          <div style={{minWidth: '300px'}}>{departmentDropdown()}</div>
          <div
            style={{
              minWidth: '300px',
              margin: '0px 15px',
            }}>
            {categoryFilter()}
          </div>
        </div>
        <CommonButton
          style={{width: '98.4%', backgroundColor: '#405672', marginTop: 2}}
          label="Create Category"
          onClick={createCategory}
        />

        <CommonTable
          data={categoryData}
          tableHeaderData={tableHeaderData}
          dataKeys={dataKeys}
          showUpdate
          onPressUpdate={updateCategory}
          showDelete
          onPressDelete={deleteCategory}
          tableStyle={{width: '100%'}}
          tableContainerStyle={{marginTop: 3}}
          emptyTitle="No Category Found"
        />
      </Box>
      {showAlert && (
        <LocalAlert
          severity={alertType}
          alertText={alertMessage}
          onClose={() => setShowAlert(false)}
        />
      )}
      <Loader loading={loading} />
    </Box>
  );
}

export default Category;
