import React, {useState, useEffect} from 'react';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import Colors from '../Resources/COLORS/Colors';
import ApiClient from '../networking/ApiManager';

function CommonAskForPending(props) {
  const navigate = useNavigate();
  const {openModal, setOpenModal} = props;
  const [pendingQuestionData, setPendingQuestionData] = useState(null);
  const [apiCalled, setCpiCalled] = useState(false);

  const {t, i18n} = useTranslation();
  let sessionUser = localStorage.getItem('user');

  useEffect(() => {
    if (
      sessionUser == 'patient' &&
      window.location.pathname !== '/termsAndConditions' &&
      window.location.pathname !== '/termsAndConditions'
    ) {
      verifyPendingQuestion();
    }
  }, []);

  const verifyPendingQuestion = () => {
    ApiClient.pendingPatientResponses()
      .then(res => {
        setPendingQuestionData(res.data.data);
        setCpiCalled(true);
      })
      .catch(err => {
        console.log(err);
        setCpiCalled(true);
      });
  };

  const returnModal = () => {
    const style = {
      position: 'fixed',
      justifyContent: 'center',
      top: '80px',
      left: '5px',
      right: '5px',
      backgroundColor: '#ffff',
      maxHeight: '65vh',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '20px',
      borderRadius: '5px',
      zIndex: '100',
    };

    const navigateToPendingQuestion = () => {
      navigate('/startquestionary', {state: {pendingQuestionData}});
      setOpenModal(false);
    };

    return (
      <>
        <div
          style={{
            height: '110vh',
            width: '110vw',
            backgroundColor: '#00000075',
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: '100',
          }}
          onClick={() => setOpenModal(false)}>
          <Box
            sx={style}
            onClick={e => {
              e.stopPropagation();
            }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <p style={{textAlign: 'justify'}}>
                  {t('pending_sychart_question')}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'end',
                  marginTop: '15px',
                  alignItems: 'center',
                }}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => navigate('/termsAndConditions')}
                  onMouseEnter={e => {
                    e.target.style.fontWeight = 'bold';
                  }}
                  onMouseLeave={e => {
                    e.target.style.fontWeight = '500';
                  }}>
                  {t('start_over')}
                </Button>
                <Button
                  variant="outlined"
                  color="success"
                  style={{marginLeft: '10px'}}
                  onClick={navigateToPendingQuestion}
                  onMouseEnter={e => {
                    e.target.style.fontWeight = 'bold';
                  }}
                  onMouseLeave={e => {
                    e.target.style.fontWeight = '500';
                  }}>
                  {t('resume_pending')}
                </Button>
              </div>
            </div>
          </Box>
        </div>
      </>
    );
  };

  if (apiCalled) {
    if (pendingQuestionData?.id) {
      return returnModal();
    } else {
      navigate('/termsAndConditions');
      return <></>;
    }
  } else {
    return <></>;
  }
}

export default CommonAskForPending;
