import React from 'react';
import Colors from '../Resources/COLORS/Colors';
import {Box, createTheme, ThemeProvider} from '@mui/material';

function CommonBorderBox(props) {
  const {divStyle, disabled} = props;

  const hoverStyle = {
    border: !disabled && '2px solid ' + Colors.APPCIRCLE,
    borderRadius: '15px',
    fontWeight: '600',
    cursor: disabled ? 'not-allowed' : 'pointer',
  };

  const style = {
    border: '2px solid ' + Colors.BORDER,
    borderRadius: '15px',
    fontWeight: '600',
    cursor: 'pointer',
    boxShadow: '0px 2px 7px 0px #888888c2',
  };

  return (
    <Box
      onClick={props.onClick && props.onClick}
      sx={{
        'div.commonBorderBox': {
          ...style,
          ...props.style,
          '&:hover': {...hoverStyle, ...props.hoverStyle},
        },
      }}
      style={divStyle}>
      <div className="commonBorderBox">{props.children}</div>
    </Box>
  );
}

export default CommonBorderBox;
