import React, {useCallback, useEffect, useState} from 'react';
import {Box, Button, Modal} from '@mui/material';
import {
  LocalAlert,
  CommonDropDown,
  CommonTextInput,
  Header,
  CommonButton,
} from '../../commonComponents';
import {useNavigate, useParams} from 'react-router-dom';
import LanguageDropdown from '../../commonComponents/LanguageDropdown';
import Loader from '../../commonComponents/Loader';
import Colors from '../../Resources/COLORS/Colors';
import {useTableData} from '../../hooks';
import {useDispatch} from 'react-redux';
import AsyncThunks from '../../redux/AsyncThunks';
import ApiClient from '../../networking/ApiManager';
import BlackCrossIcon from '../../commonComponents/BlackCrossIcon';

function CreateSubSpecialist() {
  const [name, setName] = useState('');
  const [image, setImage] = useState('');
  const [serverImage, setServerImage] = useState('');
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [cloneModalOpen, setCloneModalOpen] = useState(false);
  const [departmentList, setDepartmentList] = useState(null);
  const [departmentIdForClone, setDepartmentIdForClone] = useState('');

  const [alertMessage, setAlertMessage] = useState(null);
  const [alertType, setAlertType] = useState('error');
  const [lName, setLName] = useState({});
  const [promptData, setPromptData] = useState({});
  const [selectdCategory, setSelectdCategory] = useState('');
  const [isHopiCategory, setIsHopiCategory] = useState(false);
  const [maxScore, setMaxScore] = useState('');
  const [effectiveIndex, setEffectiveIndex] = useState('');
  const table = useTableData();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const {id} = useParams();
  const {departments} = useTableData(true);
  const [isBtnDiasable, setIsBtnDisable] = useState(false);

  const getSingleSubSpecialist = useCallback(async () => {
    const subcpecialist = await table.get.subspecialist(id);
    setMaxScore(subcpecialist.max_score);
    setEffectiveIndex(subcpecialist.effective_index);
    setName(subcpecialist.name);
    setLName(subcpecialist.lname);
    setServerImage(subcpecialist.image_url);
    setSelectdCategory(subcpecialist.category);
    setIsHopiCategory(subcpecialist.category === 'hopi');
    setPromptData(subcpecialist.lprompt);
  }, [id, table.get]);

  useEffect(() => {
    if (id) {
      getSingleSubSpecialist();
    }
  }, [id, getSingleSubSpecialist]);

  useEffect(() => {
    setDepartmentList(departments);
  }, [departments]);

  const prepareFormData = () => {
    let formData = new FormData();

    for (const key of Object.keys(lName)) {
      formData.append('lname[' + key + ']', lName[key]);
    }
    for (const key of Object.keys(promptData)) {
      formData.append('lprompt[' + key + ']', promptData[key]);
    }
    image && formData.append('image', image);
    formData.append('department_id', table.departmentId);
    formData.append('category', selectdCategory);
    formData.append('max_score', maxScore);
    formData.append('effective_index', effectiveIndex);
    return formData;
  };

  const forFinal = func => {
    func
      .unwrap()
      .then(res => {
        navigate('/subspecialties');
        setShowAlert(true);
      })
      .catch(err => {
        console.log(err.response.data);
        setShowAlert(true);
        setAlertMessage('Failed to Create Subspecialties');
        setAlertType('error');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const createNewSubspecialist = () => {
    if (!selectdCategory) {
      setShowAlert(true);
      setAlertMessage('Please select category');
      setAlertType('error');
    } else if (
      Object.keys(lName).length === 0 ||
      Object.values(lName)[0] === ''
    ) {
      setShowAlert(true);
      setAlertMessage('Please select subspecialty');
      setAlertType('error');
    } else {
      setLoading(true);
      let params = prepareFormData();
      forFinal(dispatch(AsyncThunks.createSubspecialtie({params})));
    }
  };

  const updateSubspecialist = () => {
    if (!selectdCategory) {
      setShowAlert(true);
      setAlertMessage('Please select category');
      setAlertType('error');
    } else if (
      Object.keys(lName).length === 0 ||
      Object.values(lName)[0] === ''
    ) {
      setShowAlert(true);
      setAlertMessage('Please select subspecialty');
      setAlertType('error');
    } else {
      setLoading(true);
      let formData = prepareFormData();
      forFinal(
        dispatch(AsyncThunks.updateSubspecialtie({id, params: formData})),
      );
    }
  };

  const CategoryData = [
    {key: 'past', name: 'Past History'},
    {key: 'personal', name: 'Personal History'},
    {key: 'family', name: 'Family History'},
    {key: 'hopi', name: 'HOPI'},
    {key: 'anxiety', name: 'Anxiety'},
    {key: 'depression', name: 'Depression'},
    {key: 'sleep', name: 'Sleep'},
    {key: 'loneliness', name: 'Loneliness'},
    {key: 'stress', name: 'Stress'},
    {key: 'sleepiness', name: 'Sleepiness'},
    {key: 'osa_risk', name: 'OSA Risk'},
    {key: 'food', name: 'Food'},
    {key: 'gi', name: 'Gi'},
    {key: 'neuropsychological', name: 'Neuropsychological'},
    {key: 'skin', name: 'Skin'},
    {key: 'weight_issues', name: 'Weight Issues'},
    {key: 'vitamin_d', name: 'Vitamin D'},
    {key: 'joint_pain', name: 'Joint Pain'},
    {key: 'prostate', name: 'Prostate'},
    {key: 'fibromyalgia', name: 'Fibromyalgia'},
    {key: 'allergies', name: 'Allergies'},
  ];

  const syExamBtn = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          margin: '10px 20px',
        }}>
        <div
          onClick={() => {
            navigate(`/subspecialties/${id}/sy_exam`);
          }}
          style={{
            background: Colors.APPCIRCLE,
            padding: '5px 25px',
            borderRadius: '15px',
            color: Colors.WHITE,
            fontSize: 'large',
            fontWeight: '600',
            cursor: 'pointer',
          }}>
          SyExam
        </div>
      </div>
    );
  };

  const showCloneButton = (btnAction, style, boxStyle) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          margin: '10px 20px',
          ...boxStyle,
        }}>
        <div
          onClick={isBtnDiasable ? null : btnAction}
          style={{
            background: Colors.APPCIRCLE,
            padding: '5px 25px',
            borderRadius: '15px',
            color: Colors.WHITE,
            fontSize: 'large',
            fontWeight: '600',
            cursor: 'pointer',
            ...style,
          }}>
          Clone
        </div>
      </div>
    );
  };

  const showFields = (showState1, setState1) => {
    return (
      <>
        <CommonDropDown
          formControlStyle={{width: '100%', margin: '10px 0px'}}
          label="Department Name"
          labelId="Department_Name"
          onChange={setState1}
          value={showState1}
          selectStyle={{width: '100%'}}
          data={departmentList || []}
          setData={'id'}
          showData={'name'}
        />
      </>
    );
  };

  const transLateSymptom = () => {
    setLoading(true);
    ApiClient.transLateSubspecialty(id)

      .then(res => {
        setLoading(false);
        setAlertMessage(res.data.data);
        setAlertType('success');
        setShowAlert(true);
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
      });
  };

  const cloneForm = () => {
    return (
      <div>{showFields(departmentIdForClone, setDepartmentIdForClone)}</div>
    );
  };

  const handleClone = () => {
    setIsBtnDisable(true);
    const params = {
      id: id,
      department_id: departmentIdForClone,
    };

    setLoading(true);
    ApiClient.cloneSubspecialist(params, id)
      .then(res => {
        setLoading(false);
        setAlertMessage(res.data.data);
        setAlertType('success');
        setShowAlert(true);
        setCloneModalOpen(false);
        setIsBtnDisable(false);
      })
      .catch(err => {
        console.log(err.response.data);
        setLoading(false);
        setAlertMessage('Failed to clone Subspecialty');
        setAlertType('error');
        setShowAlert(true);
        setIsBtnDisable(false);
      });
  };

  const cloneModal = () => {
    const style = {
      position: 'absolute',
      top: '50px',
      boxShadow: 24,
      backgroundColor: '#fff',
      overflow: 'auto',
      maxHeight: '80vh',
      p: 4,
      minWidth: '50%',
    };
    return (
      <Modal
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={cloneModalOpen}
        onClose={() => setCloneModalOpen(false)}
        aria-labelledby="Show Clone Data"
        aria-describedby="Clone Data">
        <Box sx={style}>
          <BlackCrossIcon
            onClick={() => setCloneModalOpen(false)}
            containerStyle={styles.containerStyle}
            style={styles.iconStyle}
          />
          {cloneForm()}
          {showCloneButton(
            handleClone,
            {width: '100%', textAlign: 'center'},
            {margin: '10px, 0px'},
          )}
        </Box>
      </Modal>
    );
  };

  const coloneAndTranslateBtn = () => {
    return (
      <>
        <div
          onClick={transLateSymptom}
          style={{
            background: Colors.APPCIRCLE,
            padding: '5px 25px',
            borderRadius: '15px',
            color: Colors.WHITE,
            fontSize: 'large',
            fontWeight: '600',
            cursor: 'pointer',
            alignSelf: 'center',
            width: 'fit-content',
          }}>
          Translate
        </div>
        {showCloneButton(() => setCloneModalOpen(true))}
        <div
          style={{
            background: Colors.APPCIRCLE,
            padding: '5px 20px',
            borderRadius: '15px',
            color: Colors.WHITE,
            fontSize: 'large',
            fontWeight: '600',
            cursor: 'pointer',
            alignSelf: 'center',
            width: 'fit-content',
            marginRight: '10px',
          }}
          onClick={() => navigate(`/subspecialty_score_ranges/${id}`)}>
          <span style={{whiteSpace: 'nowrap'}}>Score Ranges</span>
        </div>
      </>
    );
  };

  return (
    <Box>
      <Header />
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding: 5,
        }}>
        {!!id && coloneAndTranslateBtn()}
        {id && isHopiCategory && syExamBtn()}
      </div>
      <Box sx={{margin: 2}}>
        <h2 style={{margin: '15px 0'}}>
          {id ? 'Update' : 'Create'} Subspecialties :-
        </h2>

        <CommonDropDown
          formControlStyle={{width: '100%', margin: '15px 0'}}
          selectStyle={{width: '100%'}}
          {...table.departmentDropDown}
        />
        <CommonDropDown
          formControlStyle={{width: '100%', margin: '0 0 15px 0'}}
          label="Category"
          labelId="Select_Category"
          onChange={value => {
            setSelectdCategory(value);
          }}
          value={selectdCategory}
          selectStyle={{width: '100%'}}
          data={CategoryData}
          setData={'key'}
          showData={'name'}
        />
        <CommonTextInput
          maxLength={500}
          allowNegativeDecimaleNumber
          showLength={150}
          style={{width: '100%', margin: '0 0 15px 0'}}
          label="Max Score"
          onChange={setMaxScore}
          value={maxScore}
        />

        <CommonTextInput
          style={{width: '100%', margin: '0 0 15px 0'}}
          validateDeciNumbers
          label="Effective Index"
          onChange={setEffectiveIndex}
          value={effectiveIndex}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 15,
          }}>
          <LanguageDropdown
            inputValue={promptData}
            onChange={setPromptData}
            label="Prompt"
          />
        </div>
        <LanguageDropdown
          inputValue={lName}
          onChange={setLName}
          label="Subspecialty"
        />
        <div style={{marginTop: '20px'}}>
          {(image || serverImage) && (
            <div>
              {image && (
                <img
                  alt={name}
                  style={{maxWidth: '200px', maxHeight: '200px'}}
                  src={URL.createObjectURL(image)}
                />
              )}
              {!image && serverImage && (
                <img
                  alt={name}
                  style={{maxWidth: '200px', maxHeight: '200px'}}
                  src={serverImage}
                />
              )}
            </div>
          )}
        </div>
        <div style={{display: 'flex'}}>
          <label htmlFor="file-input">
            <Button
              variant="contained"
              sx={{width: '100%', backgroundColor: '#405672', marginTop: 2}}
              component="span">
              Attach Image
            </Button>
          </label>
        </div>

        <CommonTextInput
          id="file-input"
          type="file"
          style={{visibility: 'hidden'}}
          onChange={setImage}
        />

        <CommonButton
          style={{backgroundColor: '#405672', marginTop: 2, width: '100%'}}
          label={'Save'}
          onClick={() => {
            id ? updateSubspecialist() : createNewSubspecialist();
          }}
        />
      </Box>
      {showAlert && (
        <LocalAlert
          severity={alertType}
          alertText={alertMessage}
          onClose={() => setShowAlert(false)}
        />
      )}
      {cloneModal()}

      <Loader loading={loading} />
    </Box>
  );
}

export default CreateSubSpecialist;

const styles = {
  containerStyle: {
    height: 25,
    position: 'absolute',
    right: 4,
    top: 7,
  },
  iconStyle: {
    height: '15px',
    maxWidth: '17px',
    margin: '5px 8px',
  },
};
