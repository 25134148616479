import React, {useState, useEffect} from 'react';
import {CommonButton, useUserData} from '../commonComponents';
import ApiClient from '../networking/ApiManager';
import {useNavigate, useParams} from 'react-router';
import {cameraEditIcon} from '../Resources/Images';
import {useDispatch} from 'react-redux';
import Actions from '../redux/Actions';
import './EditProfile.css';
import {FloatingLabelDropDown, FloatinInputField} from '../otherComponents';
import {usePresigned, useTableData} from '../hooks';
import DoctorImage from '../SuperDoctor/bodyParts/DoctorImage';

const GENDERS = [
  {
    label: 'Male',
  },
  {
    label: 'Female',
  },
  {
    label: 'Other',
  },
];

const requiredFileds = ['name', 'department_id', 'email'];

function EditProfile() {
  const {id} = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let sessionUser = localStorage.getItem('user');
  const [loading, setLoading] = useState(false);
  const {getAndUploadSingleFile} = usePresigned();
  const [departmentList, setDepartmentList] = useState(null);
  const inputRef = React.useRef(null);
  const {userData} = useUserData();
  const [userDetails, setUserDetails] = useState({});
  const {departments} = useTableData(true);

  useEffect(() => {
    setUserDetails(userData);
  }, [userData]);

  useEffect(() => {
    setUserData();
  }, [userData]);

  useEffect(() => {
    setDepartmentList(departments);
  }, [departments]);

  const setUserData = () => {};

  const FIELDS = [
    {
      label: 'Full Name',
      key: 'name',
      type: 'input',
    },
    {
      label: 'Gender',
      key: 'gender',
      type: 'dropdown',
      selectLabel: 'label',
      selectValue: 'label',
      data: GENDERS,
    },
    {
      label: 'Email',
      key: 'email',
      type: 'input',
      inputMode: 'email',
    },
    {
      label: 'Phone Number',
      key: 'phone',
      type: 'input',
      disabled: true,
    },
    {
      label: 'Department',
      key: 'department_id',
      type: 'dropdown',
      data: departmentList,
      selectLabel: 'name',
      selectValue: 'id',
    },
  ];

  const updateProfile = async url => {
    let keyErrors = {};
    requiredFileds.forEach(key => {
      if (!userDetails[key]) {
        keyErrors[key + '_error'] = 'This field is required';
      }
    });

    if (Object.keys(keyErrors).length) {
      setUserDetails(prev => ({...prev, ...keyErrors}));
      return;
    }
    const params = {};
    FIELDS.forEach(field => {
      if (userDetails[field.key]) {
        params[field.key] = userDetails[field.key];
      }
    });
    if (userDetails.image) {
      const url = await getAndUploadSingleFile(userDetails.image);
      params.image_url = url;
    }
    await ApiClient.updateProfile(params, id)
      .then(res => {
        let data = res.data.data;
        const currentDoctor = data.super_admin ? 'doctor' : 'admin';
        if (sessionUser === currentDoctor) {
          dispatch(Actions.setUserData(data));
        } else {
          localStorage.clear();
          window.location.pathname = '/';
        }
        if (data.name) {
          setTimeout(() => {
            navigate('/');
          }, 800);
        }
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
      });
  };

  const setDetails = (key, value) => {
    setUserDetails(prev => {
      delete prev[key + '_error'];
      return {...prev, [key]: value};
    });
  };

  const renderFields = field => {
    const label = field.label;
    const value = userDetails[field.key] || '';
    const helperText = userDetails[field.key + '_error'] || '';

    const onChange = value => {
      if (field.regex && !field.regex.test(value)) {
        return;
      }
      setDetails(field.key, value);
    };

    switch (field.type) {
      case 'input':
        return (
          <FloatinInputField
            value={value}
            label={label}
            onChange={onChange}
            helperText={helperText}
            {...field}
          />
        );
      case 'dropdown':
        return (
          <FloatingLabelDropDown
            value={value}
            label={label}
            selectValue={field.selectValue}
            selectLabel={field.selectLabel}
            data={field.data}
            onChange={onChange}
            helperText={helperText}
            {...field}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="content-body">
      <div className="edit-profile-card">
        <div className="change_profile">
          <DoctorImage className="patient-img" />
          <img
            onClick={() => {
              inputRef.current.click();
            }}
            src={cameraEditIcon}
            className="change-img-icon pointer"
            alt="edit"
          />
          <input
            ref={inputRef}
            type="file"
            id="file"
            style={{display: 'none'}}
            onChange={e => {
              const [file] = e.target.files;
              if (file) {
                setDetails('image', file);
                setDetails('image_url', URL.createObjectURL(file));
              }
            }}
          />
        </div>
        <div className="edit-information">
          {FIELDS.map(renderFields)}
          <CommonButton
            onClick={updateProfile}
            loading={loading}
            label="Update Profile"
          />
        </div>
      </div>
    </div>
  );
}

export default EditProfile;
