const Strings = {
  INSURANCE_DATA: [
    {
      'SL NO': 1,
      CATEGORY: 'INS',
      name: 'ADNIC-ABU DHABI NATIONAL INSURANCE COMPANY',
      insurance_plan: 'INS017',
    },
    {
      'SL NO': 2,
      CATEGORY: 'TPA',
      name: 'ALMADHALLAH HEALTH CARE MANAGEMENT FZ-LLC',
      insurance_plan: 'TPA003',
    },
    {
      'SL NO': 3,
      CATEGORY: 'INS',
      name: 'AMERICAN LIFE INSURANCE COMPANY-METLIFE',
      insurance_plan: 'INS013',
    },
    {
      'SL NO': 4,
      CATEGORY: 'TPA',
      name: 'ECARE',
      insurance_plan: 'TPA029',
    },
    {
      'SL NO': 5,
      CATEGORY: 'TPA',
      name: 'FMC NETWORK UAE MANAGEMENT CONSULTANCY',
      insurance_plan: 'TPA010',
    },
    {
      'SL NO': 6,
      CATEGORY: 'INS',
      name: 'OMAN',
      insurance_plan: 'INS012',
    },
    {
      'SL NO': 7,
      CATEGORY: 'TPA',
      name: 'MEDNET UAE',
      insurance_plan: 'TPA036',
    },
    {
      'SL NO': 8,
      CATEGORY: 'TPA',
      name: 'NAS Administration Services LLC',
      insurance_plan: 'TPA004',
    },
    {
      'SL NO': 9,
      CATEGORY: 'INS',
      name: 'NATIONAL GENERAL INSURANCE -NGI',
      insurance_plan: 'INS038',
    },
    {
      'SL NO': 10,
      CATEGORY: 'TPA',
      name: 'NEURON L.L.C',
      insurance_plan: 'TPA001',
    },
    {
      'SL NO': 11,
      CATEGORY: 'TPA',
      name: 'INAYAH TPA LLC',
      insurance_plan: 'TPA008',
      Column7: ' ',
    },
    {
      'SL NO': 12,
      CATEGORY: 'TPA',
      name: 'NEXTCARE ',
      insurance_plan: 'TPA002',
    },
    {
      'SL NO': 13,
      CATEGORY: 'TPA',
      name: 'WHEALTH INTERNATIONAL L.L.LC',
      insurance_plan: 'TPA032',
    },
    {
      'SL NO': 14,
      CATEGORY: 'TPA',
      name: 'VIDAL MEDICAL SERVICES L.L.C',
      insurance_plan: 'TPA021',
    },
    {
      'SL NO': 15,
      CATEGORY: 'TPA',
      name: 'AMITHY HEALTH',
      insurance_plan: 'TPA006',
    },
    {
      'SL NO': 16,
      CATEGORY: 'TPA',
      name: 'IRIS HEALTH SERVICES L.L.C',
      insurance_plan: 'TPA030',
    },
    {
      'SL NO': 17,
      CATEGORY: 'TPA',
      name: 'MAXCARE MIDDILE EAST',
      insurance_plan: 'TPA015',
    },
    {
      'SL NO': 18,
      CATEGORY: 'TPA',
      name: 'PENTA CARE',
      insurance_plan: 'TPA013',
    },
    {
      'SL NO': 19,
      CATEGORY: 'TPA',
      name: 'FOURTUNE HEALTHCARE L.L.C',
      insurance_plan: 'TPA025',
    },
    {
      'SL NO': 20,
      CATEGORY: 'INS',
      name: 'DUBAI INSURANCE COMPANY',
      insurance_plan: 'INS005',
    },
    {
      'SL NO': 21,
      CATEGORY: 'TPA',
      name: 'ENET TPA L. L.C',
      insurance_plan: 'TPA038',
    },
    {
      'SL NO': 22,
      CATEGORY: 'Other',
      name: 'Other',
      insurance_plan: 'Other',
    },
  ],
};

export default Strings;
