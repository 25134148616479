import React, {useState} from 'react';
import {CommonButton, Header, Loader, LocalAlert} from '../../commonComponents';
import {Button} from '@mui/material';
import ApiClient from '../../networking/ApiManager';
import {useNavigate, useLocation} from 'react-router';
import LanguageDropdown from '../../commonComponents/LanguageDropdown';

const UpdateActivity = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const editParamId = location.state && location.state.edit;

  const editParamName = location.state && location.state.value.lname;

  const [loading, setLoading] = useState(false);
  const [alertType, setAlertType] = useState('error');
  const [name, setName] = useState(editParamName || '');
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const isNameEmpty = !name || Object.values(name).some(entry => entry === '');

  const updateActivity = () => {
    const formData = new FormData();
    for (const key of Object.keys(name)) {
      formData.append('lname[' + key + ']', name[key]);
    }
    if (!isNameEmpty) {
      setLoading(true);

      ApiClient.updateActivity(editParamId, formData)
        .then(res => {
          setShowAlert(true);
          setAlertMessage('Successfully Update Activity');
          setAlertType('success');
          setTimeout(() => {
            navigate(-1);
          }, 500);
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);

          console.log(err);
        });
    } else {
      setShowAlert(true);
      setAlertMessage('Please Enter Activity');
      setAlertType('error');
    }
  };

  return (
    <div>
      <Header />
      <div style={{paddingLeft: 20}}>
        <p style={{fontSize: 25}}>Update Activity :-</p>
      </div>
      <div style={{padding: 20}}>
        <LanguageDropdown
          inputValue={name}
          onChange={setName}
          label="Activity"
        />
      </div>

      <CommonButton
        style={{
          width: '97%',
          backgroundColor: '#405672',
          marginTop: '2%',
        }}
        label="Save"
        onClick={updateActivity}
      />
      {showAlert && (
        <LocalAlert
          severity={alertType}
          alertText={alertMessage}
          onClose={() => setShowAlert(false)}
        />
      )}
      <Loader loading={loading} />
    </div>
  );
};

export default UpdateActivity;
