import {useState} from 'react';
import ApiClient from '../networking/ApiManager';

const usePresigned = () => {
  const [uploading, setUploading] = useState(false);

  const getPresignedUrl = (count = 1, type) => {
    return new Promise((resolve, reject) => {
      const params = {
        count: count,
        type: type || 'doctor_profile',
      };
      setUploading(true);
      ApiClient.presignedUrlsForDoctor(params)
        .then(res => {
          let mediaData = res.data.data;
          if (count === 1) {
            resolve(mediaData[0].url);
          } else {
            resolve(mediaData);
          }
        })
        .catch(err => {
          console.log(err);
        });
    });
  };

  const uploadSingleFile = (file, url) => {
    return new Promise((resolve, reject) => {
      ApiClient.uploadFileToServer(file, url)
        .then(res => {
          const [finalUrl] = url.split('?');
          resolve(finalUrl);
        })
        .catch(err => {
          reject(err);
        });
    });
  };

  const getAndUploadSingleFile = (file, type) => {
    return new Promise((resolve, reject) => {
      getPresignedUrl(1, type)
        .then(url => {
          uploadSingleFile(file, url)
            .then(res => {
              resolve(res);
            })
            .catch(err => {
              reject(err);
            })
            .finally(() => {
              setUploading(false);
            });
        })
        .catch(err => {
          reject(err);
        });
    });
  };

  return {getAndUploadSingleFile, uploading};
};

export default usePresigned;
