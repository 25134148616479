import React, {useState} from 'react';
import {
  Box,
  Button,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from '@mui/material';
import {useNavigate} from 'react-router';
import {useDispatch} from 'react-redux';
import AsyncThunks from '../../redux/AsyncThunks';
import ApiClient from '../../networking/ApiManager';
import LanguageDropdown from '../../commonComponents/LanguageDropdown';
import {
  CommonButton,
  CommonTextInput,
  Loader,
  LocalAlert,
} from '../../commonComponents';

function FollowUpQuestionaaryform(props) {
  const [lSignforFollowup, setLSignforFollowup] = useState(
    props?.questionData?.ldescriptor_sign || {},
  );
  const [descriptorCode, setDescriptorCode] = useState(
    props?.questionData?.descriptor_code,
  );
  const [questionId, setQuestionID] = useState(props?.questionData?.id);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [checkBoxes, setCheckBoxes] = useState({
    askForPatientInputImage:
      props?.questionData?.ask_patient_input_image || false,
    startSyChart: props?.questionData?.start_sychart || false,
    skip: props?.questionData?.skippable || false,
  });
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertType, setAlertType] = useState('error');

  const dispatch = useDispatch();

  const inTheEnd = action => {
    action
      .unwrap()
      .then(res => {
        if (props?.refreshRootElement) {
          props?.refreshRootElement(res.data);
        } else {
          navigate(`/followups`);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const createQuestionnari = params => {
    setLoading(true);
    inTheEnd(dispatch(AsyncThunks.createFollowUpQuestion({params})));
  };

  const updateFollowUpQuestionnary = (params, id) => {
    setLoading(true);
    inTheEnd(dispatch(AsyncThunks.updateFollowUpQuestion({params, id})));
  };

  const deleteQuestionnari = () => {
    const confirmDelete = window.confirm(
      'Are you sure you want to delete this item?',
    );
    if (confirmDelete) {
      setLoading(true);
      ApiClient.deleteFollowUpQuestions(props?.questionData?.id)
        .then(res => {
          props?.refreshRootElement(res.data.data);
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  const saveData = () => {
    if (
      Object.keys(lSignforFollowup).length === 0 ||
      Object.values(lSignforFollowup)[0] === ''
    ) {
      setShowAlert(true);
      setAlertMessage('Please provide descriptor user');
      setAlertType('error');
      return false;
    } else if (!descriptorCode) {
      setShowAlert(true);
      setAlertMessage('Please provide descriptor system');
      setAlertType('error');
      return false;
    } else {
      let formData = new FormData();
      for (const key of Object.keys(lSignforFollowup)) {
        !!lSignforFollowup &&
          formData.append(
            'ldescriptor_sign[' + key + ']',
            lSignforFollowup[key],
          );
      }
      !!descriptorCode && formData.append('descriptor_code', descriptorCode);

      formData.append(
        'ask_patient_input_image',
        checkBoxes.askForPatientInputImage,
      );
      formData.append('start_sychart', checkBoxes.startSyChart);
      formData.append('skippable', checkBoxes.skip);

      if (props?.questionData?.parentId) {
        formData.append('parent_id', props?.questionData?.parentId);
      }
      if (questionId) {
        updateFollowUpQuestionnary(formData, questionId);
      } else {
        createQuestionnari(formData);
      }
    }
  };

  const createBtnMargin = questionId ? '20px 10px 20px 0' : '20px 0px';
  return (
    <Box sx={{margin: 2}}>
      <div style={{width: '100%'}}>
        <h2 sx={{margin: '15px 0'}}>Followup Question :-</h2>
        <LanguageDropdown
          inputValue={lSignforFollowup}
          onChange={setLSignforFollowup}
          label="Descriptor User"
        />
        <CommonTextInput
          maxLength={250}
          showLength={125}
          style={{width: '100%', marginTop: '18px'}}
          value={descriptorCode}
          label="Descriptor System"
          onChange={setDescriptorCode}
        />
        {/* <CommonTextInput
        type="file"
        style={{ width: '100%', margin: '15px 0' }}
        onChange={setImage}
      /> */}
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={checkBoxes.askForPatientInputImage} />}
            label="Ask for patient input image?"
            onChange={() => {
              checkBoxes.askForPatientInputImage =
                !checkBoxes.askForPatientInputImage;
              setCheckBoxes({...checkBoxes});
            }}
          />
          <FormControlLabel
            control={<Checkbox checked={checkBoxes.startSyChart} />}
            label="Start SyChart?"
            onChange={() => {
              checkBoxes.startSyChart = !checkBoxes.startSyChart;
              setCheckBoxes({...checkBoxes});
            }}
          />
          <FormControlLabel
            control={<Checkbox checked={checkBoxes.skip} />}
            label="skip?"
            onChange={() => {
              checkBoxes.skip = !checkBoxes.skip;
              setCheckBoxes({...checkBoxes});
            }}
          />
        </FormGroup>

        <CommonButton
          style={{
            width: '100%',
            backgroundColor: '#405672',
            margin: createBtnMargin,
          }}
          label={'Save'}
          onClick={saveData}
        />

        {questionId && (
          <CommonButton
            variant="contained"
            label="Delete Questionary"
            style={{
              width: '100%',
              backgroundColor: '#405672',
              margin: '20px 0 20px 10px',
            }}
            onClick={() => {
              deleteQuestionnari();
            }}
          />
        )}
        {showAlert && (
          <LocalAlert
            severity={alertType}
            alertText={alertMessage}
            onClose={() => setShowAlert(false)}
          />
        )}
        <Loader loading={loading} />
      </div>
    </Box>
  );
}

export default FollowUpQuestionaaryform;
