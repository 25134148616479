import {Box, Switch} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router';
import {
  CommonButton,
  CommonTable,
  Header,
  Loader,
  LocalAlert,
  useCallApi,
} from '../../commonComponents';
import ApiClient from '../../networking/ApiManager';

function Facts(props) {
  const [data, setdata] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertType, setAlertType] = useState('error');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {apiCall, deleteApiCall} = useCallApi();

  const createFact = () => {
    navigate(`/facts/new`);
  };

  const handleError = err => {
    console.log(err);
    setLoading(false);
  };

  const factActivateDeactivate = (id, isActive) => {
    setLoading(true);
    ApiClient.activateDeactivateFact(id, isActive)
      .then(res => {
        getFacts();
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
      });
  };

  const getFacts = () => {
    apiCall('getFacts', null, setdata, handleError);
  };

  const otherOptions = (id, isActive) => {
    return (
      <div>
        <Switch
          onChange={() => {
            if (!isActive) {
              factActivateDeactivate(id, 'deactivate');
            } else {
              factActivateDeactivate(id, 'activate');
            }
          }}
          defaultChecked={!isActive}
        />
      </div>
    );
  };

  useEffect(() => {
    getFacts();
  }, []);

  const tableHeaderData = ['Id', 'Fact', 'Content', 'Image'];
  const dataKeys = ['id', 'fact', 'content'];

  const deleteFacts = id => {
    const confirmDelete = window.confirm(
      'Are you sure you want to delete this fact?',
    );
    if (confirmDelete) {
      deleteApiCall('deleteFacts', id, getFacts, handleError);
      console.log(id);
    }
  };

  const updateFacts = id => {
    navigate(`/facts/${id}`);
  };

  return (
    <Box>
      <Header />
      <Box sx={{margin: 2}}>
        <p style={{fontSize: 30, margin: '15px'}}>Facts :- </p>
        <CommonButton
          style={{width: '98.4%', backgroundColor: '#405672', marginTop: 2}}
          label="Create Fact"
          onClick={createFact}
        />
        <CommonTable
          data={data}
          tableHeaderData={tableHeaderData}
          dataKeys={dataKeys}
          showImageKey={'image_url'}
          showUpdate
          showDelete
          onPressDelete={deleteFacts}
          showOtherOption={otherOptions}
          onPressUpdate={updateFacts}
          tableStyle={{width: '100%'}}
          tableContainerStyle={{marginTop: 3}}
          emptyTitle="No Facts Found"
        />
      </Box>
      {showAlert && (
        <LocalAlert
          severity={alertType}
          alertText={alertMessage}
          onClose={() => setShowAlert(false)}
        />
      )}
      <Loader loading={loading} />
    </Box>
  );
}

export default Facts;
