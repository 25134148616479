import React from 'react';
import {blackCrossIcon} from '../Resources/Images';

const BlackCrossIcon = props => {
  return (
    <div style={props.containerStyle}>
      <div onClick={props.onClick}>
        <img src={blackCrossIcon} alt="close_icon" style={props.style} />
      </div>
    </div>
  );
};

export default BlackCrossIcon;
