import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Switch} from '@mui/material';
import AsyncThunks from '../redux/AsyncThunks';

const LanguageSwitch = ({row, type, taskSetId}) => {
  const localStorageKey = `languageSwitchIsActive_${row.id}`;
  const storedIsActive = localStorage.getItem(localStorageKey);
  const initialIsActive = storedIsActive
    ? JSON.parse(storedIsActive)
    : row.deactivated_at === null;

  const [isActive, setIsActive] = useState(initialIsActive);
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(isActive));
  }, [isActive, localStorageKey]);

  const toggle = () => {
    setIsActive(!isActive);
    const upper = type;
    const method = taskSetId ? `taskToggle${type}` : `toggle${upper}`;

    dispatch(AsyncThunks[method]({id: row.id, isActive, taskSetId: taskSetId}));
  };

  return <Switch onChange={toggle} defaultChecked={isActive} />;
};

export default LanguageSwitch;
