import React from 'react';

import './App.css';
import DocsyApp from './docsy-components/DocsyApp';

function App() {
  return <DocsyApp />;
}

export default App;
