import {registeredPatientsList} from '../Redcuers/PatientListReducer/RegistrationPatientListSlice';
import {getDoctors} from '../Redcuers/ReceptionistReducer/DoctorsListSlice';
import {searchPatients} from '../Redcuers/SychartDoctor/PatientsListSlice';
import {getPatientsResponses} from '../Redcuers/SychartDoctor/PatientsResponsesSlice';
import {SuperDoctorThunks} from '../Redcuers/SuperDoctor';
import {getLanguage} from '../Redcuers/LanguageSlice';

const AsyncThunks = {
  searchPatients,
  getPatientsResponses,
  getDoctors,
  registeredPatientsList,
  getLanguage,
  ...SuperDoctorThunks,
};

export default AsyncThunks;
