import {Box} from '@mui/system';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CommonTextInput from './CommonTextInput';
import {useSelector} from 'react-redux';

function LanguageDropdown(props) {
  const [selectedLanguage, setSelectedLanguage] = useState({});
  const [display, setDisplay] = useState(false);
  const [inputValue, setInputValue] = useState({});
  const [selectedText, setSelectedText] = useState('');
  const languages = useSelector(state => state.LanguageSlice.data);
  const dropDownRef = useRef();

  useEffect(() => {
    if (props.inputValue) {
      setInputValue(props.inputValue);
      setSelectedText(
        props.inputValue && selectedLanguage
          ? props.inputValue[selectedLanguage.id] || ''
          : '',
      );
    }
  }, [props.inputValue, selectedLanguage, selectedLanguage.id]);

  const handleLanguageChange = useCallback(item => {
    setSelectedLanguage(item);
    setDisplay(false);
  }, []);

  useEffect(() => {
    const handleOutsideClick = event => {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        setDisplay(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [dropDownRef, display]);

  useEffect(() => {
    if (selectedLanguage.id) {
      setSelectedText(
        props.inputValue && selectedLanguage
          ? props.inputValue[selectedLanguage.id] || ''
          : '',
      );
    }
  }, [selectedLanguage, props.inputValue]);

  useEffect(() => {
    if (languages.length > 0) {
      const lang = languages[0];
      setSelectedLanguage(lang);
    }
  }, [languages]);

  const handleLanguageTextChange = useCallback(
    text => {
      setSelectedText(text);
      const change = prev => ({...prev, [selectedLanguage.id]: text});
      setInputValue(change);
      props.onChange(change);
    },
    [props, selectedLanguage.id],
  );

  return (
    <Box
      ref={dropDownRef}
      sx={{display: 'flex', flexDirection: 'row', width: '100%'}}>
      <div style={{display: 'flex', flexDirection: 'row', marginTop: 3}}>
        <div className="input-group mb-3">
          <div className="dropdown">
            <button
              onClick={() => setDisplay(!display)}
              className="dropbtn"
              style={{minWidth: 120, maxHeight: 55}}>
              {selectedLanguage && selectedLanguage.locale}
              <ArrowDropDownIcon />
            </button>
            <div
              id="myDropdown"
              className="dropdown-content"
              style={{
                display: display ? 'block' : 'none',
                zIndex: 2,
                maxHeight: '200px',
                overflowY: 'scroll',
              }}>
              {languages.map((item, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      padding: '10px',
                    }}>
                    <a
                      onClick={() => {
                        handleLanguageChange(item);
                      }}>
                      {item.locale}
                    </a>
                    {inputValue[item.id] && inputValue[item.id].length > 0 && (
                      <input
                        style={{}}
                        type="checkbox"
                        className="onoffswitch-checkbox"
                        id="inline"
                        checked
                        onChange={() => {}}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <CommonTextInput
        style={{width: '100%', marginTop: 3}}
        value={selectedText}
        maxLength={250}
        showLength={125}
        label={props.label}
        onChange={handleLanguageTextChange}
      />
    </Box>
  );
}

export default LanguageDropdown;
